window.NO_PARAMS_GUI = true;
window.debugMode = false;

// TODO remove pace

require('regenerator-runtime/runtime')
require('core-js/stable')
require('../css/main.css')
require('./page.css')
require('../css/materialize.css')
require('../js/materialize.min.js')

// set to false to avoid having to load face models
export const doFaceDetection = true // when doFaceDetection is false automaticLogin is enabled
export const debugMode = window.debugMode
export const installationVersion = false // if true, need to also change webpack to use tmpl-install.html
export const automaticLoginUserName = "Arsenal"
export const automaticLoginUserLoc = "Montréal, Québec"
export const automaticLoginUserComment = "Bonjour!"

// export from scripts what is needed to be accessed by non nested scripts

export * from '../js/firebase.js'
// export * from './scene_install.js'
export * from './scene.js'
export * from './sceneController.js'

// user registration
require('./user-registration.js')
require('./new-audio.js')
// require('./timer.js')

require('./dematerialize.js')
export * from './language-lookup.js'


// // ===== Lingdong's fake pulses
// window.bcb;
// function foo(){
//   setTimeout(foo,1000);
//   if (window.bcb == undefined){
//     if (window.getBeatCallbacks){
//       window.bcb = getBeatCallbacks();
//     }
//   }else{
//     Object.values(bcb).map(x=>x());
//   }
// }
// foo();
// // ====== end lingdong's fake pulses
