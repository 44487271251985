import { newUserAddedtoAllUsers } from "./sceneController"
// import { updateViewerStatus } from "./bpm-detection"
// import { bpmDetection } from "./user-registration"


const en = 'English'
const fr = 'Français'



var bpmDetectionPhase = -1
var bpmDetectionPulse = 0

// var lang = ''
export var lang = {
  mylang: '',
  aListener: function(val) {},
  set me(val) {
    this.mylang = val;
    this.aListener(val);
  },
  get me() {
    return this.mylang;
  },
  registerListener: function(listener) {
    this.aListener = listener;
  }
}



var langButton

export const LOADING_FACE_DETECTION = -1
export const NO_FACE_FOUND = 0
export const FACE_FOUND = 1
export const STAY_STILL = 1.1
export const HONING_PULSE =1.2
export const PULSE_FOUND = 3





////// translations
var txt1 = new Object
txt1.en = 'Welcome to \“OnPulse\” a biometric chat project. To participate please:'
txt1.fr = 'Bienvenue à «OnPulse», un projet de clavardage biométrique. Afin de participer, prière de:'
var txt2_0 = new Object
txt2_0.en = 'Enable your camera so it can detect your heartbeat'
txt2_0.fr = 'Activer votre caméra afin qu’elle puisse détecter votre pouls cardiaque'
var txt2_1 = new Object
txt2_1.en = 'Ensure that your face is well lit'
txt2_1.fr = 'Vous assurer que votre visage est bien éclairé'
var txt2_2 = new Object
txt2_2.en = 'Wear headphones and turn the volume up'
txt2_2.fr = 'Porter des écouteurs et monter le volume'
var txt2_3 = new Object
txt2_3.en = 'Enter your name and optional information'
txt2_3.fr = 'Entrer votre nom et information optionnelle'
var txt2_4 = new Object
txt2_4.en = 'Press LAUNCH.'
txt2_4.fr = 'Cliquer sur LANCER'
var txt3 = new Object
txt3.en = 'This is an art project that extracts but does not retain biometric information. Do not participate in this piece if you have privacy concerns. As an open 3D chat environment visitors need to be aware that all written text will be seen publicly and they are responsible for any copyright violations, abusive content or any claims whatsoever. If a particular chat bores or offends you may turn the chat function off at any time.'
txt3.fr = 'Ceci est un projet artistique qui extrait l’information biométrique de l’usager sans la conserver. Prière de ne pas participer à cette pièce si vous êtes préoccupés par le partage d’information personnelle. En tant qu’espace 3D de clavardage ouvert à tous, les visiteurs doivent prendre conscience que tout texte écrit sera vu publiquement et que ceux-ci seront responsables de toute violation du droit d’auteur, de contenu abusif, ou de toute autre requête. Si un message vous étant destiné est jugé inintéressant ou offensant, vous pouvez désactiver la fonction de clavardage à tout moment.'

// bpm detection text
var bpmInfo = new Object
bpmInfo.en = 'The artwork detects your pulse using photoplethysmography and machine learning with Tensor Flow models. It uses signal processing algorithms to detect small changes in the blue and green channels of your skin tone over time.'
bpmInfo.fr = 'L’œuvre détecte votre pouls en utilisant la photopléthysmographie et l’apprentissage automatique, ou «Machine Learning», à l’aide de modèles Tensor Flow. Elle utilise des algorithmes de traitement de signal afin de reconnaître des changements dans les teintes de bleu et de vert de votre peau sur une courte durée.'
var bpmLoadingMessage = new Object
bpmLoadingMessage.en = 'Loading machine learning models for face detection...'
bpmLoadingMessage.fr = 'Chargement des modèles d’apprentissage automatique pour la détection faciale...'
var bpmPhase0 = new Object
bpmPhase0.en = 'No face detected'
bpmPhase0.fr = 'Aucun visage détecté'
var bpmPhase1 = new Object
bpmPhase1.en = 'Face found!'
bpmPhase1.fr = 'Visage trouvé!'
var bpmPhase1_1 = new Object
bpmPhase1_1.en = 'Detecting pulse. Please stay still.'
bpmPhase1_1.fr = 'Détection du pouls. Veuillez demeurer immobile'
var bpmPhase1_2 = new Object
bpmPhase1_2.en = 'Detecting pulse... '
bpmPhase1_2.fr = 'Détection du pouls... '
var bpmPhase3 = new Object
bpmPhase3.en = 'Pulse found!'
bpmPhase3.fr = 'Pouls trouvé!'

// registration text
var txt4 = new Object
txt4.en = 'Please fill out the info below'
txt4.fr = 'Prière de remplir l’information ci-bas'
var form1 = new Object
form1.en = 'Name'
form1.fr = 'Nom'
var form2 = new Object
form2.en = 'Location (Optional)'
form2.fr = 'Emplacement (Optionnel)'
var form3 = new Object
form3.en = 'Comment (Optional)'
form3.fr = 'Commentaires (Optionnel)'
var form4 = new Object
form4.en = 'LAUNCH'
form4.fr = 'LANCER'

// loading scene
var loadingSceneTxt = new Object
loadingSceneTxt.en = 'loading'
loadingSceneTxt.fr = 'chargement'



// var bpmPhase1 = new Object
// bpmPhase0.en = 'No face detected'
// bpmPhase0.fr = 'Aucun visage détecté'


window.addEventListener('DOMContentLoaded', (event) => {

    langButton = document.getElementById('translationButton')
    langButton.addEventListener('click', changeLanguage)

    lang.me = window.navigator.userLanguage || window.navigator.language

    lang.me = lang.me.slice(0, 2)

    console.log('lang: ' + lang.me)


        // default the language to english if we do not support the provided language
        if(lang.me !== 'en' && lang.me !== 'fr'){
            lang.me = 'en'
        }

        // default language is english, translate page to french if needed
        if(lang.me === 'fr'){
            translatePage()
        }

})

// from the input control, change the language
function changeLanguage(){
    if(lang.me == 'en'){
        lang.me = 'fr'
        langButton.innerHTML = en
    }else if(lang.me == 'fr'){
        lang.me = 'en'
        langButton.innerHTML = fr
    }

    translatePage()


}

function translatePage(){
    window.ISFRENCH = lang.me === 'en' ? false : true

    // login - col1 - welcome text
    document.getElementById('txt1').innerHTML = txt1[lang.me]
    document.getElementById('txt2-0').innerHTML = txt2_0[lang.me]
    document.getElementById('txt2-1').innerHTML = txt2_1[lang.me]
    document.getElementById('txt2-2').innerHTML = txt2_2[lang.me]
    document.getElementById('txt2-3').innerHTML = txt2_3[lang.me]
    document.getElementById('txt2-4').innerHTML = txt2_4[lang.me]
    document.getElementById('txt3').innerHTML = txt3[lang.me]

    // login - col2 - bpm detection text
    document.getElementById('bpmInfo').innerHTML = bpmInfo[lang.me]
    // the remainder of the text for bpm detection gets triggered by a listener in bpm-detection.js

    // login - col3 - registration text
    document.getElementById('txt4').innerHTML = txt4[lang.me]
    document.getElementById('name_input_label').innerHTML = form1[lang.me]
    document.getElementById('loc_input_label').innerHTML = form2[lang.me]
    document.getElementById('comment_input_label').innerHTML = form3[lang.me]
    document.getElementById('initUser').innerHTML = form4[lang.me]

    // loading
    document.getElementById('loading_scene_txt').innerHTML = loadingSceneTxt[lang.me]

}

export function getTranslatedBPMText(phase, pulse){
  bpmDetectionPhase = phase
    switch (phase) {
        case LOADING_FACE_DETECTION:
            return bpmLoadingMessage[lang.me]
        // no face detected
        case NO_FACE_FOUND:
            return bpmPhase0[lang.me]

        // face found
        case FACE_FOUND:
            return bpmPhase1[lang.me]

        // detecting pulse please stay still
        case STAY_STILL:
            return bpmPhase1_1[lang.me]

        // detecting pulse... 68 BPM
        case HONING_PULSE:
            return bpmPhase1_2[lang.me]

        // pulse found
        case PULSE_FOUND:
            return bpmPhase3[lang.me]

        default:
            break;
    }
}
