const faceapi = require('face-api.js')

const SSD_MOBILENETV1 = 'ssd_mobilenetv1'
const TINY_FACE_DETECTOR = 'tiny_face_detector'
const MTCNN = 'mtcnn'

let faceIsFound = false
let forwardTimes = []
var fps = 0
var foreheadPoints
var leftCheekPoints
var rightCheekPoints
var leftPoint
let selectedFaceDetector = SSD_MOBILENETV1

// ssd_mobilenetv1 options
// let minConfidence = 0.5

// tiny_face_detector options
let inputSize = 160 // default - good for mobile. Use 224 for installation. Options: 128, 160, 224
const scoreThreshold = 0.5

// mtcnn options
// let minFaceSize = 20

function getTimeStats (timeInMs) {
  forwardTimes = [timeInMs].concat(forwardTimes).slice(0, 30)
  const avgTimeInMs = forwardTimes.reduce((total, t) => total + t) / forwardTimes.length
  // $('#time').val(`${Math.round(avgTimeInMs)} ms`)
  // $('#fps').val(`${faceapi.round(1000 / avgTimeInMs)}`)
  // return timeInMs/1000;
  fps = faceapi.round(1000 / avgTimeInMs)

  // return undefined;
}

function getFPS () {
  return fps || undefined
}

function getFaceDetectorOptions () {
  return new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold })
  // return selectedFaceDetector === SSD_MOBILENETV1
  //   ? new faceapi.SsdMobilenetv1Options({ minConfidence })
  //   : (
  //     selectedFaceDetector === TINY_FACE_DETECTOR
  //       ? new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold })
  //       : new faceapi.MtcnnOptions({ minFaceSize })
  //   )
}

// function setLeftPoint (lp) {
//   console.log(' lp ' + lp.x)
//   leftPoint = lp
// }

async function getSingleFaceLandmarks (videoIn, canvas, isDrawingLandmarks, isDrawingDetection) {
  var ts = Date.now()
  const options = getFaceDetectorOptions()

  var result = await faceapi.detectSingleFace(videoIn, options).withFaceLandmarks(true)
  getTimeStats((Date.now() - ts))
  if (result) {
    // console.log(' face found ')
    const dims = faceapi.matchDimensions(canvas, videoIn, false)
    result = faceapi.resizeResults(result, dims)

    if (isDrawingDetection) { faceapi.draw.drawDetections(canvas, result) }

    if (isDrawingLandmarks === true) { faceapi.draw.drawFaceLandmarks(canvas, result) }
    // const landmarks = faceapi.getSingleFaceLandmarks;
    var resultForeheadPnts = {
      left: result.landmarks.positions[19],
      right: result.landmarks.positions[24]
    }
    var resultLeftCheekPnts = {
      top: result.landmarks.positions[46],
      bottom: result.landmarks.positions[54]
    }

    var resultRightCheekPnts = {
      top: result.landmarks.positions[41],
      bottom: result.landmarks.positions[48]
    }

    // leftForeheadMarker = foreheadPoints.left;

    // console.log("fps: " + fps);
    // setLeftPoint(result.landmarks.positions[19]);
    setForeheadPoints(resultForeheadPnts)
    setLeftCheekPoints(resultLeftCheekPnts)
    setRightCheekPoints(resultRightCheekPnts)
    setFaceIsFound(true)
  }
  else {
    setFaceIsFound(false)
  }

  return 0
}

function setFaceIsFound (result) {
  faceIsFound = result
}

function getFaceIsFound () {
  return faceIsFound
}

function setForeheadPoints (resultForeheadPnts) {
  foreheadPoints = resultForeheadPnts
}

function getForeheadPoints () {
  if (foreheadPoints) { return foreheadPoints } else return undefined
}

function setLeftCheekPoints (resultLCheekPnts) {
  leftCheekPoints = resultLCheekPnts
}

function getLeftCheekPoints () {
  if (leftCheekPoints) { return leftCheekPoints } else return undefined
}

function setRightCheekPoints (resultRCheekPnts) {
  rightCheekPoints = resultRCheekPnts
}

function getRightCheekPoints () {
  if (rightCheekPoints) { return rightCheekPoints } else return undefined
}

function getLeft () {
  if (leftPoint) {
    return leftPoint
  }
  return undefined
}

// function onIncreaseMinConfidence () {
//   minConfidence = Math.min(faceapi.round(minConfidence + 0.1), 1.0)
//   $('#minConfidence').val(minConfidence)
//   updateResults()
// }

// function onDecreaseMinConfidence () {
//   minConfidence = Math.max(faceapi.round(minConfidence - 0.1), 0.1)
//   $('#minConfidence').val(minConfidence)
//   updateResults()
// }

// function onInputSizeChanged (e) {
//   changeInputSize(e.target.value)
//   updateResults()
// }

function changeInputSize (size) {
  inputSize = parseInt(size)

  // const inputSizeSelect = $('#inputSize')
  // inputSizeSelect.val(inputSize)
  // inputSizeSelect.material_select()
}

// function onIncreaseScoreThreshold () {
//   scoreThreshold = Math.min(faceapi.round(scoreThreshold + 0.1), 1.0)
//   $('#scoreThreshold').val(scoreThreshold)
//   updateResults()
// }

// function onDecreaseScoreThreshold () {
//   scoreThreshold = Math.max(faceapi.round(scoreThreshold - 0.1), 0.1)
//   $('#scoreThreshold').val(scoreThreshold)
//   updateResults()
// }

// function onIncreaseMinFaceSize () {
//   minFaceSize = Math.min(faceapi.round(minFaceSize + 20), 300)
//   $('#minFaceSize').val(minFaceSize)
// }

// function onDecreaseMinFaceSize () {
//   minFaceSize = Math.max(faceapi.round(minFaceSize - 20), 50)
//   $('#minFaceSize').val(minFaceSize)
// }

function getCurrentFaceDetectionNet () {
  if (selectedFaceDetector === SSD_MOBILENETV1) {
    // console.log('current face detection net: ssd')
    return faceapi.nets.ssdMobilenetv1
  }
  if (selectedFaceDetector === TINY_FACE_DETECTOR) {
    // console.log('current face detection net: tiny face detector')
    return faceapi.nets.tinyFaceDetector
  }
  if (selectedFaceDetector === MTCNN) {
    // console.log('current face detection net: mtcnn')
    return faceapi.nets.mtcnn
  }
}

function isFaceDetectionModelLoaded () {
  // console.log("im found ")
  return !!getCurrentFaceDetectionNet().params
}

async function changeFaceDetector (detector) {
  // ['#ssd_mobilenetv1_controls', '#tiny_face_detector_controls', '#mtcnn_controls']
  //   .forEach(id => $(id).hide())

  selectedFaceDetector = detector
  // const faceDetectorSelect = $('#selectFaceDetector')
  // faceDetectorSelect.val(detector)
  // faceDetectorSelect.material_select()

  // $('#loader').show()
  document.getElementById('loader').style.display = 'block'
  if (!isFaceDetectionModelLoaded()) {
    // await getCurrentFaceDetectionNet().load('/')
    // console.log('change face detector')
    await getCurrentFaceDetectionNet().loadFromUri('../src/assets/models/')
  }

  document.getElementById('loader').style.display = 'none'
  await faceapi.loadFaceLandmarkTinyModel('../src/assets/models/')
  console.log('loaded')
  // $(`#${detector}_controls`).show()
  // $('#loader').hide()
}

// async function onSelectedFaceDetectorChanged (e) {
//   selectedFaceDetector = e.target.value

//   await changeFaceDetector(e.target.value)
//   updateResults()
// }

// function initFaceDetectionControls () {
//   const faceDetectorSelect = $('#selectFaceDetector')
//   faceDetectorSelect.val(selectedFaceDetector)
//   faceDetectorSelect.on('change', onSelectedFaceDetectorChanged)
//   faceDetectorSelect.material_select()

//   const inputSizeSelect = $('#inputSize')
//   inputSizeSelect.val(inputSize)
//   inputSizeSelect.on('change', onInputSizeChanged)
//   inputSizeSelect.material_select()
// }

module.exports = {
  changeFaceDetector,
  changeInputSize,
  fps,
  isFaceDetectionModelLoaded,
  foreheadPoints,
  getFaceDetectorOptions,
  getFaceIsFound,
  getForeheadPoints,
  getFPS,
  getLeft,
  getLeftCheekPoints,
  getRightCheekPoints,
  getSingleFaceLandmarks,
  getTimeStats,
  TINY_FACE_DETECTOR
}
