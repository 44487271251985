

var oldchathistlen = 0;
var thisUser
var otherUser
var chatId

export function initChat (friendId, chatid) {
    console.log('initiated chat:')
    // thisUser = myUser
    otherUser = friendId
    chatId = chatid
}

export function setUser (user){
    thisUser = user

}

// displays entire chat history
export function renderChatHist(chatObj){

    // console.log({chatObj})
    if (!chatObj){
        return;
      }

    var messages = Object.values(chatObj)
    messages = messages.sort((a,b) => (a.time-b.time))

    //draw chat message
    document.getElementById("chathist").innerHTML = `${messages.map(x=>'<small style="color:silver">'+x.name+': <i style="color:white">'+x.text+'</i></small>').join("<br>")}<br>`;

    if (messages.length!=oldchathistlen){
      document.getElementById("chathist").scrollTop = document.getElementById("chathist").scrollHeight
    }
    oldchathistlen = messages.length;
  }


  // displays when the other user's typing status has changed.
  // triggered by firebase.js
window.otherUserIsTyping = function (isTyping){
    document.getElementById("chatname").innerHTML = (isTyping? (otherUser.name+(ISFRENCH?" tape...":" is typing...")) : otherUser.name)
  }

  // adds chat message to user's view
  window.appendChatMessage = function(message){
    // console.log('append chat message ' + message.text)
    document.getElementById("chathist").innerHTML += '<small style="color:silver">'+message.name+': <i style="color:white">'+message.text+'</i></small><br>'

    document.getElementById("chathist").scrollTop = document.getElementById("chathist").scrollHeight

    oldchathistlen += 1;
  }
