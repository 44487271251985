//http://doodle-place.glitch.me/touch-controls.js


function angleLerp(a0,a1,t) {
    function shortAngleDist(a0,a1) {
      var max = Math.PI*2;
      var da = (a1 - a0) % max;
      return 2*da % max - da;
  }
  return a0 + shortAngleDist(a0,a1)*t;
}
var outer;
var style = document.createElement("style");
style.innerHTML =`
.dir-ctrl-outer{

}
.dir-ctrl-outer::after{
  content: " ";
  display: block;
  width: 150px;
  height:150px;
  margin-left: 75px;
  margin-top:75px;
  border-radius: 1000px;
  border: 1px solid rgba(255,255,255,0.3);
}

.dir-ctrl-inner{
  background-color: rgba(255,255,255,0.3);

}
`
// setTimeout(function(){
  document.head.appendChild(style);
// },100);

function newTouchPad(player,callback){
  var W = 300; //300
  var w = 80; //80 // inside width of circle
  var R = 30; // 50
  var pad = -30// = -30; // -30
  outer = document.createElement("div");
  outer.classList.add("dir-ctrl-outer");
  outer.style.position = "absolute";
  outer.style.width = W+"px";
  outer.style.height = W+"px";

  if(window.innerHeight > window.innerWidth){
    outer.style.left = (window.innerWidth/2-W/2)+"px";
    outer.style.top = (window.innerHeight-W-pad)+"px";
  }else{
    outer.style.left = (pad)+"px";
    outer.style.top = (window.innerHeight-W-pad)+"px";
  }
  outer.style.borderRadius = (W/2) + "px";
  document.body.appendChild(outer)

  var inner = document.createElement("div");

  inner.classList.add("dir-ctrl-inner");
  inner.style.position = "absolute";
  inner.style.width = w+"px";
  inner.style.height = w+"px";
  inner.style.left = (W-w)/2+"px";
  inner.style.top = (W-w)/2+"px";
  inner.style.borderRadius = (w/2) + "px";
  outer.appendChild(inner)

  var touchid;
  var touchx = 0;
  var touchy = 0;
  var innerx = 0;
  var innery = 0;
  var dispx = 0;
  var dispy = 0;

  var radius = 0;
  var theta = 0;


  function calc_touch(){
    var x = touchx;
    var y = touchy;
    var a = Math.atan2(y,x);
    var r = Math.hypot(x,y);
    r = Math.min(r,R);
    radius = r/R;
    theta = a;

    innerx = Math.cos(a)*r;
    innery = Math.sin(a)*r;
  }

  setInterval(function(){
    dispx = dispx*0.8+innerx*0.2;
    dispy = dispy*0.8+innery*0.2;
    inner.style.left = (dispx-w/2+W/2) + "px";
    inner.style.top = (dispy-w/2+W/2) + "px";


    if (touchid != undefined){

      player.x += Math.cos(player.rot+theta+Math.PI/2)*player.spd*radius*1.5;
      player.z += Math.sin(player.rot+theta+Math.PI/2)*player.spd*radius*1.5;
    }


  },10)

  outer.addEventListener('touchstart', function(event){
    // console.log('joystick touchstart')
    // if (callback){callback()}
    var rect = outer.getBoundingClientRect();
    for (var i = 0; i < event.touches.length; i++){

      var tid = event.touches[i].identifier;
      touchx = event.touches[i].pageX - rect.left-W/2;
      touchy = event.touches[i].pageY - rect.top-W/2;
      touchid = tid;
      break;
    }
    calc_touch();
    event.stopPropagation();
    event.preventDefault();

  }, false);
  outer.addEventListener('touchmove', function(event){
    // console.log('joystick touchmove')
    // if (callback){callback()}
    var rect = outer.getBoundingClientRect();

    for (var i = 0; i < event.touches.length; i++){
      var tid = event.touches[i].identifier;
      if (tid == touchid){
        touchx = event.touches[i].pageX - rect.left-W/2;
        touchy = event.touches[i].pageY - rect.top-W/2;
        break;
      }
    }
    if (tid != undefined){
      calc_touch();
    }
    event.stopPropagation();
    event.preventDefault();


  }, false);
  outer.addEventListener('touchcancel', function(event){
    // console.log('joystick touchcancel')
    touchid = undefined;
    touchx = 0;
    touchy = 0;
    calc_touch();
    event.stopPropagation();
    event.preventDefault();

  }, false);
  outer.addEventListener('touchend', function(event){
    // console.log('joystick touchend')
    touchid = undefined;
    touchx = 0;
    touchy = 0;
    calc_touch();
    event.stopPropagation();
    event.preventDefault();

  }, false);


  function get(){
    return {r:radius,th:theta,dom:outer}
  }
  return get;

}



function newTouchLook(player,dom,maskdom,callback){

  var touchid;
  var touchdown;
  var touchdown2;
  var oldrot;
  var oldrot2;

  var mrect = maskdom.getBoundingClientRect();

  dom.addEventListener('touchstart', function(event){
    if (callback){callback()}
    for (var i = 0; i < event.touches.length; i++){
      var x = event.touches[i].pageX;
      var y = event.touches[i].pageY;
      // console.log(mrect,x,y)
      if (mrect.left < x && x < mrect.right && mrect.top < y && y < mrect.bottom){
        console.log("blocked");
        continue;
      }
      var tid = event.touches[i].identifier;
      touchdown = event.touches[i].pageX
      touchdown2= event.touches[i].pageY
      touchid = tid;
      oldrot = player.rot;
      oldrot2 = player.rot2;
      break;

    }
    event.preventDefault(); //new
  }, false);
  dom.addEventListener('touchmove', function(event){
    if (callback){callback()}
    var ids = [];

    for (var i = 0; i < event.touches.length; i++){
      var tid = event.touches[i].identifier;
      ids.push(tid);
      if (tid == touchid){
        var x = event.touches[i].pageX;
        var y = event.touches[i].pageY;
        player.rot = oldrot - (x-touchdown)/window.innerWidth*2;
        player.rot2 = oldrot2 - (y-touchdown2)/window.innerHeight*2;
        break;
      }
    }

    event.preventDefault();
  }, false);
  dom.addEventListener('touchcancel', function(event){
    touchid = undefined;
    touchdown = undefined;
    touchdown2 = undefined;
    event.preventDefault();
  }, false);
  dom.addEventListener('touchend', function(event){
    touchid = undefined;
    touchdown = undefined;
    touchdown2 = undefined;
    event.preventDefault(); //new
  }, false);
}

function hideTouchPad(){
  console.log('hiding touch pad ' + outer)
  if(outer){
  outer.style.display = 'none'
  }
}

function showTouchPad(){
  console.log('showing touch pad ' + outer)
  if(outer){
  outer.style.display = 'block'
  }
}

// moves touch pad when mobile changes screen orientation
function moveTouchPad(){
  var W = 300;
  var pad = -30;

  // in portrait mode
  if(window.innerHeight > window.innerWidth){
    outer.style.left = (window.innerWidth/2-W/2)+"px";
    outer.style.top = (window.innerHeight-W-pad)+"px";
  }
  // in landscape mode
  else{
    outer.style.left = (pad)+"px";
    outer.style.top = (window.innerHeight-W-pad)+"px";
    console.log('inner width is now greater than 600')
  }
}

module.exports = {newTouchPad,newTouchLook, hideTouchPad, showTouchPad, moveTouchPad}
