import { addToMyPreviousChats, initNewChat, initScene, getXInactiveUsers, getUserAvailabilityToChat, getNewChatID, notifyUsrOfChat, updateMyPosInDB, updateMyAvailabilityToChat } from './main.js'
import { players, panners, loadAudio, updateAudio, startBackgroundAudio, startMainUserAudio, stopMainUserAudio, loadExternalHeartbeats, startExternalHeartbeats, collectUsersOnLoad, removeUserAudio } from './new-audio.js'

export const maxusers = 100
const maxhusks = 3
export var isOnLogin = true
var sceneIsLoading = false

export class User {
  constructor (id) {
    if (id) { this.id = id }
    this.active = false
    this.name = ''
    this.loc = ''
    this.bpm = -1
    this.comment = ''
    this.room = 1
    this.pos = {
      x: 0,
      z: 0
    }
    this.chat = true
    this.currChatID = ''
    // }
  }
}

class Chat {
  constructor (id) {
    if (id) { this.id = id }
    this.users = []
    this.messages = []
  }
}

class Message {
  constructor (author, message, timestamp) {
    this.timestamp = timestamp
    this.message = author
    this.author = message
  }
}

var myPrevChats = {} // dictionary where key is usr ID of another user i've previously chatted with, and value is the associated chatID
var chats = []
var currChatIndex = -1 // if in a chat, this will be >= 0. Gives the current chat index within chats[]

export var user
var allUsers = []
var allUsersMeta = []
initAllUsers()

export function setSceneIsLoading (isLoading) {
  sceneIsLoading = isLoading
}

export function getSceneIsLoading(){
  return sceneIsLoading
}

export function onLoadScene () {
  loadAudio()
  loadThreeJS()
  // This is called at the beginning of loading scene state. Add what you need to here to load your part
}

export function startScene () {
  // put whatever you need to here to start your scene
  // such as initScene

  // USER INTRO FADE IN
  setTimeout(() => {
    updateAudio() // Track Camera Position/Orientation for Spatial Audio Mixer
    startMainUserAudio(user.id, user.bpm) // Start User Heartbeat Audio
    loadExternalHeartbeats() // Load External Heartbeats
  }, 500) // 10000

  // USER HAS ENTERED HUSK
  setTimeout(() => {
    startBackgroundAudio()// Start Drone and Wind
    stopMainUserAudio() // Fade Out Userheartbeat
    startExternalHeartbeats() // Start External Heartbeat Audio
  }, 500) // 20000

  // console.log({ mainuser: user })
  // console.log({ allUsers: allUsers })
  // console.log({ allmetadata: allUsersMeta })
  initScene(user, allUsers, allUsersMeta)
}

// determines if id pased in is the same as the current user's id
export function isMainUser (snapid) {
  // console.log('am i the main user?')
  // console.log(' id that was sent in: ' + snapid + ' saved user id: ' + user.id)
  if (snapid === user.id) { return true }
  return false
}

// init user object
export function initMainUser (theUser) {
  user = theUser
}

export function fillInputsWithUserPersistence (authUser) {
  initMainUser(authUser)
  // user = authUser
  if (authUser.name !== undefined) {
    document.getElementById('name_input_label').classList.add('active')
    document.getElementById('name_input').value = authUser.name
  }
  if (authUser.loc !== undefined) {
    document.getElementById('loc_input_label').classList.add('active')
    document.getElementById('loc_input').value = authUser.loc
  }
  if (authUser.comment !== undefined) {
    document.getElementById('comment_input_label').classList.add('active')
    document.getElementById('comment_input').value = authUser.comment
  }
}

// update my position in db
export function updateMyPos (x, z) {
  user.pos.x = x
  user.pos.z = z
  updateMyPosInDB(user.id, x, z)
}

// listener calls this event every time a new user has been added to this room
export function newUserAddedtoAllUsers (newUser) {
  // if adding a user while the scene is loading needs to behave differently use the sceneIsLoading var

  if (newUser.id) {
    // console.log({ foundfriend: newUser })
    collectUsersOnLoad(newUser)

    // if user was a user, just resurrect them
    for (let i = 0; i < allUsers.length; i++) {
      if (allUsers[i].id === newUser.id && allUsersMeta[i].isHusk) {
        allUsersMeta[i].isHusk = false
        Object.assign(allUsers[i], newUser)
        return
      }
    }

    if(allUsers.length == maxusers -1){

      // let's kill a husk to make space
      for (let i = 0; i < allUsers.length; i++) {
        if (allUsersMeta[i].isHusk) {
          allUsersMeta[i].isHusk = false
          Object.assign(allUsers[i], newUser)
          return
        }
      }
    }else {
      allUsers.push(newUser)
      allUsersMeta.push({isHusk: false})
      if(!sceneIsLoading){
        newAvatar(newUser, {isHusk: false})
        console.log('calling newavatar extras')
        newAvatarExtras()
      }
    }
    console.log('sorry! no spare room')
    // sorry, no spare rooms!
  }
}

// listener calls this event every time a user has either left the room or has become inactive
// for now this user needs to turn into a husk
// TODO: determine if user leaving left to go to another room or left website
export function removeUserfromAllUsers (userID) {
  removeUserAudio(userID)
  if (userID !== user.id) {
    for (let i = 0; i < allUsers.length; i++) {
      console.log('controller removing user ' + userID)
      if (allUsers[i].id === userID) {
        console.log('removing user at index ' + i)

        allUsers[i].active = false
        allUsersMeta[i].isHusk = true
        removeAvatar(userID)
        break
      }
    }
  }
}

// listener call this event every time another user's position has been updated
export function updateUserPosInAllUsers (userID, axis, value) {
  const getIndexOfUser = allUsers.findIndex(user => user.id === userID)
  if (getIndexOfUser === -1) {
    console.error('user searched does not exist in allUsers. sceneController.userPosUpdated()')
    return false
  }
  allUsers[getIndexOfUser].pos[axis] = parseFloat(value)
  // console.log({userupdated:allUsers[getIndexOfUser]})
}

// calculate number of husks that are needed to fill the scene. Retrieve husk info from db
export function getNumHusksNeeded () {
  var numHusksNeeded = maxhusks;
  // allUsersMeta.map(x => { x.isHusk ? (numHusksNeeded++) : 0 });

  function doit(){
    // console.log(getXInactiveUsers);

    try{
      getXInactiveUsers(numHusksNeeded);
      // console.log("getNumHusksNeeded success")
    }catch(e){
      // console.log("getNumHusksNeeded retry")
      setTimeout(doit,100);
    }
  }
  doit();
}

// inactive user added to husks
export function addInactiveUser (inusr) {
  for (let i = 0; i < allUsers.length; i++) {
    if (allUsersMeta[i].isHusk && allUsers[i].id.includes("--fake--")) {
      // console.log("replace",i,allUsers[i],inusr)
      Object.assign(allUsers[i], inusr)
      return;
    }
  }

  if(allUsers.length < maxusers){
    allUsers.push(inusr)
    allUsersMeta.push({isHusk: true})
  }
}

// called when database does not have enough past user data to fill the husks with.
// use this data to fill remaining husks that weren't filled by db.
function initAllUsers () {
  console.log('creating empty users')
  var nameChoice = ['Alice', 'Bob', 'Tom', 'Jerry', 'Mary', 'John', 'Joe']
  var locationChoice = ['Canada', 'US', 'UK', 'China', 'France', 'Germany', 'Japan', 'Spain', 'Italy', 'Mexico', 'Russia']
  var messageChoice = ['Hello world', 'Hi', 'Yo', "What's up", 'Good morning', 'Hello', 'Hiya', 'Hahaha', 'Hehehehe', 'Hey', 'Wow', 'This is awesome', 'I love it', 'wtf']

  // for (let i = 0; i < maxusers; i++) {

  for (let i = 0; i < maxhusks; i++) {
    var fakeUsr = new User("--fake--"+getRandomID())
    fakeUsr.name = nameChoice[Math.floor(nameChoice.length * Math.random())] + Math.floor(Math.random() * 100)
    fakeUsr.loc = locationChoice[Math.floor(locationChoice.length * Math.random())]
    fakeUsr.comment = messageChoice[Math.floor(messageChoice.length * Math.random())] + '! ' + messageChoice[Math.floor(messageChoice.length * Math.random())]
    fakeUsr.pos = { x: 0, z: 0 }
    allUsers.push(fakeUsr)
    allUsersMeta.push({ isHusk: true })
  }
  getNumHusksNeeded();
  // console.log(allUsers, allUsersMeta)
}

/// //// CHATS
// gets all the usrIds and associated ChatIds that i've previously chatted with
export function setPreviousChatIDs (userID, chatID) {
  myPrevChats[userID] = chatID
}

export function toggleChatFunction (chatIsOn) {
  updateMyAvailabilityToChat(user.id, chatIsOn, true)
}

// trigger this when clicking on another user
// check if I already have chatted with that usr before.
// returns string, cannot chat with this user
// returns chat object, previous messages with user and can continue chatting
// returns true, initiate new chat w/ user
export function canChatWithUser (usrID, userName) {
  if (user.chat === false) {
    return 'Turn chat on to chat. Press \'c\' on keybaord'
  }

  // check if user's chat is unblocked
  var canChat = getUserAvailabilityToChat(usrID)
  if (canChat.on === false) {
    // this user has turned off the chat functionality
    return userName + '\'s has chat turned off'
  } else if (canChat.avail !== true) {
    // this user is busy with another user
    return userName + ' is in another conversation right now'
  }

  // this user is available to chat
  // if chatted before w/ usr, get last 20 messages to display
  if (myPrevChats.userID !== undefined) {
    // i have previously chatted with this user. Get previous messages

    // check locally for messages
    var chatInedex = chats.findIndex(chat => chat.id === myPrevChats.userID)
    if (chatInedex === -1) {
      // pull previous messages from db
      // TODO
    } else {
      currChatIndex = chatInedex
      return chats[chatInedex]
    }
  }
}

export function getUserWithID(userID){
  // const indexOfUser = allUsers.findIndex(user => user.id === userID)
  return allUsers[allUsers.findIndex(user => user.id === userID)]
}

// called when the first message is sent to a user to start chatting
// if chat ID is passed in, this is a continuation from a previous chat
// otherwise init new chat
// export function initChatWithUser (usrID, message, chatID) {
//   // continue previous chat, using this chatID
//   if (chatID !== undefined) {

//   } else {
//     // init new chat
//     var newChatID = getNewChatID()
//     user.currChatID = newChatID
//     initNewChat(newChatID, user.id, usrID, message)
//     updateMyAvailabilityToChat(user.id, true, newChatID)
//     notifyUsrOfChat(usrID, chatID)
//   }
//   return true
// }

// export function isMyCurrChatID (chatID) {
//   return user.currChatID === chatID
// }

// // someone sent me a message
// export function initChatByAnotherUsr (chatID, chatInfo) {
//   // have I chatted with this person before ?
//   var otherUsr
//   chatInfo.usrs.forEach(usr => {
//     // get other user from chatIDs
//     if (usr !== user.id) {
//       otherUsr = usr
//     }
//   })
//   var haveChatted = myPrevChats[otherUsr]

//   if (haveChatted === undefined) {
//     // we have not chatted before

//     // add to prevchats list
//     myPrevChats.otherUsr = chatID
//     addToMyPreviousChats(user.id, otherUsr, chatID)

//     var newChat = new Chat(chatID)
//     newChat.users = chatInfo.usrs
//   } else {
//     // we've chatted before.
//     // display previous messages from local chats
//     var chatIndex = chats.findIndex(chat => chat.id === myPrevChats.userID)
//     if (chatIndex === -1) {
//       // pull previous chat messages from the db
//       // TODO
//     } else {
//       currChatIndex = chatIndex
//       // send lingdong previous chat messages: chats[chatInedex].messages
//     }
//   }

//   var chatInedex = chats.findIndex(chat => chat.id === myPrevChats.userID)
//   if (chatInedex === -1) {}
// }

function getRandomID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0; var v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function getRandomDate () {
  var d = (new Date(new Date() - 10000000000000 * Math.random())).toString().split(' ')
  return d.slice(0, 3).join(' ') + '<br>' + d[4]
}

export function randomIntFromInterval (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
