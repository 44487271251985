
window.dematerialize = function(){
    function walk(node, func) {
       var children = node.childNodes;
       for (var i = 0; i < children.length; i++){
           walk(children[i], func);
       }
       func(node);
    }
    walk(document.body, function(x){
        if (x.classList){
            x.classList.add("browser-default");
        }
    })
}