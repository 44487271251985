window.ISFRENCH = false;
window.ISMOBILE = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) || navigator.maxTouchPoints > 1
window.ISIPHONE = /(iPhone|iPod|iPad)/i.test(navigator.userAgent) // true or false statement

console.log("ISPHONE " + ISIPHONE)
window.hasFocus = true;
window.onfocus = function(){window.hasFocus=true}
window.onblur = function(){window.hasFocus=false}

window.pointed = null; // the user I am currently pointing or interacting with
window.isgoto = false;
window.ischat = false;

var {updateMyPos, getUserWithID} = require("./sceneController.js")
var {newTouchPad,newTouchLook,hideTouchPad, showTouchPad, moveTouchPad} = require("./touch-controls.js");
var chat = require('./chat.js')
var hasChatted = false // used as a tutorial to guide the user to click/tap on the user
var isDisplayingMessage = false // true when a non-tutorial related message is being displayed

var THREE = require('three')
require("../js/three/EffectComposer.js")
require("../js/three/RenderPass.js")
require("../js/three/ShaderPass.js")
require("../js/three/CopyShader.js")
require("../js/three/FXAAShader.js")
require("../js/three/LuminosityHighPassShader.js")
require("../js/three/UnrealBloomPass.js")
require("../js/three/OrbitControls.js")
require("../js/three/PointerLockControls.js")
require("../js/three/Reflector.js")
require("../js/three/GLTFLoader.js")
require("../js/three/DeviceOrientationControls.js")
const dat = require("../js/dat.gui.min.js")

var Shaders = require("./shaders.js")


var PARAMS = {
  "noiseparam1": 0.28,
  "noiseparam2": 1,
  "noiseparam3": 10,
  "growthtobeat": 1,
  "alpha": 0.11476822333275892,
  "darkest": 0.02,
  "morphspeed": {
    "x": 0.02,
    "y": 0.02,
    "z": 0.02,
    "variance": 0.5
  },
  "tint": {
    "r": 0,
    "g": 0,
    "b": 0,
    "r2": 1,
    "g2": 0.2526427061310782,
    "b2": 0
  },
  "env": {
    "darkest": 0,
    "brightest": 0,
    "light": {
      "x": -1,
      "y": -1,
      "z": -1
    },
    "ambient": {
      "r": 0.5, // was 0.3, now 0.5 //tegan 1
      "g": 0.5, // was 0.3, now 0.5
      "b": 0.8 // was 0.6, now 0.8
    }
  },
  "bloomradius": 0.4973572938689218,
  "bloomstrength": 3.63107822410148,
  "camera": {
    "path": [
      [
        0,
        200,
        300
      ],
      [
        0,
        100,
        200
      ],
      [
        0,
        25,
        100
      ],
      [
        0,
        12,
        0
      ]
    ],
    "lookat": 12,
    "steps": 1600,
    "drift": {
      "x": 1.5,
      "y": 0,
      "z": 1.5,
      "noise": 0,
      "probchange": 0.001,
      "easing": 0.99
    },
    "easing": 0.5,
    "maxlabeldist": 200
  },
  "timer": {
    "camera": 0,
    "jitter": 2000,
    "lightup": 3000,
    "lightupothers": 1000,
    "gaincontrol": 9000,
    "rezoomactivationdelay": 2000 //2000
  },
  "anim": {
    "bulge": 10,
    "jitter": 0.05,
    "startradius": 80,
    "stopradius": 5,
    "crawlspeed": 0.1,
    "turnspeed": 0.4,
    "fpsdelta": 0.005
  },
  "shadow": {
    "const1": 3.1035671204549367, // tegan 0
    "const2": 10.373944511459591
  },
  "walls": {
    "x": 70,
    "z": 200
  },
  "oob": {
    "timer": 1000,
    "mobiletimer": 60,
    "backspeed": 0.06
  }
}

// Instantiate a loader
var loader = new THREE.GLTFLoader();
var envLoaded = false;
// Load a glTF resource

function addParamsGui(){
  if (window.NO_PARAMS_GUI == undefined){
    setTimeout(addParamsGui,100);
    return;
  }
  if (window.NO_PARAMS_GUI){
    return;
  }
  window.paramUploader = document.createElement("input");
  paramUploader.type = "file";
  paramUploader.style= "position:absolute;left:90px;top:20px;z-index:10000";
  document.body.appendChild(paramUploader);
  paramUploader.onchange = function(){
    var file = this.files[0];
    if (!file) {
        return;
    }
    var fileReader = new FileReader();
    fileReader.onload = function(e) {
        var text = e.target.result;
        PARAMS = JSON.parse(text);
    };
    fileReader.readAsText(this.files[0]);
  }

  let paramDownloader = document.createElement("button");
  paramDownloader.type = "file";
  paramDownloader.style= "position:absolute;left:90px;top:0px;z-index:10000";
  paramDownloader.innerHTML = "Download PARAMS file"
  document.body.appendChild(paramDownloader);
  paramDownloader.onclick = function(){
    function download(file, text) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8, '
                           + encodeURIComponent(text));
      element.setAttribute('download', file);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    download("PARAMS.JSON",JSON.stringify(PARAMS));
  }

  paramDownloader.style.display = window.debugMode?"block":"none"
  paramUploader.style.display = window.debugMode?"block":"none"


}
addParamsGui();

window.threeJSLoaded = 0

// on scene load
window.loadThreeJS = function(){

  // loads GLTF model for 3js
loader.load(
  // resource URL
  '/src/assets/models3d/HangarBicocca_20200619.glb',
  // called when the resource is loaded
  function ( gltf ) {
      // White directional light at half intensity shining from the top.

    // var geometry = new THREE.BoxGeometry(10,10,10);
    // var material = new THREE.MeshLambertMaterial( { color: 0x00ff00 } );
    // var cube = new THREE.Mesh( geometry, material );
    // scene.add( cube );
    // cube.layers.enable(0);
    envLoaded = true;
    gltf.scene.layers.enable(1);
    gltf.scene.layers.disable(0);
    // console.log(gltf.scene)

    function attemptToAddToScene(){
      if (window.scene){
        window.scene.add( gltf.scene );
        window.gltfScene = gltf.scene;


  // var directionalLight = new THREE.DirectionalLight( 0xffffff, 1.0 );
  // scene.add( directionalLight );
  // directionalLight.layers.enable(1);

  // var light = new THREE.PointLight( 0x111111); // soft white light
  // light.position.y = 40;
  // scene.add( light );
  // light.layers.enable(1);

// window.light = new THREE.AmbientLight( 0x404040 ); // soft white light
// scene.add( window.light );
// window.light.layers.enable(1);

        return;
      }
      setTimeout(attemptToAddToScene,100);
    }
    attemptToAddToScene();
    gltf.scene.scale.x = 1;
    gltf.scene.scale.y = 1;
    gltf.scene.scale.z = 1;
    gltf.scene.position.y = -5
    gltf.scene.position.z = 250
    // gltf.scene.traverse(function(child){console.log(child.name)});
    // console.log("----------------------------------------------")
    gltf.scene.traverse( function ( child ) {
      // child.material.side = THREE.DoubleSide;
      // child.material = new THREE.MeshBasicMaterial( { color: 0xFF0000 } );
      // child.material = new THREE.ShaderMaterial({
      //   uniforms: {
      //     darkest:{
      //       type: "float",
      //       value: 0.005
      //     },
      //     brightest:{
      //       type: "float",
      //       value: 0.01
      //     },
      //     light:{
      //       type:"v3",
      //       value: new THREE.Vector3(0.1,0.2,0.3)
      //     }
      //   },
      //   vertexShader: Shaders.vertEnv,
      //   fragmentShader: Shaders.fragEnv,
      // });

      if (child.material && child.material.map){

        child.material = new THREE.MeshBasicMaterial( { color:0xFFFFFF,map: child.material.map} );
        // child.material.side = THREE.BackSide;
        child.material.needsUpdate = true;
      }
      child.traverse(function(grand){
        if (grand.material && grand.material.map){
          grand.material = new THREE.MeshBasicMaterial( { color:0xFFFFFF,map: grand.material.map} );
          // console.log(grand)
          // grand.material.side = THREE.BackSide;
          grand.material.needsUpdate = true;
        }
        grand.traverse(function(great){
          if (great.material && great.material.map){
            great.material = new THREE.MeshBasicMaterial( { color:0xFFFFFF,map: great.material.map} );
            // console.log(grand)
            // grand.material.side = THREE.BackSide;
            great.material.needsUpdate = true;
          }
        })

      })
      child.layers.enable(1);
      child.layers.disable(0);
      // child.scale.x = 10;
      // child.scale.y = 10;
      // child.scale.z = 10;
    } );

    gltf.animations; // Array<THREE.AnimationClip>
    gltf.scene; // THREE.Group
    gltf.scenes; // Array<THREE.Group>
    gltf.cameras; // Array<THREE.Camera>
    gltf.asset; // Object

  },
  // called while loading is progressing
  function ( xhr ) {
    var pc = ( xhr.loaded / xhr.total * 100 ); // pc doesn't work once on firebase server. xhr.total doesn't give actual file size of model
    var gltfFileSize = 14282448 // needs updating if 3d model changes
    threeJSLoaded = (xhr.loaded / gltfFileSize) * 100
    // if (Math.random()<0.2 || pc > 90){
    //   console.log( pc + '% loaded' );
    // }

  },
  // called when loading has errors
  function ( error ) {

    // console.log( 'An error happened' ,error);

  }
);
}

window.requestDeviceOrientation = function () {
  console.log("requestion device orientation ")
 THREE.DeviceOrientationRequest()
}

var mobileOOBLastQuaternion = THREE.Quaternion()
var avatars = [];

function poisson(samples, rng){
  //mitchel's best candidate
  var cands = []
  for (var i = 0; i < 16; i++){
    cands.push(rng());
  }
  var Md = -Infinity;
  var Mi = 0;
  for (var i = 0; i < cands.length; i++){
    var md = Infinity;
    for (var j = 0; j < samples.length; j++){
      var d = Math.sqrt(Math.pow(cands[i].x-samples[j].x,2)+Math.pow(cands[i].y-samples[j].y,2));
      if (d < md){
        md = d;
      }
    }
    if (md > Md){
      Md = md;
      Mi = i;
    }
  }
  return cands[Mi];
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function randomInfo(){


  var nameChoice = ["Alice","Bob","Tom","Jerry","Mary","John","Joe"];
  var locationChoice = ["Canada","US","UK","China","France","Germany","Japan","Spain","Italy","Mexico","Russia"];
  var messageChoice = ["Hello world","Hi","Yo","What's up","Good morning","Hello","Hiya","Hahaha","Hehehehe","Hey","Wow","This is awesome","I love it","wtf"];
  return {
    name: nameChoice[Math.floor(nameChoice.length*Math.random())]+Math.floor(Math.random()*100),
    loc: locationChoice[Math.floor(locationChoice.length*Math.random())],
    comment: messageChoice[Math.floor(messageChoice.length*Math.random())]+"! "+messageChoice[Math.floor(messageChoice.length*Math.random())],
    id:uuidv4(),
  }
}

window.removeAvatar = function(removedUserID){
  console.log('scene removing user: ' + removedUserID)
  for(var i =0; i < avatars.length; i++){
    if(avatars[i].info.id == removedUserID){
      console.log('removing user at index: ' + i)
      console.log(avatars[i])
      avatars[i].meta.isHusk = true
      avatars[i].on = false
      avatars[i].beattime = 0
    }
  }
  
}

window.newAvatar = function(info,meta){
  // console.log(info,meta)
    let material = new THREE.ShaderMaterial({
    uniforms: {
      color: {
        type: "v3",
        value: new THREE.Vector3(1.0,1.0,1.0)
      },
      noiseseed: {
        type: "v3",
        value: new THREE.Vector3(Math.random()*10,Math.random()*10,Math.random()*10)
      },
      noiseparam1:{
        type: "float",
        value: 0.5
      },
      noiseparam2:{
        type: "float",
        value: 1.0
      },
      noiseparam3:{
        type: "float",
        value: 10
      },
      alpha:{
        type: "float",
        value: PARAMS.alpha
      }
    },
    vertexShader:Shaders.vertFig,
    fragmentShader:Shaders.fragFig,
    // side: THREE.DoubleSide,
      transparent:true,
  });
  var x, y;
  if (avatars.length == -1){
    ;;;;;;;;;;;[x,y] = [0,0];
  }else if (info.pos.x || info.pos.z){
    // console.log("using pos from database:",info.pos);;;;;;;;;;;;;;;;;;;;;
    ;;;;;;;;;;;[x,y] = [info.pos.x,info.pos.z]
  }else{
    var {x,y} = poisson(avatars.map(x=>({x:x.mesh.position.x,y:x.mesh.position.z})),()=>({x:Math.random()*140-70, y:Math.random()*400-200}));
    info.pos.x = x;
    info.pos.z = y;
  }


  var obj = new THREE.Mesh(new THREE.SphereGeometry(3, 50, 50), material);
  var s = avatars.length==0? 2.5 : (1.5+Math.random()*1.5);
  obj.applyMatrix4(new THREE.Matrix4().makeTranslation(0,3.2,0));
  obj.applyMatrix4( new THREE.Matrix4().makeScale( 1.0, s, 0.5 ) );

  obj.position.x = x;
  obj.position.z = y;

  // console.log(s/2)
  obj.rotation.y = Math.random()*Math.PI*2

  obj.layers.disable(1);
  obj.layers.enable(0);
  scene.add(obj);

  var tintChoices = [[1,0,0],[1,0.5,0],[1,1,0],[1,1,1]];

  var bpm = parseFloat((info!=undefined)?(info.bpm):60);
  if (isNaN(bpm)){
    bpm = 60;
  }
  function randomDate(){
    var d = (new Date(new Date()-10000000000000*Math.random())).toString().split(" ");
    return d.slice(0,3).join(" ")+"<br>"+d[4];
  }
  function notRandomDate(){
    var d = (new Date(info.timestamp)).toString().split(" ");
    return d.slice(0,3).join(" ")+"<br>"+d[4];
  }
  {
    let av = {
      beattime:0,
      bpm:bpm,
      amp:  Math.random()*0.5+0.5,
      mesh:obj,
      // shadow:quad,
      mat:material,
      on: (avatars.length) && (!meta.isHusk),
      supressed:true,
      x:x,
      y:y,
      scale:s,
      bulge:1,
      lazy: Math.random(),
      bright:0,
      morphspeed: Math.random(),
      info: info,
      meta: meta,
      messages:[],
      timestamp:notRandomDate(),
      tint: tintChoices[Math.floor(tintChoices.length*Math.random())]
    }
    // console.log(av.info.dead)
    av.beatfun = function(){
      av.beattime = 0;
    }
    av.mesh.u_parent = av;
    avatars.push(av);
  }
}

window.newAvatarExtras = function () {
  var quadgeom = new THREE.PlaneGeometry(500,500);
// var quadmat = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
var quaduni = {};
var avatarIndex = avatars.length -1
avatars[avatarIndex].supressed = false
// for (var i = 0; i < avatars.length; i++){
  quaduni["u"+avatarIndex.toString().padStart(2,'0')] = {
    type:"v4",
    value:new THREE.Vector4(
      (avatars[avatarIndex].mesh.position.x+250)/500,
      (-avatars[avatarIndex].mesh.position.z+250)/500,
      0.05,
      avatars[avatarIndex].scale*0.02)
  }
  quaduni["c"+avatarIndex.toString().padStart(2,'0')] = {
    type:"v3",
    value:new THREE.Vector3(
      0,0,0)
  }
// }
var quadmat = new THREE.ShaderMaterial({
  uniforms: quaduni,
  vertexShader: Shaders.vertFloor,
  fragmentShader: Shaders.fragFloor,
  // side: THREE.DoubleSide,
  transparent:true,
});
var quad = new THREE.Mesh( quadgeom,quadmat );
quad.rotation.x = -Math.PI/2
quad.position.x = 0;
quad.position.z = 0;
quad.position.y = 0.5;
quad.layers.enable(1);
quad.layers.disable(0);
scene.add(quad);

}

// once scene starts
module.exports = {initScene:function(thisUser, dbUsers, dbUsersMeta){
  RTDBChat_setUserId(thisUser.id);
  chat.setUser(thisUser)

  console.log('starting scene')

  if (typeof window.paramUploader != undefined && window.paramUploader != undefined){
    try{
      window.paramUploader.remove();
    }catch(e){

    }
  }

var mobileControls = false
if(ISMOBILE || ISIPHONE){
  mobileControls = true
}

  // try{
  //   screen.orientation.lock(screen.orientation.type);
  // }catch(e){}
  // try{
  //   screen.lockOrientation("orientation");
  // }catch(e){}
  // try{
  //   screen.lockOrientationUniversal = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
  // }catch(e){}

  // document.body.requestPointerLock()
  // dbUsers = dbUsers.slice(0,50);
  // console.log(thisUser);
  // console.log(JSON.stringify(dbUsers));
/* global describe THREE dat Stats */
{
  let div = document.createElement("div");
  div.innerHTML = "";//ISMOBILE?"":"Click anywhere to start"
  div.style = "position:absolute;max-width:50%;right:10px;bottom:10px;color:white;pointer-events:none;z-index:100;font-family:sans-serif"
  div.id="hint"
  document.body.appendChild(div);
}

{
  let div = document.createElement("div");
  div.innerHTML = ISFRENCH?"<span id=chatStatus><span style='color:lime'>•</span> Clavardage: Activé</span><span id=soundStatus><span style='color:lime'>•</span> Son: Activé</div>":"<span id='chatStatus'><span style='color:lime'>•</span> Chat: On </span><span id=soundStatus> <span style='color:lime'>•</span> Sound: On</span>";
  div.style = "max-width:50%;position:absolute;left:10px;bottom:10px;color:white;z-index:100;font-family:sans-serif;cursor:pointer;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none; -webkit-tap-highlight-color:transparent;"
  div.id="status"
  document.body.appendChild(div);
  document.getElementById('chatStatus').addEventListener('click', enableChatFunc)
  document.getElementById('soundStatus').addEventListener('click', enableSoundFunc)
}
{
  let div = document.createElement("div");
  div.innerHTML = "+"
  if(mobileControls){
    div.style = "position:absolute;width:100%;left:0px;top:40%;color:white;pointer-events:none;z-index:100;font-family:sans-serif;text-align:center"

  }else{
    div.style = "position:absolute;width:100%;left:0px;top:50%;color:white;pointer-events:none;z-index:100;font-family:sans-serif;text-align:center"
  }
  div.id="crosshair"
  document.body.appendChild(div);
}

if(ISMOBILE && document.webkitFullscreenElement){
  {
    let div = document.createElement('div');
    div.innerHTML = "<i class='fas fa-compress fa-lg'></i>"
    div.style = "position:absolute; right: 15px; top: 15px; color:white; z-index:100; "
    div.id="toggleFullScreen"
    document.body.appendChild(div);
    document.getElementById('toggleFullScreen').addEventListener('click', toggleFullScreenMode)
  }
}

{
  let div = document.createElement("div");
  div.style = "display:none;position:absolute;width:300px;height:120px;left:calc(50% - 150px);top:60%;color:white;z-index:100;font-family:sans-serif;background:rgba(0,0,0,0.5);font-family:sans-serif;border:none;border-radius:3px"
  div.id = "chat";
  div.innerHTML = `
    ${ISFRENCH?"Clavardage":"Chat"}: <span id="chatname">Someone</span>
    <div id="chathist" style="font-size:16px;width:298px;height:70px;overflow:hidden;overlfow-x:hidden;overflow-y:scroll;border:1px solid gray;line-height:16px;"></div>
    <textarea style="resize:none;overflow:hidden;font-size:16px;background:none;color:white;width:298px;height:20px;"
              placeholder="${ISFRENCH?"Tapez quelque chose...":"Type something..."}"
              id="chatinp">
    </textarea>
    <div style="color:gray;text-align:right;width:300px;font-size:10px">${ISFRENCH?"Appuyez sur Entrée pour soumettre":"Press Enter to submit"}&nbsp;</div>

  `
  div.onclick = function(e){
    e.stopPropagation();
    e.preventDefault();
  }
  document.body.appendChild(div);
}
{
  let img = document.createElement("img");
  img.src="/src/assets/images/vignette_red.png";
  img.style="z-index:2;position:absolute;width:100%;height:100%;left:0px;top:0px;pointer-events:none;opacity:0"
  img.id="vig";
  document.body.appendChild(img);
}
{
  let img = document.createElement("img");
  img.src="/src/assets/images/vignette2_red.png";
  img.style="z-index:2;position:absolute;width:100%;height:100%;left:0px;top:0px;pointer-events:none;opacity:0"
  img.id="vig2";
  document.body.appendChild(img);
}


var lbldiv = document.createElement("div");
lbldiv.innerHTML = ""
lbldiv.style = `${ISMOBILE?"background:":""};position:absolute;left:0px;top:0px;color:white;pointer-events:none;z-index:100;font-family:sans-serif;text-align:center;width:${ISMOBILE?500:1000}px;text-shadow:0px 0px 5px rgba(0,0,0,1.0)`
lbldiv.id="label"
document.body.appendChild(lbldiv);


document.body.style.background="black";

var scene = window.scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(60, window.innerWidth/ window.innerHeight, 1, 10000);
camera.position.set(0,200,300);
camera.lookAt(new THREE.Vector3(0,PARAMS.camera.lookat,0))
camera.layers.enable(1);



var renderer2 = new THREE.WebGLRenderer({antialias: false});
renderer2.setSize(window.innerWidth, window.innerHeight);

renderer2.domElement.style.opacity = 0.0;
renderer2.domElement.style.position = "absolute";
renderer2.domElement.style.left = "0px"
renderer2.domElement.style.top = "0px"
renderer2.domElement.style.pointerEvents = "none"
document.body.appendChild(renderer2.domElement);

var renderer = new THREE.WebGLRenderer({antialias: false, alpha:true});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0xFF0000, 0);

renderer.domElement.style.opacity = 0.0;
renderer.domElement.style.left = "0px"
renderer.domElement.style.top = "0px"
renderer.domElement.style.cursor = "pointer"
renderer.domElement.style.webkitTapHighlightColor = "transparent"

renderer.domElement.style.mixBlendMode = "screen";
document.body.appendChild(renderer.domElement);

// var controls = new THREE.OrbitControls(camera, renderer.domElement);
// pick controls to move camera around
var controls
if(mobileControls){
  controls = new THREE.DeviceOrientationControls(camera)
}
else{
  controls = new THREE.PointerLockControls( camera, document.body );
}

controls.paused = true;

var raycaster = new THREE.Raycaster();


window.getCameraPosition = function(){
  return new THREE.Vector3(camera.position.x,camera.position.y,camera.position.z);
}
window.getCameraOrientation = function(){
  var forward = new THREE.Vector3();
  camera.getWorldDirection(forward);
  return {
    forward,
    up: new THREE.Vector3(
      camera.up.x,
      camera.up.y,
      camera.up.z,
    ),
    quaternion:new THREE.Quaternion(
      camera.quaternion.x,
      camera.quaternion.y,
      camera.quaternion.z,
      camera.quaternion.w,
    ),
  }
}
window.getFigurePosition = function(userId){
  for (var i = 0; i < avatars.length; i++){
    if (avatars[i].info && avatars[i].info.id == userId){
      return new THREE.Vector3(
        avatars[i].mesh.position.x,
        avatars[i].mesh.position.y,
        avatars[i].mesh.position.z
      )
    }
  }
  // not found;
  return null;
}
window.getAllFigurePositions = function(){
  var ret = {}
  for (var i = 0; i < avatars.length; i++){
    if (avatars[i].info && avatars[i].info.id && !avatars[i].meta.isHusk){
      ret[avatars[i].info.id] = new THREE.Vector3(
        avatars[i].mesh.position.x,
        avatars[i].mesh.position.y,
        avatars[i].mesh.position.z
      )
    }
  }
  return ret;
}

window.getBeatCallbacks = function(){
  var ret = {}
  for (var i = 0; i < avatars.length; i++){
    if (avatars[i].info && avatars[i].info.id && !avatars[i].meta.isHusk){
      ret[avatars[i].info.id] = avatars[i].beatfun;
    }
  }
  return ret;
}

// material = new THREE.MeshBasicMaterial({color: "white", wireframe: false})











newAvatar(thisUser,{});
for (var i = 0; i < dbUsers.length; i++){
  newAvatar(dbUsers[i],dbUsersMeta[i]);
}
{
  let {x,y} = poisson(avatars.map(x=>({x:x.mesh.position.x,y:x.mesh.position.z})),()=>({x:Math.random()*140-70, y:Math.random()*400-200}));
  avatars[0].mesh.position.x = x;
  avatars[0].mesh.position.z = y;
  avatars[0].x = x;
  avatars[0].y = y;
}
camera.position.set(avatars[0].mesh.position.x,200,avatars[0].mesh.position.z+300);


var Player;
if (ISMOBILE){
  // makes joystick visible and usable at start
  setTimeout(function(){
    Player ={x:-avatars[0].mesh.position.x,z:-avatars[0].mesh.position.z,rot:-Math.PI/2,rot2:0,spd:0.1};
    var pad = newTouchPad(Player,function(){if (isOOB){OOBOff()} hasntBeenInteracting = 0}, );
    // var pad = newTouchPad(Player, function(){} );
    newTouchLook(Player,renderer.domElement,pad().dom,function(){if (isOOB){OOBOff()}})

  },PARAMS.timer.gaincontrol)
}
var showLabels = false;

// show elements and make interactive 5s after scene initiates
setTimeout(function(){
  showLabels = true;
  updateMyRoomAvailability(thisUser.id, true)
},PARAMS.timer.gaincontrol+3000)

var quadgeom = new THREE.PlaneGeometry(500,500);
// var quadmat = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
var quaduni = {};
for (var i = 0; i < avatars.length; i++){
  quaduni["u"+i.toString().padStart(2,'0')] = {
    type:"v4",
    value:new THREE.Vector4(
      (avatars[i].mesh.position.x+250)/500,
      (-avatars[i].mesh.position.z+250)/500,
      0.05,
      avatars[i].scale*0.02)
  }
  quaduni["c"+i.toString().padStart(2,'0')] = {
    type:"v3",
    value:new THREE.Vector3(
      0,0,0)
  }
}
var quadmat = new THREE.ShaderMaterial({
  uniforms: quaduni,
  vertexShader: Shaders.vertFloor,
  fragmentShader: Shaders.fragFloor,
  // side: THREE.DoubleSide,
  transparent:true,
});
var quad = new THREE.Mesh( quadgeom,quadmat );
quad.rotation.x = -Math.PI/2
quad.position.x = 0;
quad.position.z = 0;
quad.position.y = 0.5;
quad.layers.enable(1);
quad.layers.disable(0);
scene.add(quad);


//        var geometry = new THREE.CircleBufferGeometry( 100, 64 );
//        var groundMirror = new THREE.Reflector( geometry, {
//          clipBias: 0.003,
//          textureWidth: window.innerWidth,
//          textureHeight: window.innerHeight,
//          color: 0x111111
//        } );
//        groundMirror.position.y = 0.0;
//        groundMirror.rotateX( - Math.PI / 2 );
// groundMirror.layers.enable(1);
//        scene.add( groundMirror );

/** COMPOSER */
var renderScene = new THREE.RenderPass( scene, camera )

// var effectFXAA = new THREE.ShaderPass( THREE.FXAAShader )
// effectFXAA.uniforms.resolution.value.set( 1 / window.innerWidth, 1 / window.innerHeight )

var bloomPass = new THREE.UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 )
bloomPass.threshold = 0.0

bloomPass.radius = PARAMS.bloomradius
bloomPass.strength = PARAMS.bloomstrength
bloomPass.renderToScreen = false

bloomPass.clearColor = new THREE.Color(0,0,0,0);

// for (let i = 0; i < 5; i++){
//   // console.log(bloomPass,bloomPass.renderTargetHorizontal,bloomPass.renderTargetVertical)
//   bloomPass.renderTargetsHorizontal[i].texture.format = THREE.RGBAFormat;
//   bloomPass.renderTargetsVertical[i].texture.format = THREE.RGBAFormat;
// }


var composer = new THREE.EffectComposer( renderer )
composer.setSize( window.innerWidth, window.innerHeight )

composer.addPass( renderScene )
// composer.addPass( effectFXAA )
composer.addPass( bloomPass )

renderer.gammaInput = true
renderer.gammaOutput = true
renderer.toneMappingExposure = Math.pow( 0.9, 4.0 )


function sigmoid(x, a){
  a = Math.min(Math.max(a,0.0001),0.99999);
  a = 1-a;

  var y = 0;
  if (x<=0.5){
    y = (Math.pow(2.0*x, 1.0/a))/2.0;
  }
  else {
    y = 1.0 - (Math.pow(2.0*(1.0-x), 1.0/a))/2.0;
  }
  return y;
}

function cameraAnimation(pts,lookx,lookz){
  var pts3 = []
  for (var i = 0; i < pts.length; i++){
    pts3.push(new THREE.Vector3(pts[i][0],pts[i][1],pts[i][2]));
  }

  // console.log(pts3);
  var curve = new THREE.CatmullRomCurve3( pts3 );
  // var cpts = curve.getPoints( PARAMS.camera.steps );
  var cpts = [];
  for (var i = 0; i < PARAMS.camera.steps; i++){
    var x = (i)/(PARAMS.camera.steps-1.0);
    var y = sigmoid(x,PARAMS.camera.easing);
    cpts.push(curve.getPoint(y));
  }

  // console.log(cpts)
  for (var i = 0; i < cpts.length; i++){
    ;;;(function (){
      var _i = i;
      setTimeout(function(){
        // console.log(camera.position)
        camera.position.x = cpts[_i].x;
        camera.position.y = cpts[_i].y;
        camera.position.z = cpts[_i].z;

        var oldquat = new THREE.Quaternion(
          camera.quaternion.x,
          camera.quaternion.y,
          camera.quaternion.z,
          camera.quaternion.w,
        )
        camera.lookAt(new THREE.Vector3(lookx,PARAMS.camera.lookat,lookz))
        var newquat = new THREE.Quaternion(
          camera.quaternion.x,
          camera.quaternion.y,
          camera.quaternion.z,
          camera.quaternion.w,
        )
        // console.log(Math.max(0.001,0.2-md*0.01));
        oldquat.slerp(newquat,0.01);
        camera.quaternion.x = oldquat.x;
        camera.quaternion.y = oldquat.y;
        camera.quaternion.z = oldquat.z;
        camera.quaternion.w = oldquat.w;


        // camera.lookAt(new THREE.Vector3(lookx,PARAMS.camera.lookat,lookz));
      },_i*5);
    })();
  }

  // if(mobileControls){
  //   setTimeout(function () {
  //     console.log('ready for second camera animation')

  //     var transitionTime = 20000
  //     for (var i = 0; i < transitionTime/10; i++){
  //       setTimeout(function(){
  //         let tt = PARAMS.oob.backspeed;
  //           // controls.getDeviceOrientation()
  //           // camera.position.x = camera.position.x*(1-tt) + avatars[0].mesh.position.x*tt;
  //           // camera.position.y = camera.position.y*(1-tt) +       PARAMS.camera.lookat*tt;
  //           // camera.position.z = camera.position.z*(1-tt) + avatars[0].mesh.position.z*tt;
          
  //         var oldquat = new THREE.Quaternion(
  //           camera.quaternion.x,
  //           camera.quaternion.y,
  //           camera.quaternion.z,
  //           camera.quaternion.w,
  //         )
  //         var newquat = controls.getDeviceOrientation();
  //         console.log(oldquat, newquat)
  //         oldquat.slerp(newquat,0.1);
  //         camera.quaternion.x = oldquat.x;
  //         camera.quaternion.y = oldquat.y;
  //         camera.quaternion.z = oldquat.z;
  //         camera.quaternion.w = oldquat.w;
  //         console.log(oldquat)
  //       },i*10);
        
  //     }

  //     setTimeout(function(){
  //         var finalOrientation = controls.getDeviceOrientation()
  //         camera.quaternion.x = finalOrientation.x;
  //         camera.quaternion.y = finalOrientation.y;
  //         camera.quaternion.z = finalOrientation.z;
  //         camera.quaternion.w = finalOrientation.w;
  //         // camera.rotation.x = 0;
  //         // camera.rotation.z = 0;
  //       },transitionTime);
      
  //   }, cpts.length * 5)
  // }
}


for (var i = 0; i < 500; i++){
  ;;;(function(){
    let _i = i;
    setTimeout(function(){
      renderer.domElement.style.opacity = _i*0.002;
      renderer2.domElement.style.opacity = _i*0.002;
    },i*10)
  })()
}

var moveForward = false;
var moveBackward = false;
var moveLeft = false;
var moveRight = false;
var velocity = new THREE.Vector3();
var driftV = new THREE.Vector3();
var driftLookat = new THREE.Vector3(2000,PARAMS.camera.lookat,2000);
var cachedQuat = new THREE.Quaternion();
var direction = new THREE.Vector3();

render();
var t = 0;

var isInteracting = false;
var hasntBeenInteracting = 0;
var isOOB = false;
var isTurningOffOOB = false;
var isHoldingKey = false;

var firstTime = false;

var enabledchat = true;

RTDBChat_setMute(thisUser.id,false);
RTDBChat_setInvite(thisUser.id,null);


var enabledsound = true;

var onKeyDown = function ( event ) {
  if (isOOB && !isTurningOffOOB){
    OOBOff();
  }
  if (controls.paused || ischat){
    if (ischat){
      event.stopPropagation();

      return;
    }

  }

  isHoldingKey = true;
  isInteracting = true;
  switch ( event.keyCode ) {
    case 38: // up
    case 87: // w
      moveForward = true;
      break;
    case 37: // left
    case 65: // a
      moveLeft = true;
      break;
    case 40: // down
    case 83: // s
      moveBackward = true;
      break;
    case 39: // right
    case 68: // d
      moveRight = true;
      break;
  }

  // if (event.key == "n" || event.key == "N"){
  //   isInteracting = false;
  //   isHoldingKey = false;
  //   moveForward = false;
  //   moveLeft = false;
  //   moveBackward = false;
  //   moveRight = false;
  //   do{
  //     var idx = 1+Math.floor(Math.random()*(avatars.length-1));
  //   }while(avatars[idx].on)
  //   newUser(idx);
  // }

  if (event.key == "m"){
    enabledsound = !enabledsound;
    updateStatusDiv()
  }
  if (event.key == "c"){
    enabledchat = !enabledchat;
    RTDBChat_setMute(thisUser.id,!enabledchat);
    updateStatusDiv()
  }

      };

function enableChatFunc(){
  enabledchat = !enabledchat;
  RTDBChat_setMute(thisUser.id,!enabledchat);
  updateStatusDiv()
}
function enableSoundFunc(){
  enabledsound = !enabledsound
  muteAudio()
  updateStatusDiv()
}

function toggleFullScreenMode(){
  // if(ISMOBILE){
  //   document.webkitCancelFullScreen();
  // }

  if (!document.fullscreenElement &&    // alternative standard method
    !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    document.getElementById('toggleFullScreen').innerHTML = "<i class='fas fa-compress fa-lg'></i>"
  } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      document.getElementById('toggleFullScreen').innerHTML = "<i class='fas fa-expand fa-lg'></i>"
    }
}

function showStartChatTutorial(showTutorial){

  if(showTutorial){
    if(!document.getElementById('initiateChatTut')){
        let div = document.createElement("div");
        div.id = "initiateChatTut"
        if(mobileControls){
          div.innerHTML = (ISFRENCH?"Appuyer pour commencer à clavarder":"Tap to initiate chat");
        }else{
          div.innerHTML = (ISFRENCH?"Cliquer pour commencer à clavarder":"Click to initiate chat");
        }
      div.style = "display:block; color: white; position: absolute; text-align:center; width: 100%; left: 0px; top: calc(50% - 20px); pointer-events:none; z-index:10000; text-shadow:0px 0px 5px rgba(0,0,0,1.0)"
      document.body.appendChild(div);
    }else if(document.getElementById('initiateChatTut').style.display == 'none'){
      document.getElementById('initiateChatTut').style.display = 'block'
    }
  }else{
    // only hide div if it already exists
    if(document.getElementById('initiateChatTut') ){
      document.getElementById('initiateChatTut' ).style.display = 'none'
    }
  }
}

function updateStatusDiv(){
  document.getElementById("status").innerHTML=`
  <span id=chatStatus>
        <span style='color:${enabledchat?"lime":"red"}'>
        •
        </span> ${ISFRENCH?"Clavardage":"Chat"}: ${enabledchat?(ISFRENCH?"Activé":"On"):(ISFRENCH?"Désactivé":"Off")}
        </span>
        <span id=soundStatus>
        <span style='color:${enabledsound?"lime":"red"}'>•</span> ${ISFRENCH?"Son":"Sound"}: ${enabledsound?(ISFRENCH?"Activé":"On"):(ISFRENCH?"Désactivé":"Off")}
        </span>`
  document.getElementById('chatStatus').addEventListener('click', enableChatFunc)
  document.getElementById('soundStatus').addEventListener('click', enableSoundFunc)
}

var notTyping = -1;

var onKeyUp = function ( event ) {
  if (ischat){
    if (notTyping != -1){
      clearTimeout(notTyping);
    }

    RTDBChat_setChatTyping(userid2chatid[pointed.info.id],thisUser.id,true);

    let eh = userid2chatid[pointed.info.id];
    notTyping = setTimeout(function(){
      RTDBChat_setChatTyping(eh,thisUser.id,false);
      notTyping = -1;
    },1000)

    if (event.key == "Enter"){
      var txt = document.getElementById("chatinp").value;
      document.getElementById("chatinp").value = "";
      if (txt.length <=1){
        return;
      }
      RTDBChat_setChatMessage(userid2chatid[pointed.info.id],uuidv4(),{
        name:thisUser.name,
        text:txt,
        time:new Date().getTime(),
      });
      event.stopPropagation();
    }
    return;
  }
  if (controls.paused){
    return;
  }
  isHoldingKey = false;
  switch ( event.keyCode ) {

    case 38: // up
    case 87: // w
      moveForward = false;
      break;

    case 37: // left
    case 65: // a
      moveLeft = false;
      break;

    case 40: // down
    case 83: // s
      moveBackward = false;
      break;

    case 39: // right
    case 68: // d
      moveRight = false;
      break;

  }

};

document.addEventListener( 'keydown', onKeyDown, false );
document.addEventListener( 'keyup', onKeyUp, false );

// checking if user has been active
document.addEventListener('mousemove',function(){
  if (isOOB && !isTurningOffOOB){
    OOBOff();
  }
  if (controls.paused){
    return;
  }
  isInteracting = true;
},false)
setInterval(function(){if (!isHoldingKey){isInteracting=false}},1000);

// lsit of chatids I've had in coordination with the user they are associated with
window.userid2chatid = {};

// only called when my user gets loaded in
function newUser(idx){
  turnOn(idx);
  if(!ISMOBILE){
  controls.lock();
  }

  controls.paused = true;
  document.getElementById('hint').innerHTML = "";
  setTimeout(function(){
    RTDBChat_setMute(thisUser.id,!enabledchat);

    document.getElementById('hint').innerHTML = ISMOBILE?"":(ISFRENCH?"Déplacez votre souris pour regarder dans différentes directions, utilisez les flèches ou les touches WASD de votre clavier pour vous déplacer. Touches d’activation/désactivation: C=Clavardage, M=Sourdine":"Move mouse to look, WASD/Arrow keys to walk. Toggles: C = chat, M = mute");
    controls.paused = false;

    window.clickFunction = function () {
      velocity.x = 0;
      velocity.y = 0;
      velocity.z = 0;
      moveForward = false;
      moveLeft = false;
      moveBackward = false;
      moveRight = false;
      isHoldingKey = false;

      //if user is currently in chat, exit out of chat
      if (ischat){
        setTimeout(function(){
          RTDBChat_setMute(thisUser.id,false);

          let chatid = userid2chatid[pointed.info.id]

          RTDBChat_stopChatListeners(userid2chatid[pointed.info.id], thisUser.id)

          // sending to chat board that i have left the chat
          // RTDBChat_setChatMessage(chatid,uuidv4(),{
          //   name:'bot',
          //   text: thisUser.name + " has left the chat",
          //   time: Date.now(),
          // });

          pointed = null;
          isgoto = false;
          ischat = false;
          controls.paused = false;

          if(ISMOBILE){
          showTouchPad()
          }

          document.getElementById("chat").style.display="none";
        // document.getElementById("chatinp").unfocus();
        },1)
      }else if (isgoto){
        pointed = null;
        isgoto = false;
        ischat = false;
        if(ISMOBILE){
        showTouchPad()
        }
        controls.paused = false;
      }else if (pointed != null && pointed.info && pointed.info.id !== thisUser.id){
        if (pointed.meta.isHusk){
          isgoto = true;
        }
        else if(enabledchat === false){
            // console.log('this user has chat turned off')
            // clicked on a user who has chat turned off
            if(hasChatted == false ){showStartChatTutorial(false)}
            isgoto = true;
            let div = document.createElement("div");
            div.innerHTML = (ISMOBILE?(ISFRENCH?"Votre chat est désactivé. Appuyez sur l’option chat dans le coin inférieur gauche pour l’activer.":"Your chat is turned off. Tap the chat option in the bottom left corner to enable it."):(ISFRENCH?"Votre chat est désactivé. Appuyez sur “c” pour réactiver le chat":"Your chat is turned off. Press 'c' to enable chat"));
            div.style = "color: white; position: absolute; text-align:center; width: 100%; left: 0px; top: calc(50% - 20px); pointer-events:none; z-index:10000; text-shadow:0px 0px 5px rgba(0,0,0,1.0)"
            document.body.appendChild(div);
            isDisplayingMessage = true
            setTimeout(function(){
              div.remove();
              isDisplayingMessage = false
            },2000)
            // alert("The other person cannot be bothered right now! Find someone else.");

        }
        else{
          // console.log(pointed)
          // clicked on another active user
          // get user's mute state
          RTDBChat_getMute(pointed.info.id,function(ismute){

            // is user is available to chat, start chat
            if (ismute === false){
              var chatID = userid2chatid[pointed.info.id];
              var isNewChat = chatID  === undefined ? true : false;

              // create chat ID if we haven't chatted before
              if (isNewChat){
                chatID = uuidv4(); // create new chatID
                // initiate chat
                console.log('initiate chat with someone')
                hasChatted = true

                // sends message to chat
                RTDBChat_setChat(chatID,{
                  users:{[thisUser.id]:true,[pointed.info.id]:true},
                  typing:{[thisUser.id]:false,[pointed.info.id]:false},
                  messages:{},
                })
                if(ISMOBILE){
                  hideTouchPad()
                }


                userid2chatid[pointed.info.id] = chatID;
              }
              // we've chatted before. Start chat and display old messages
              else
              {

                // RTDBChat_getChat(chatID,function(data2){
                //   chat.initChat(pointed.info.id, chatID)
                //   chat.renderChatHist(data2);
                // })
                chat.initChat(pointed.info, chatID)
                chat.renderChatHist(RTDBChat_getCachedChat(chatID))
              }

              // inviting someone else to chat
              console.log('inviting someone else to chat')
              let otherUser = getUserWithID(pointed.info.id)
              chat.initChat(otherUser, chatID)
              // set my mute to the id of the other person i am talking with
              RTDBChat_setMute(thisUser.id,pointed.info.id);
              // RTDBChat_setMute(thisUser.id,true);
              // set person's mute that I've initiated, to be my id
              RTDBChat_setMute(pointed.info.id,thisUser.id);
              // RTDBChat_setMute(pointed.info.id,true);
              RTDBChat_setInvite(pointed.info.id,{chat:chatID,user:thisUser.id});

              if(isNewChat){
                RTDBChat_setChatMessage(chatID,uuidv4(),{
                  name:"bot",
                  text:(ISFRENCH? "Salut! Bienvenue dans l'espace de clavardage":"Hey! Welcome to the chat"),
                  time: Date.now(),
                });

                // sends message to chat
                // RTDBChat_setChat(chatID,{
                //   users:{[thisUser.id]:true,[pointed.info.id]:true},
                //   typing:{[thisUser.id]:false,[pointed.info.id]:false},
                //   messages:{"_":{name:"bot",text:(ISFRENCH? "Salut! Bienvenue dans l'espace de clavardage":"Hey! Welcome to the chat"),time:Date.now()}},
                // })
              }

              if(ISMOBILE){
              hideTouchPad()
              }

              ischat = true;
              
              if (window.whoosh){
                window.whoosh();
              }
            }else if(ismute === true){
              // console.log('this user has chat turned off')
              // clicked on a user who has chat turned off
              if(hasChatted == false ){isDisplayingMessage = true; showStartChatTutorial(false)}
              isgoto = true;
              let div = document.createElement("div");
              div.innerHTML = pointed.info.name+(ISFRENCH?" ne peut pas clavarder présentement, son chat est fermé":" cannot chat right now, their chat is turned off");
              div.style = "color: white; position: absolute; text-align:center; width: 100%; left: 0px; top: calc(50% - 20px); pointer-events:none; z-index:10000; text-shadow:0px 0px 5px rgba(0,0,0,1.0)"
              document.body.appendChild(div);
              setTimeout(function(){
                div.remove();
                isDisplayingMessage = false
              },2000)
              // alert("The other person cannot be bothered right now! Find someone else.");
            }else{
              // this user is in a chat with somebody

              // this user is in a chat with me!!
              if(ismute == thisUser.id){

                // console.log('this user is still in our old conversation')
                var chatID = userid2chatid[pointed.info.id];
                // RTDBChat_getChat(chatID,function(data2){
                //   chat.renderChatHist(data2);
                // })
                chat.initChat(pointed.info, chatID)
                chat.renderChatHist(RTDBChat_getCachedChat(chatID))
                RTDBChat_setMute(thisUser.id,pointed.info.id);

                ischat = true;
                hasChatted = true;
                pointed = avatars.filter(x=>x.info.id==pointed.info.id)[0];
                document.getElementById("chat").style.display="block";
                document.getElementById("chatinp").focus();
                if(!ISMOBILE){
                controls.unlock();
                }
                if(ISMOBILE){
                  hideTouchPad()
                }
                controls.paused = false;
                addChatListeners(chatID);
                // RTDBChat_setChatMessage(chatID,uuidv4(),{
                //   name:"bot",
                //   text:thisUser.name+(ISFRENCH? " est entré dans le chat":" has entered the chat"),
                //   time: Date.now(),
                // });
              }else{

              isgoto = true;
              if(hasChatted == false ){
                showStartChatTutorial(false)
              }
              let div = document.createElement("div");
              div.innerHTML = pointed.info.name+(ISFRENCH?" est dans une autre conversation":" is in another conversation");
              div.style = "color: white; position: absolute; text-align:center; width: 100%; left: 0px; top: calc(50% - 20px); pointer-events:none; z-index:10000; text-shadow:0px 0px 5px rgba(0,0,0,1.0)"
              document.body.appendChild(div);
              isDisplayingMessage = true
              setTimeout(function(){
                div.remove();
                isDisplayingMessage = false
              },2000)
            }
            }
          })
        }


      }
    }
    //

    // renderer.domElement.addEventListener('click', clickFunction)
    if(mobileControls){

    renderer.domElement.addEventListener('touchstart', clickFunction)
    }
    else{
      document.body.addEventListener( 'click', clickFunction);
    }
  },PARAMS.timer.gaincontrol);
}

// someone else has invited me to chat
window.invitedToChat = function (otherUser, chatId){
  if (!ischat){
    // someone else wants to talk with me
    // RTDBChat_getInvite(thisUser.id,function(invite){
      if (otherUser && chatId){
        // console.log(JSON.stringify(invite));
        console.log('recieved invite from ' + otherUser)
        showStartChatTutorial(false)
        ischat = true;
        pointed = avatars.filter(x=>x.info.id==otherUser.id)[0];
        userid2chatid[otherUser.id]=chatId;
        document.getElementById("chat").style.display="block";
        document.getElementById("chatinp").focus();
        if(!ISMOBILE){
        controls.unlock();
        }
        if(ISMOBILE){
          hideTouchPad()
        }
        controls.paused = false;
        RTDBChat_setInvite(thisUser.id,null);
        chat.initChat(otherUser, chatId)
      }
  }
}

// sets up new user
setTimeout(function(){

  // checks that 'glitch' isn't in url, this checks if code is running on glitch server or in real one
  if (!window.location.href.includes("glitch")){
    newUser(0);
  }

  if(ISMOBILE){
  renderer.domElement.addEventListener('touchstart', () => {
    // console.log('touch on renderer called')
    hasntBeenInteracting = 0
  })
  }

  // triggered if user has exited interaction area, and are trying to get back in.
  renderer.domElement.addEventListener( 'click', function () {
    console.log("CLICK ON RENDERER")
    if (!avatars[0].on){
      newUser(0);
    }else{
      if(!ISMOBILE){
      controls.lock();
      }
      controls.paused = false;
      // window.location.href = window.location.href;
    }
    hasntBeenInteracting = 0
  }, false );

},500); // new 5000

function heartbeat(t,m){
  var x = t;
  var g = 0.3*(Math.sin(x*6.25-0.8)+1+2*Math.pow(Math.sin(x*6.25+2.7),4)-0.3);
  return g*m;
}


function turnOn(i){
  if (i==0){

    for (var j = 0; j < avatars.length; j++){
      avatars[j].bright = 0;
      avatars[j].supressed = true;
    }
  }
  avatars[i].bright = 1;
  avatars[i].supressed = false;

  var x = avatars[i].mesh.position.x+0;
  var z = avatars[i].mesh.position.z+0;
  setTimeout(function(){
    var path = PARAMS.camera.path.map(x=>[x[0]+avatars[i].mesh.position.x,x[1],x[2]+avatars[i].mesh.position.z]);
    if (i != 0){
      path.unshift([camera.position.x,camera.position.y,camera.position.z]);
    }
    cameraAnimation(path, avatars[i].mesh.position.x, avatars[i].mesh.position.z);
    console.log('camera movement started')
  },PARAMS.timer.camera)

  var wait = i == 0 ? 0 : PARAMS.timer.rezoomactivationdelay;
  for (var j = 0; j < (PARAMS.timer.lightup-PARAMS.timer.jitter)/10; j++){
    ;;;(function(){
      var _j = j;
      setTimeout(function(){
        avatars[i].mesh.position.x = x+_j*PARAMS.anim.jitter*(Math.random()-0.5);
        avatars[i].mesh.position.z = z+_j*PARAMS.anim.jitter*(Math.random()-0.5);
        avatars[i].bulge = 1+Math.random();
      },wait+PARAMS.timer.jitter+j*10);
    })()
  }
  setTimeout(function(){
    avatars[i].mesh.position.x = x;
    avatars[i].mesh.position.z = z;
    avatars[i].on = true;
    avatars[i].bulge = PARAMS.anim.bulge;
  },wait+PARAMS.timer.lightup)
  setTimeout(function(){
    for (var j = 0; j < avatars.length; j++){
      ;;;(function(){
        let _j = j;
        setTimeout(function(){
          // console.log(_j,"was lit");
          avatars[_j].supressed = false;
        },3000);
        // },j*2000)
      })()
    }
  },wait+PARAMS.timer.lightupothers)
}

function OOBOff(){
  // alert("about to turn off oob!")
  if(isTurningOffOOB == true) {
    return;
  }
  isTurningOffOOB = true;

  var offOobAnimationTime = 1200
  for (var i = 0; i < offOobAnimationTime/10; i++){
    setTimeout(function(){
      let tt = PARAMS.oob.backspeed;
      if(mobileControls){
        camera.position.x = camera.position.x*(1-tt) + avatars[0].mesh.position.x*tt;
        camera.position.y = camera.position.y*(1-tt) +       PARAMS.camera.lookat*tt;
        camera.position.z = camera.position.z*(1-tt) + avatars[0].mesh.position.z*tt;
      }else{
      controls.getObject().position.x = controls.getObject().position.x*(1-tt) + avatars[0].mesh.position.x*tt;
      controls.getObject().position.y = controls.getObject().position.y*(1-tt) +       PARAMS.camera.lookat*tt;
      controls.getObject().position.z = controls.getObject().position.z*(1-tt) + avatars[0].mesh.position.z*tt;
      }
      var oldquat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )
      var newquat = cachedQuat;
      oldquat.slerp(newquat,tt);
      camera.quaternion.x = oldquat.x;
      camera.quaternion.y = oldquat.y;
      camera.quaternion.z = oldquat.z;
      camera.quaternion.w = oldquat.w;
      
    },i*10);
    setTimeout(function(){
      camera.quaternion.x = cachedQuat.x;
      camera.quaternion.y = cachedQuat.y;
      camera.quaternion.z = cachedQuat.z;
      camera.quaternion.w = cachedQuat.w;
      // camera.rotation.x = 0;
      // camera.rotation.z = 0;
    },offOobAnimationTime);
  }
  setTimeout(function(){
    isOOB = false;
    isTurningOffOOB = false;
    controls.paused = false;
    isInteracting = true;
    hasntBeenInteracting = 0;
    // alert("oob turned off!")
  },offOobAnimationTime)
}




var lastTime = new Date();

var mobileCameraCorrection = false;

function render(){

  var thisTime = new Date();
  var fps = 1000 / (thisTime - lastTime);
  lastTime = thisTime;
  if (isNaN(fps)){
    fps = 60;
  }

  renderer.autoClear = false;
  renderer.clear();

  // var g = 0.45*(Math.sin(t/10)+1)+0.1
  document.getElementById("vig2").style.opacity=0.0;

  // updating position of all avatars
  for (var i = 0; i < avatars.length; i++){
    if (i != 0){
      avatars[i].on = !avatars[i].meta.isHusk;
      avatars[i].mesh.position.x = avatars[i].info.pos.x;
      avatars[i].mesh.position.z = avatars[i].info.pos.z;
    }else{
      updateMyPos(avatars[i].mesh.position.x, avatars[i].mesh.position.z)
    }
    var d2c = new THREE.Vector3(camera.position.x, avatars[i].mesh.position.y, camera.position.z).distanceTo( avatars[i].mesh.position );
    var amul = 1.0
    if (d2c<6){
      amul = 0;
    }else if (d2c<26){
      amul = (d2c-6)/20;
    }

    // var g = 0.45*(Math.sin(t/10+avatars[i].phase)+1)+0.1;
    var g;
    if (avatars[i].on){
      var bps = avatars[i].bpm/60;
      var bt = avatars[i].beattime;
      var fpb = fps/bps;
      g = heartbeat(bt,avatars[i].amp)*0.9+0.1;
      avatars[i].beattime += 1/fpb;
      // console.log(fpb,bps,bt,fpb,avatars[i].beattime);
      avatars[i].beattime = Math.min(avatars[i].beattime, 1)
    }else{
      g = PARAMS.darkest;
    }
    g*=avatars[i].bright;
    g = Math.max(PARAMS.darkest,g);

    if (i == 0 && !controls.paused){
      // tegan stop blinking
      document.getElementById("vig").style.opacity=g*1.0;
    }
    if (i != 0 && d2c <= 10 && !controls.paused){
      // tegan stop blinking
      document.getElementById("vig2").style.opacity=g*1.0;
      // console.log(document.getElementById("vig2").style.opacity)
    }
    var uuu = new THREE.Vector3(
      (avatars[i].tint[0]*PARAMS.tint.r+(1-PARAMS.tint.r))*PARAMS.tint.r2,
      (avatars[i].tint[1]*PARAMS.tint.g+(1-PARAMS.tint.g))*PARAMS.tint.g2,
      (avatars[i].tint[2]*PARAMS.tint.b+(1-PARAMS.tint.b))*PARAMS.tint.b2);
    avatars[i].mat.uniforms.color.value = new THREE.Vector3(uuu.x*g,uuu.y*g,uuu.z*g);
    avatars[i].mat.uniforms.noiseparam1.value = PARAMS.noiseparam1;
    avatars[i].mat.uniforms.noiseparam2.value = PARAMS.noiseparam2*avatars[i].bulge * (1+g*PARAMS.growthtobeat);
    avatars[i].mat.uniforms.noiseparam3.value = PARAMS.noiseparam3;
    avatars[i].mat.uniforms.noiseseed.value.x = avatars[i].mat.uniforms.noiseseed.value.x + PARAMS.morphspeed.x * ( avatars[i].morphspeed * PARAMS.morphspeed.variance + (1-PARAMS.morphspeed.variance) );
    avatars[i].mat.uniforms.noiseseed.value.y = avatars[i].mat.uniforms.noiseseed.value.y + PARAMS.morphspeed.y * ( avatars[i].morphspeed * PARAMS.morphspeed.variance + (1-PARAMS.morphspeed.variance) );
    avatars[i].mat.uniforms.noiseseed.value.z = avatars[i].mat.uniforms.noiseseed.value.z + PARAMS.morphspeed.z * ( avatars[i].morphspeed * PARAMS.morphspeed.variance + (1-PARAMS.morphspeed.variance) );



    avatars[i].mat.uniforms.alpha.value = (avatars[i].on?1.0:PARAMS.alpha)*amul;

//     avatars[i].shadow.position.x = avatars[i].mesh.position.x;
//     avatars[i].shadow.position.z = avatars[i].mesh.position.z;

//     avatars[i].shadow.material.uniforms.color.value.x = g*0.7*amul;
//     avatars[i].shadow.material.uniforms.color.value.y = g*0.7*amul;
//     avatars[i].shadow.material.uniforms.color.value.z = g*0.7*amul;


//     avatars[i].mat.needsUpdate = true;
//     avatars[i].shadow.material.needsUpdate = true;

    {
      let name = "u"+i.toString().padStart(2,'0');
      // console.log('quads name: ' + name )
      // console.log(quad.material.uniforms)
      if(quad.material.uniforms[name] != undefined){
      quad.material.uniforms[name].value.x =  (avatars[i].mesh.position.x+250)/500;
      quad.material.uniforms[name].value.y = (-avatars[i].mesh.position.z+250)/500;
      quad.material.uniforms[name].value.z = (avatars[i].meta.isHusk&&avatars[i].mesh.position.distanceTo(new THREE.Vector3(0,0,0))<10)?0:Math.max(0,PARAMS.shadow.const2*g+PARAMS.shadow.const1)*(avatars[i].meta.isHusk?0.8:1.0);

      let namf = "c"+i.toString().padStart(2,'0');
      quad.material.uniforms[namf].value.x = uuu.x
      quad.material.uniforms[namf].value.y = uuu.y
      quad.material.uniforms[namf].value.z = uuu.z
      }
    }


    if (avatars[i].bulge > 1){
      avatars[i].bulge*=0.9
    }
    if (avatars[i].bright < 1 && !avatars[i].supressed){
      avatars[i].bright += Math.max(0.0001, avatars[i].bright*0.01);
    }

    // if (window.gltfScene){
    //   window.gltfScene.traverse( function ( child ) {
    //     child.material.uniforms.darkest.value = PARAMS.env.darkest;
    //     child.material.uniforms.brightest.value = PARAMS.env.brightest;
    //     child.material.uniforms.light.value.x = PARAMS.env.light.x;
    //     child.material.uniforms.light.value.y = PARAMS.env.light.y;
    //     child.material.uniforms.light.value.z = PARAMS.env.light.z;
    //     child.material.needsUpdate = true;
    //   } );
    // }
    // if (window.light){
    //   light.color.r = PARAMS.env.ambient.r;
    //   light.color.g = PARAMS.env.ambient.g;
    //   light.color.b = PARAMS.env.ambient.b;
    // }

    if (window.gltfScene){
      window.gltfScene.traverse( function ( child ) {

        if (child.material && child.material.map){

          child.material.color.r = PARAMS.env.ambient.r;
          child.material.color.g = PARAMS.env.ambient.g;
          child.material.color.b = PARAMS.env.ambient.b;
          child.material.needsUpdate = true;
        }
        child.traverse(function(grand){
          if (grand.material && grand.material.map){
            grand.material.color.r = PARAMS.env.ambient.r;
            grand.material.color.g = PARAMS.env.ambient.g;
            grand.material.color.b = PARAMS.env.ambient.b;
            grand.material.needsUpdate = true;
          }
          grand.traverse(function(great){
            if (great.material && great.material.map){
              great.material.color.r = PARAMS.env.ambient.r;
              great.material.color.g = PARAMS.env.ambient.g;
              great.material.color.b = PARAMS.env.ambient.b;
            }
          })

        })
      } );
    }

    var md = PARAMS.anim.startradius;
    var mj = -1;
    var ok = false;
    for (var j = 0; j < avatars.length; j++){
      if (!avatars[j].on || avatars[j].bright < 0.8 || i == j){
        continue;
      }
      var p = avatars[i].mesh.position;
      var q = avatars[j].mesh.position;
      var d = Math.sqrt(Math.pow(p.x-q.x,2)+Math.pow(p.z-q.z,2));
      if (d < md){
        md = d;
        mj = j;
        if (d < PARAMS.anim.stopradius+avatars[i].lazy*10){
          ok  = true;
        }
      }
    }
    if (mj != -1 && !ok){
      var p = avatars[ i].mesh.position;
      var q = avatars[mj].mesh.position;
      var d = new THREE.Vector3(q.x-p.x, 0, q.z-p.z);
      d = d.normalize();
      if (avatars[i].on && avatars[i].bright > 0.8){
        // avatars[i].mesh.position.x += d.x*Math.max(0,PARAMS.anim.crawlspeed-md*0.002);
        // avatars[i].mesh.position.z += d.z*Math.max(0,PARAMS.anim.crawlspeed-md*0.002);
      }else{
        var oldquat = new THREE.Quaternion(
          avatars[i].mesh.quaternion.x,
          avatars[i].mesh.quaternion.y,
          avatars[i].mesh.quaternion.z,
          avatars[i].mesh.quaternion.w,
        )
        avatars[i].mesh.lookAt(avatars[mj].mesh.position)
        var newquat = new THREE.Quaternion(
          avatars[i].mesh.quaternion.x,
          avatars[i].mesh.quaternion.y,
          avatars[i].mesh.quaternion.z,
          avatars[i].mesh.quaternion.w,
        )
        // console.log(Math.max(0.001,0.2-md*0.01));
        oldquat.slerp(newquat, 0.3*Math.max(0.001,PARAMS.anim.turnspeed-md*0.005));
        avatars[i].mesh.quaternion.x = oldquat.x;
        avatars[i].mesh.quaternion.y = oldquat.y;
        avatars[i].mesh.quaternion.z = oldquat.z;
        avatars[i].mesh.quaternion.w = oldquat.w;
      }
    }
  }

  var delta = PARAMS.anim.fpsdelta;
  velocity.x -= velocity.x * 10.0 * delta;
  velocity.z -= velocity.z * 10.0 * delta;

  direction.z = Number( moveForward ) - Number( moveBackward );
  direction.x = Number( moveRight ) - Number( moveLeft );
  direction.normalize(); // this ensures consistent movements in all directions

  if ( moveForward || moveBackward ) velocity.z -= direction.z * 400.0 * delta;
  if ( moveLeft || moveRight ) velocity.x -= direction.x * 400.0 * delta;


  if ((!window.hasFocus || (!isInteracting && !ischat)) && showLabels){
    hasntBeenInteracting ++;
  }else{
    hasntBeenInteracting = 0;
  }



  // set oob to true if inactive for longer than desired
  if(mobileControls && hasntBeenInteracting > PARAMS.oob.mobiletimer && !controls.paused){
    controls.paused = true;
    isOOB = true;
    mobileOOBLastQuaternion = controls.getDeviceOrientation()
    console.log('MBILE setting oob to true')
  }
  else if (hasntBeenInteracting > PARAMS.oob.timer && !controls.paused){
    controls.paused = true;
    isOOB = true;
    console.log('setting oob to true')
  }
  // console.log('hasntbeeninteracting: ' + hasntBeenInteracting)
  if (hasntBeenInteracting > 30/*FPS*/*60/*sec*/*5/*min*/){
    // console.log("kicking you out!")
    RTDBBootUser(thisUser.id)
  }
  if (isOOB){
    // console.log("OOB!");
  }

  if ((!controls.paused && !isInteracting && !ischat && !isgoto) || isOOB){

    if (isOOB){

      var tt = PARAMS.camera.drift.easing;
      driftV.x = driftV.x * tt + PARAMS.camera.drift.x * (1-tt) ;
      driftV.y = driftV.y * tt + PARAMS.camera.drift.y * (1-tt) ;
      driftV.z = driftV.z * tt + PARAMS.camera.drift.z * (1-tt) ;


      driftV.y += 0.1;

      var oldquat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )
      camera.lookAt(new THREE.Vector3(avatars[0].mesh.position.x,0,avatars[0].mesh.position.z))
      var newquat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )


      oldquat.slerp(newquat,0.001);
      camera.quaternion.x = oldquat.x;
      camera.quaternion.y = oldquat.y;
      camera.quaternion.z = oldquat.z;
      camera.quaternion.w = oldquat.w;
      if (Math.random() < PARAMS.camera.drift.probchange){
        PARAMS.camera.drift.x*=-1;
      }
      if (Math.random() < PARAMS.camera.drift.probchange){
        PARAMS.camera.drift.y*=-1;
      }
      if (Math.random() < PARAMS.camera.drift.probchange){
        PARAMS.camera.drift.z*=-1;
      }
      if(mobileControls){
        // check that camera has not moved
        // mobileOOBLastQuaternion
        var latestDeviceOrientation = controls.getDeviceOrientation()
        var hasChangedDeviceOrientation = (Math.abs(latestDeviceOrientation.dot( latestDeviceOrientation) - mobileOOBLastQuaternion.dot( latestDeviceOrientation)) > 0.1) ? true : false

        if(hasChangedDeviceOrientation === true){
          if(isOOB){
            OOBOff()
          }
          hasntBeenInteracting = 0
        }
        camera.position.x += ( - driftV.x *delta );
        camera.position.z +=  ( - driftV.z * delta);
        camera.position.y += ( driftV.y * delta );
      }else{
        controls.moveRight( - driftV.x *delta );
        controls.moveForward( - driftV.z * delta);
        controls.getObject().position.y += ( driftV.y * delta ); // new behavior
      }

    }else{
      // regular camera controls
      if(mobileControls){
        //update returns true if there has been movement in orientation and false if there has been none
        var hasChangedDeviceOrientation = controls.update()
        if(hasChangedDeviceOrientation === true){
          if(isOOB){
            OOBOff()
          }
          hasntBeenInteracting = 0
        }else {
          hasntBeenInteracting ++
        }
      }

      var oldquat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )
      if (!mobileControls){
        camera.lookAt(driftLookat)
      }
      var newquat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )
      oldquat.slerp(newquat,0.0001);
      camera.quaternion.x = oldquat.x;
      camera.quaternion.y = oldquat.y;
      camera.quaternion.z = oldquat.z;
      camera.quaternion.w = oldquat.w;
      if (Math.random() < PARAMS.camera.drift.probchange){
        // console.log("drift dir change")
        driftLookat = new THREE.Vector3(avatars[0].mesh.position.x+Math.random()*100-50, PARAMS.camera.lookat, avatars[0].mesh.position.z+Math.random()*100-50);
      }
      cachedQuat = new THREE.Quaternion(
        camera.quaternion.x,
        camera.quaternion.y,
        camera.quaternion.z,
        camera.quaternion.w,
      )
    }



  }else{
    var tt = PARAMS.camera.drift.easing;
    driftV.x *= tt;
    driftV.y *= tt;
    driftV.z *= tt;
  }

  // apply movement // rotational still works without this
  if (!ischat && !mobileControls){
    controls.moveRight( - velocity.x *delta );
    controls.moveForward( - velocity.z * delta);

    controls.getObject().position.y += ( velocity.y *delta ); // new behavior
  }


  if(!mobileControls){
  if ((!controls.paused) || isOOB){
    controls.getObject().position.x = Math.min(Math.max(controls.getObject().position.x,-PARAMS.walls.x),PARAMS.walls.x);
    controls.getObject().position.z = Math.min(Math.max(controls.getObject().position.z,-PARAMS.walls.z),PARAMS.walls.z)
  }

  if (!controls.paused){
    avatars[0].mesh.position.x = controls.getObject().position.x;
    // avatars[0].mesh.position.y = controls.getObject().position.y;
    avatars[0].mesh.position.z = controls.getObject().position.z;
  }
}


  // objBack.material.color.r=0.5*(Math.sin(t/10)+1.1);
  // objBack.material.color.g=0.5*(Math.sin(t/10)+1.1);
  // objBack.material.color.b=0.5*(Math.sin(t/10)+1.1);

  bloomPass.strength = PARAMS.bloomstrength;
  bloomPass.radius = PARAMS.bloomradius;

  camera.layers.set(0);

  if (!ischat && !isOOB){
    if(mobileControls){
      // recaster XY set from -1, 1. crosshair on mobile is 40% down from top of display
      raycaster.setFromCamera( new THREE.Vector2(0,0.2), camera );
    }else{
    raycaster.setFromCamera( new THREE.Vector2(0,0), camera );
    }
    var intersects = raycaster.intersectObjects( avatars.map(x=>x.mesh) );
    // console.log(intersects);

    if ( intersects.length > 0 && intersects[0].distance < Infinity) {
      if (!isgoto){
        for(var i = 0; i < intersects.length; i++){

          // only use the detected player as pointed if they are a greater than 18 units away from me
          // otherwise we are inside each other but it appears as if we are already looking beyond this player
          if(intersects[i].distance > 18){
            pointed = intersects[i].object.u_parent;
            break;
          }
        }
        
        if (!pointed || !pointed.info){
          pointed = null;
        }
      }
    } else {
      if (!isgoto){
        pointed = null;
      }
    }
  }
  if(pointed == null || hasChatted == true || ischat || isOOB){
    showStartChatTutorial(false)  
  }
  if (showLabels){
    if (pointed && pointed.info && pointed.info.id != thisUser.id){
      var s = pointed.scale;
      var v = new THREE.Vector3(pointed.mesh.position.x,pointed.mesh.position.y + s*3.1,pointed.mesh.position.z);
      // console.log(v)
      v.project( camera );
      v.x = Math.round( (   v.x + 1 ) * renderer.domElement.width  / 2 );
      v.y = Math.round( ( - v.y + 1 ) * renderer.domElement.height / 2 );
      v.z = 0;

      var info = pointed.info;
      // console.log(info)
      // var nm = Math.min(pointed.messages.length,4);
      lbldiv.style.left =(v.x-(ISMOBILE?250:500))+"px";
      lbldiv.style.top = (v.y-80)+"px";
  //<br><small>(${info.id})</small>
      if (!pointed.meta.isHusk){
        lbldiv.innerHTML = `
          <b>${(info.name&&info.name.length)?info.name:(ISFRENCH?"Quelqu'un":"Somebody")}</b>
          <br><small>${(info.loc&&info.loc.length)?info.loc:""}</small><br>
          <small><i>${(info.comment&&info.comment.length)?('"'+info.comment+'"'):""}</i></small><br>
          `
        if(hasChatted == false && isDisplayingMessage == false && !ischat && !isOOB){
          showStartChatTutorial(true)
        }
        // console.log(`${info.messages.slice(Math.max(0,info.messages.length-4)).map(x=>'<small style="color:silver">'+x.name+': <i style="color:white">"'+x.text+'"</i></small>').join("<br>")}`);

      }else{
        lbldiv.innerHTML = `
        <span style="opacity:0.4">
          <b>${(info.name&&info.name.length)?info.name:(ISFRENCH?"Quelqu'un":"Somebody")}</b>
          <br><small>${(info.loc&&info.loc.length)?info.loc:""}</small>
          <br><small><i>${(info.comment&&info.comment.length)?('"'+info.comment+'"</i></small><br>'):"</i></small>"}
          <small>${pointed.timestamp.split("(")[0]}</small>
        </span>`
      }
    }else{
      lbldiv.style.left ="0px";
      lbldiv.style.top = "0px";
      lbldiv.innerHTML = "";
    }
  }

  if (isgoto || ischat){

    var x0
    var y0
    if(mobileControls){
      x0 = camera.position.x
      y0 = camera.position.z
    }
    else{
      x0 = controls.getObject().position.x;
      y0 = controls.getObject().position.z;
    }
    var x1 = pointed.mesh.position.x;
    var y1 = pointed.mesh.position.z;
    if (Math.sqrt(Math.pow(x1-x0,2)+Math.pow(y1-y0,2)) > 20){
      if(mobileControls){
        camera.position.x = x0*0.998 + x1*0.002;
        camera.position.z = y0*0.998 + y1*0.002;
        Player.x = -camera.position.x
        Player.z = -camera.position.z
      }else {
        controls.getObject().position.x = x0*0.998 + x1*0.002;
        controls.getObject().position.z = y0*0.998 + y1*0.002;
      }
      if(mobileControls){
        avatars[0].mesh.position.x =camera.position.x;
        avatars[0].mesh.position.z = camera.position.z;

      }
      else{
        avatars[0].mesh.position.x =controls.getObject().position.x;
      avatars[0].mesh.position.z =controls.getObject().position.z;
      }

      controls.paused = true;
    }else if (!ischat){
      pointed = null;
      isgoto = false;
      ischat = false;
      controls.paused = false;
    }
  }

  if (pointed && ischat && document.getElementById("chat").style.display != "block"){
    document.getElementById("chat").style.display="block";
    document.getElementById("chatinp").value = "";
    document.getElementById("chatinp").focus();
    document.getElementById("chatinp").style.outline = "none";

    if(!ISMOBILE){
    controls.unlock();
    }
    controls.paused = false;
  }



  if (ischat){

    // render entire chat
    // RTDBChat_getChat(userid2chatid[pointed.info.id],function(x){
    //   if (x){
    //     renderChatHist(x);
    //   }
    // })
    var oldquat = new THREE.Quaternion(
      camera.quaternion.x,
      camera.quaternion.y,
      camera.quaternion.z,
      camera.quaternion.w,
    )
    camera.lookAt(new THREE.Vector3(pointed.mesh.position.x,PARAMS.camera.lookat,pointed.mesh.position.z))
    var newquat = new THREE.Quaternion(
      camera.quaternion.x,
      camera.quaternion.y,
      camera.quaternion.z,
      camera.quaternion.w,
    )
    // console.log(Math.max(0.001,0.2-md*0.01));
    oldquat.slerp(newquat,0.01);
    camera.quaternion.x = oldquat.x;
    camera.quaternion.y = oldquat.y;
    camera.quaternion.z = oldquat.z;
    camera.quaternion.w = oldquat.w;

    driftV.x= 0;
    driftV.y= 0;
    driftV.z= 0;
    velocity.x = 0;
    velocity.y = 0;
    velocity.z = 0;
    moveForward = false;
    moveLeft = false;
    moveBackward = false;
    moveRight = false;
    isHoldingKey = false;

    RTDBChat_getMute(thisUser.id,function(ismute){
      if (!ismute){
        pointed = null;
        isgoto = false;
        ischat = false;
        controls.paused = false;

        document.getElementById("chat").style.display="none";
      }
    })

  }

  // player only defined for mobile controls
  // player passed into touch controls
  if (Player && !isOOB && !isgoto){
    if (!mobileCameraCorrection){
      Player.rot = Math.PI/2;
      mobileCameraCorrection = true;
    }
    avatars[0].mesh.position.x = -Player.x;
    avatars[0].mesh.position.z = -Player.z;
    // console.log("my position in render 1991",Player.x, Player.z)
    // avatars[0].mesh.rotation.set(0,-Player.rot,0);

    camera.position.x = -Player.x;
    camera.position.z = -Player.z;

    // on mobile devices do not set camera rotation as that will remove the camera rotation based on device's accelerometer
    // set the player, as this is what the touch control object is using
    Player.rot =  -1*(camera.rotation.y - Math.PI/2)
    Player.rot2 = -camera.rotation.x

    avatars[0].mesh.position.x = Math.min(Math.max(avatars[0].mesh.position.x,-PARAMS.walls.x),PARAMS.walls.x);
    avatars[0].mesh.position.z = Math.min(Math.max(avatars[0].mesh.position.z,-PARAMS.walls.z),PARAMS.walls.z)
    camera.position.x = Math.min(Math.max(camera.position.x,-PARAMS.walls.x),PARAMS.walls.x);
    camera.position.z = Math.min(Math.max(camera.position.z,-PARAMS.walls.z),PARAMS.walls.z)
  }
  // document.getElementById("crosshair").innerHTML = isgoto?"click to exit chat":"+"


  // renderer.clearDepth();
  // camera.layers.set(1);

  camera.layers.set(1);
  renderer2.render(scene, camera);

  camera.layers.set(0);
  composer.render();
  // renderer.clearDepth();

  // renderer.clearDepth();

  t++;
requestAnimationFrame(render);}

window.allUserMetas = dbUsersMeta;
window.allUsers = dbUsers;
window.avatars = avatars;



if (window.debugMode){

  var folder;
  const datGui  = new dat.GUI({ autoPlace: true });

  datGui.domElement.id = 'gui'

  folder = datGui.addFolder(`Shape`)

  folder.add(PARAMS,'noiseparam1',0.01,10.0).name('noise detail');
  folder.add(PARAMS,'noiseparam2',0.0,10.0).name('noise amplitude');
  folder.add(PARAMS,'growthtobeat',0.0,10.0).name('growth to beat');
  folder.add(PARAMS,'alpha',0.0,1.0).name('alpha');

  folder = datGui.addFolder(`Morph`)

  folder.add(PARAMS.morphspeed,'x',0.0,1.0).name('speed X');
  folder.add(PARAMS.morphspeed,'y',0.0,1.0).name('speed Y');
  folder.add(PARAMS.morphspeed,'z',0.0,1.0).name('speed Z');
  folder.add(PARAMS.morphspeed,'variance',0.0,1.0).name('variance');

  // folder.open();

  folder = datGui.addFolder(`Tint`)
  folder.add(PARAMS.tint,'r',0.0,1.0).name('red');
  folder.add(PARAMS.tint,'g',0.0,1.0).name('green');
  folder.add(PARAMS.tint,'b',0.0,1.0).name('blue');
  folder.add(PARAMS.tint,'r2',0.0,1.0).name('red2');
  folder.add(PARAMS.tint,'g2',0.0,1.0).name('green2');
  folder.add(PARAMS.tint,'b2',0.0,1.0).name('blue2');
  // folder.open();

  folder = datGui.addFolder(`Glow`)
  folder.add(PARAMS,'bloomstrength',0.0,5.0).name('bloom strength');
  folder.add(PARAMS,'bloomradius',0.0,1.5).name('bloom radius');
  // folder.open();

  folder = datGui.addFolder(`Environment`)
  folder.add(PARAMS.env,'darkest',0.0,1.0).name('darkest');
  folder.add(PARAMS.env,'brightest',0.0,1.0).name('brightest');
  folder.add(PARAMS.env.light,'x',-1.0,1.0).name('light x');
  folder.add(PARAMS.env.light,'y',-1.0,1.0).name('light y');
  folder.add(PARAMS.env.light,'z',-1.0,1.0).name('light z');

  folder.add(PARAMS.env.ambient,'r',0.0,1.0).name('ambient r');
  folder.add(PARAMS.env.ambient,'g',0.0,1.0).name('ambient g');
  folder.add(PARAMS.env.ambient,'b',0.0,1.0).name('ambient b');


  folder = datGui.addFolder(`Camera`)
  folder.add(PARAMS.camera.path[0],2,0.0,500).name('x0');
  folder.add(PARAMS.camera.path[0],1,0.0,500).name('y0');
  folder.add(PARAMS.camera.path[1],2,0.0,500).name('x1');
  folder.add(PARAMS.camera.path[1],1,0.0,500).name('y1');
  folder.add(PARAMS.camera.path[2],2,0.0,500).name('x2');
  folder.add(PARAMS.camera.path[2],1,0.0,500).name('y2');
  folder.add(PARAMS.camera.path[3],2,0.0,500).name('x3');
  folder.add(PARAMS.camera.path[3],1,0.0,500).name('y3');

  folder.add(PARAMS.camera,"lookat",0,50).name('look at height');
  folder.add(PARAMS.camera,"steps",1,2000).name('animation steps');
  folder.add(PARAMS.camera,"easing",0.0,1.0).name('easing');
  // folder.open();

  folder = datGui.addFolder(`Drift`)
  folder.add(PARAMS.camera.drift,'x',-20.0,20.0).name('speed x');
  folder.add(PARAMS.camera.drift,'y',-20.0,20.0).name('speed y');
  folder.add(PARAMS.camera.drift,'z',-20.0,20.0).name('speed z');
  folder.add(PARAMS.camera.drift,'probchange',0.0,0.1).name('prob. change');
  // folder.add(PARAMS.camera.drift,'noise',0.0,1.0).name('noise');
  folder.add(PARAMS.camera.drift,'easing',0.0,1.0).name('easing');

  folder = datGui.addFolder(`Timer`)
  folder.add(PARAMS.timer,"camera", 0,10000).name('start camera');
  folder.add(PARAMS.timer,"jitter", 0,10000).name('start jitter');
  folder.add(PARAMS.timer,"lightup",1,10000).name('light up central');
  folder.add(PARAMS.timer,"lightupothers",1,10000).name('light up others');
  folder.add(PARAMS.timer,"gaincontrol",1,10000).name('gain control');
  folder.add(PARAMS.timer,"rezoomactivationdelay",1,10000).name('re-zoom actv dlay');

  folder = datGui.addFolder(`Animation`)
  folder.add(PARAMS.anim,"bulge", 0,100).name('bulge strength');
  folder.add(PARAMS.anim,"jitter",0,1).name('jitter radius');
  folder.add(PARAMS.anim,"startradius", 0,100).name('nghbr start rad');
  folder.add(PARAMS.anim,"stopradius", 0,100).name('nghbr stop rad');
  folder.add(PARAMS.anim,"crawlspeed",0,1).name('crawl speed');
  folder.add(PARAMS.anim,"turnspeed",0,1).name('turn speed');

  folder.add(PARAMS.anim,"fpsdelta",0,0.1).name('player speed');

  folder = datGui.addFolder(`OoB`)
  folder.add(PARAMS.oob,"timer", 0,10000).name('timer');
  folder.add(PARAMS.oob,"mobiletimer", 0,200).name('mobiletimer');
  folder.add(PARAMS.oob,"backspeed", 0.00001,1.0).name('return speed');

  folder = datGui.addFolder(`Shadow`)
  folder.add(PARAMS.shadow,"const1",-10,10).name('const 1');
  folder.add(PARAMS.shadow,"const2", 0,100).name("const 2")

  datGui.add({ add:function(){ newAvatar() }},'add').name("Add New")
  datGui.add({ nuke:function(){
    for (var i = 0; i < avatars.length; i++){
      scene.remove(avatars[i].mesh);
    }
    avatars = [];
  }},'nuke').name("Nuke")

  datGui.close();
}

;;;(function(){var script=document.createElement('script');script.onload=function(){var stats=new Stats();if(!window.debugMode)stats.dom.style.display="none";document.body.appendChild(stats.dom);requestAnimationFrame(function loop(){stats.update();requestAnimationFrame(loop)});};script.src='//mrdoob.github.io/stats.js/build/stats.min.js';document.head.appendChild(script);})()

  if (window.dematerialize){
    window.dematerialize();
  }


window.addEventListener( 'resize', function(){
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth,window.innerHeight);
  renderer2.setSize(window.innerWidth,window.innerHeight);
  composer.setSize( window.innerWidth, window.innerHeight );
  bloomPass.setSize(window.innerWidth, window.innerHeight )
  console.log('resize scene')
  if(ISMOBILE){
    // move touch controls
    moveTouchPad()
  }
});

}}





