var Tone = require('tone')
var beat_volume = new Tone.Volume(6).toMaster()
var single_beat = new Tone.Player({
  url: './src/assets/audio/heartbeat2.mp3',
  fadeOut: 0.1,
  fadeIn: 0.2
}).connect(beat_volume)


var timeSinceLastLoop = 0
var maxLoopTime = 0
var isLoopingAudio = false
var loopCounter = 0

export function singleLoopStart(bpm){
  timeSinceLastLoop = Date.now()
  maxLoopTime = (60/bpm) * 1000
  isLoopingAudio = true
  loopAudio()
  loopCounter = 0
}

function loopAudio(){
  if(isLoopingAudio){

    if(Date.now() - timeSinceLastLoop > maxLoopTime - 30){
      timeSinceLastLoop = Date.now()
      // console.log('looping ' + loopCounter)
      single_beat.start()
      loopCounter++
    }

    requestAnimationFrame(loopAudio)
  }
}

export function singleLoopStop(){
  isLoopingAudio = false
}
