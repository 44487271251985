import {
  doFaceDetection, addUserToDatabase, authenticateUser, getAllUsers, setUserInactive, installationVersion, analytics_faceFound, analyticsEnterSpace, analyticsCannotEnterSpace, countActiveUsers,
  automaticLoginUserName, automaticLoginUserLoc, automaticLoginUserComment
} from './main.js'
import { user, User, startScene, onLoadScene, setSceneIsLoading, getNumHusksNeeded, fillRemainingHusksWithRandomInfo, maxusers, initMainUser, getSceneIsLoading, randomIntFromInterval } from './sceneController.js'

export var bpmDetection




// to avoid having to wait for face models to load while debugging
if (doFaceDetection === true) {
  console.log('adding bpm detection')
  if (installationVersion) {
    bpmDetection = require('./bpm-detection-install.js')
  } else {
    bpmDetection = require('./bpm-detection.js')

  }
} else {
  document.getElementById('initUser').classList.remove('disabled')

  // if this is the installation version without face detection --> then this is the version showing 3 split views using scene-install.js
  if (installationVersion) {

  }
}

var myBPM
var createNewUserDiv



// when DOM is loaded, add listeners to html tags
window.addEventListener('DOMContentLoaded', (event) => {
  createNewUserDiv = document.getElementById('regsitration')



  if (installationVersion) {
    document.body.style.backgroundColor = 'black'
  } else {


    // name input listener
    document.getElementById('name_input').addEventListener('keyup', keystrokeOnNameInput)

    if(ISIPHONE){
      document.getElementById('initUser').addEventListener('click', getDeviceOrientationPermission)
    }else {
      // launch button listener
    document.getElementById('initUser').addEventListener('click', initNewUser)
    }


    if (doFaceDetection === false) {
      setTimeout(autmaticLogin(), 20000)
    }


  }
})

// when User is about to close the window. Make
window.onbeforeunload = function (e) {
  // set current user to be inactive in db
  window.RTDBBootUser(user.id)
  delete e.returnvalue
}

function keystrokeOnNameInput () {


  if (document.getElementById('name_input').value.length !== 0) {
    if (myBPM > 0 || doFaceDetection === false) {
      document.getElementById('initUser').classList.remove('disabled')
      console.log('remove class name ')
    }
  } else {
    document.getElementById('initUser').classList.add('disabled')
  }
}

export function registerMyBPM (bpm) {
  myBPM = bpm

  if (myBPM > 0) {
    if (document.getElementById('name_input').value.length !== 0) {
      document.getElementById('initUser').classList.remove('disabled')
    }
  } else {
    document.getElementById('initUser').classList.add('disabled')
  }
}

// automatically logins the user for the installation version
function autmaticLogin () {
  document.getElementById('name_input').value = automaticLoginUserName
  document.getElementById('loc_input').value = automaticLoginUserLoc
  document.getElementById('comment_input').value = automaticLoginUserComment

  setTimeout(initNewUser(), 5000)
}

// window.onkeypress = function (e) {
//   e = e || window.event
//   if (e.keyCode === 112) {
//     console.log(' p pressed, will remove self')
//     setUserInactive(user.id)
//   }
// }

// disable login button if there is no nmae written
// function nameTextChange (text) {
//   const name_input = document.getElementById('nam_input')
//   if (text === '') {
//     name_input.classList.remove('waves-effect waves-light btn-large white black-text')
//     name_input.classList.add('disabled')
//   } else {
//     name_input.classList.remove('disabled')

//     name_input.classList.add('waves-effect waves-light btn-large white black-text')
//   }
// }

// function getUserLocationByIP () {
//   var xhr = new XMLHttpRequest()
//   xhr.onreadystatechange = function () {
//     if (xhr.readyState === 4) {
//       console.log('result: ' + xhr.responseText)
//     }
//   }
//   xhr.open('GET', 'https://ip-api.com/json')
//   xhr.send()
// }

// on user button press of 'PEEK'
function peekScene () {
  // peek into the scene without creating a new user
  // continue without creating a user
  if(user == undefined){
    user = new User()

  }
  getAllUsers().then(result => {
    getNumHusksNeeded()
    // calling a delay to wait for all users to be added to the scene
    setTimeout(function () {
      prepareScene()
    }, 5000)
  })
}

// on user button press of 'LAUNCH' create a new user and start threeJS scene
function initNewUser () {
  // generate a new user
  if (user === undefined) {
  // send & recieve user data
    authenticateUser().then(userid => {
    // addUser(userid)
      countActiveUsers().then(result => {
        var numActiveUsers = result
        // console.log('from registration ' + numActiveUsers)
        if (numActiveUsers > maxusers - 5 && doFaceDetection === true) {
          alert('We are currently at capacity, please try again later')
          analyticsCannotEnterSpace(numActiveUsers)
        } else {
            addUser(userid)

        }
      })
    })
  }

  // use previous user info
  else {
    countActiveUsers().then(result => {
      var numActiveUsers = result
      // console.log('from registration ' + numActiveUsers)
      if (numActiveUsers > maxusers - 5 && doFaceDetection === true) {
        alert('We are currently at capacity, please try again later')
        analyticsCannotEnterSpace(numActiveUsers)
      } else {

          addUser(user.id, user)


      }
    })
  }
}

// iphone only needs explicit permissions
  function getDeviceOrientationPermission(){

  //   requestDeviceOrientation((response) => {
  //   console.log('done getting permissions')
  //   // initNewUser()
  // })

  // if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {

		window.DeviceOrientationEvent.requestPermission().then( function ( response ) {
      console.log('requested device permission')
			if ( response == 'granted' ) {
        console.log('device permission granted')
        initNewUser()

				// window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
				// window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, (ele) => {
        //  initNewUser()
        // } );

			}

		} ).catch( function ( error ) {

			console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );

    } );

	// } else {

	// 	window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
	// 	window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

	// }
}

function addUser (userid, user) {

   // if ios device ask for permissions to use orientation
   if(ISIPHONE){
    window.DeviceOrientationEvent.requestPermission()
    }
  // if android - go full screen
  if(ISMOBILE && !ISIPHONE){
    if (!document.fullscreenElement &&    // alternative standard method
      !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }


  try {
    document.body.requestPointerLock() // important
  } catch (e) {}




  var myUser
  if (user === undefined) {
    // get user info
    myUser = new User()
    myUser.id = userid
  } else {
    myUser = user
  }
  myUser.name = document.getElementById('name_input').value
  myUser.loc = document.getElementById('loc_input').value
  myUser.comment = document.getElementById('comment_input').value

  // console.log('authenticated')

  // get BPM else get a random one
  if (doFaceDetection && myBPM > 0) {
    myUser.bpm = myBPM
  } else {
    myUser.bpm = Math.floor(Math.random() * (110 - 50) + 50)
  }

  // add code to check data
  // console.log({ name: myUser.name, loc: myUser.loc, bpm: myUser.bpm, comment: myUser.comment })

  // set sceneController to isloading and load everything needed for scene
  setSceneIsLoading(true)
  onLoadScene()

  // analytics - pressed button to enter space
  analyticsEnterSpace(true)

  // show loading
  createNewUserDiv.style.display = 'none'
  document.getElementById('sceneIsLoadingDiv').style.display = 'block'
  document.getElementById('translationButton').style.display = 'none'

  // start loading progress animation
  let randomTime = randomIntFromInterval(100, 800)
    timedLoadedPercent = randomTime/maxLoadTime
  timeSceneStartedLoad = Date.now()
  sceneLoadingAnimation(randomTime)

  // console.log('adding user to database')
  addUserToDatabase(myUser).then(function (userID) {
    if (doFaceDetection) {
      bpmDetection.destroyBPMDetection()
      console.log('destroyed bpm detection')
    }

    getAllUsers().then(result => {
      getNumHusksNeeded()
      // calling a delay to wait for all users to be added to the scene
      // setTimeout(function () {
      //   prepareScene()
      // }, 5000)
    })
  })
}

var timedLoadedPercent = 0
var timeSceneStartedLoad = 0
var maxLoadTime = 5500 // slightly longer than the timeout called in adduser so that loading bar never actually reaches 100%

function sceneLoadingAnimation(time) {
  let loadingAnimation = setTimeout(() => {
    // cancel animation if no longer needed
    if(getSceneIsLoading() === false){
      clearInterval(loadingAnimation)
      return
    }

    // percent that has left to go:
    timedLoadedPercent = ((Date.now() - timeSceneStartedLoad)/maxLoadTime) * 100

    // amount 3d model is loaded
    var totalAmountLoaded = (threeJSLoaded * 0.3) + (timedLoadedPercent * 0.7)

    if(timedLoadedPercent > 50 && threeJSLoaded >= 100){
      clearTimeout(loadingAnimation)
      prepareScene()
    }else {

      // get the latest saved bpm reading
      let randomTime = randomIntFromInterval(20, 300)
      // timedLoadedPercent += (randomTime/maxLoadTime) * 100

      // setBPMHoldText(1.2, randomReading) // 'Detecting pulse... ' + pulse + ' BPM'
      document.getElementById('sceneLoadingBar').style.width = totalAmountLoaded + "%"
      sceneLoadingAnimation(randomTime)
    }

  }, time)
}

function prepareScene (users) {


  // fillRemainingHusksWithRandomInfo()
  setSceneIsLoading(false)
  // console.log("START SCENE")
  document.getElementById('sceneIsLoadingDiv').style.display = 'none'
  // calls start scene in scene Controller
  startScene()
}
