import { changeFaceDetector, getFaceIsFound, getForeheadPoints, getFPS, getLeftCheekPoints, getRightCheekPoints, getSingleFaceLandmarks, isFaceDetectionModelLoaded, TINY_FACE_DETECTOR } from '../js/face-detection-controls.js'
import { debugMode, analytics_faceFound, analytics_foundTrueBPM, analyticsLoginFPS } from './main.js'
import { registerMyBPM } from './user-registration.js'
import { stackBlurCanvasRGBtest } from '../js/StackBlur.js'
import { singleLoopStart, singleLoopStop } from '../js/single.js'
import { getTranslatedBPMText, lang, LOADING_FACE_DETECTION, NO_FACE_FOUND, FACE_FOUND, STAY_STILL, HONING_PULSE, PULSE_FOUND } from './language-lookup.js'
import { Landmark } from './landmark.js'

var video
var dobpmDetection = true
var faceIsFound = false
var currentState = LOADING_FACE_DETECTION

let fhRect = new Landmark(0, 0, 0, 0)
let LCheekRect = new Landmark(0, 0, 0, 0)
let RCheekRect = new Landmark(0, 0, 0, 0)

var filterStrength = 10
var frameTime = 0;
var lastLoop = new Date();
var thisLoop
var fpsOut = document.getElementById('pageFps')
var faceFoundThreshold = 100 //frames
var faceNotFoundCounter = 0

// variables for pulse detection
var hist = []
var regPulses = []
var signalCanvas
var timeDiffCanvas
var rawDataCanvas
var resetDataCanvas
var myBPM
var honingBPM = false
var timeDetectionLimit = 20/*seconds*/ * 1000/*milliseconds*/
var timeOfDetection = 0

export var finalBPM = 0

// for signals & smooth z score
var signals = [] // all signal processing signals that belong to this face
var filteredSignals
var avgFilter
var stdFilter
var clearData = false

// arrays with history for drawing graphs
var allHist = []
var allSignals = []
var facesVsTime = []

// checking for pulse
var lastSignal = 0
var numDiscardedPulses = 0
var savedBPM = []

// updates fps - used for debugging and analytics
setInterval(function () {
  fpsOut.innerHTML = (1000 / frameTime).toFixed(1) + ' fps'
}, 500)

window.addEventListener('resize', resizeElements)

// language has changed!
lang.registerListener(function(val){
  // console.log('language changed! ')
  updateViewerStatus(currentState)
})

// fetch dom content once loaded
window.addEventListener('DOMContentLoaded', (event) => {
  if (debugMode) {
    document.getElementById('videoInput').style.display = 'block'
    document.getElementById('faceDebug').style.display = 'block'
    document.getElementById('bpmDetectionInfo').style.display = 'block'
    document.getElementById('bpmDebug').style.display = 'block'
    document.getElementById('faceFps').textContent = 'loading'
  }

  dobpmDetection = true
  video = document.getElementById('videoInput')
  signalCanvas = document.getElementById('signalGraph')
  timeDiffCanvas = document.getElementById('timeDiffGraph')
  rawDataCanvas = document.getElementById('rawDataGraph')
  resetDataCanvas = document.getElementById('resetDetectionGraph')

  // resize graph canvases to page size
  resizeElements()

  // start video stream and init face detection
  initVideo()

  window.requestAnimationFrame(step)



})

// init video
async function initVideo() {

  // load face detection model
  await changeFaceDetector(TINY_FACE_DETECTOR)

  // request & get users video feed
  video.srcObject = await navigator.mediaDevices.getUserMedia({ video: {} })

  video.addEventListener('loadedmetadata', () => {
    video.play()
    resizeElements()
    detectFace()
    updateViewerStatus(NO_FACE_FOUND) // 'no face detected'

  })
}

// clean up BPM detection when leaving page
export function destroyBPMDetection() {
  if (video.srcObject) {
    video.srcObject.getTracks().forEach(function (track) {
      track.stop()
      // console.log('stop camera')
    })
  }
  // console.log('stop audio')
  singleLoopStop() // stops audio
  dobpmDetection = false
}

// resize canvases to scale as the page size changes
function resizeElements() {
  let vidAspectRatio = video.videoWidth / video.videoHeight

  // using welcome text to based new width off of. Subtract its padding
  let welcomeDiv = document.getElementById('welcomeText')
  let computedStyle = getComputedStyle(welcomeDiv);
  let newWidth = welcomeDiv.clientWidth;
  newWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

  // resize video - height is in respect of video's aspect ratio
  var newHeight = newWidth / vidAspectRatio
  video.width = newWidth
  video.height = newHeight

  var mirroredCanvas = document.getElementById('mirroredCanvas')
  mirroredCanvas.width = newWidth
  mirroredCanvas.height = newHeight

  // mirror camera
  // var mirroredCanvas = document.getElementById('mirroredCanvas')
  var mirroredCtx = mirroredCanvas.getContext('2d')
  mirroredCtx.translate(mirroredCanvas.width, 0)
  mirroredCtx.scale(-1, 1)
  // mirroredCtx.setTransform(1,0, 0, 1, 0, 0)
  

  // resize canvases
  var faceDebugCanvas = document.getElementById('faceDebug')
  faceDebugCanvas.width = newWidth
  faceDebugCanvas.height = newHeight

  var faceRectanglesCanvas = document.getElementById('faceRectangles')
  faceRectanglesCanvas.width = newWidth
  faceRectanglesCanvas.height = newHeight

  signalCanvas.width = newWidth
  signalCanvas.height = signalCanvas.width / 12

  timeDiffCanvas.width = newWidth
  timeDiffCanvas.height = timeDiffCanvas.width / 12

  rawDataCanvas.width = newWidth
  rawDataCanvas.height = rawDataCanvas.width / 12

  resetDetectionGraph.width = newWidth
  resetDetectionGraph.height = resetDataCanvas.width / 12

  // foreheadclip displays section of forehead on black bg. Cannot be smaller than a specific height
  var foreheadClipCanvas = document.getElementById('foreheadClip')
  foreheadClipCanvas.width = newWidth
  foreheadClipCanvas.height = (newWidth / 6 < 80) ? 80 : newWidth / 6
}

// state machine for handling the UI steps of bpm detection
export function updateViewerStatus(state, finalPulse) {

  currentState = state

  // no face found
  if(state === LOADING_FACE_DETECTION){
    document.getElementById('bpmHoldMessage').style.display = 'none'
    document.getElementById('bpmLoadingMessage').style.display = 'block'
    document.getElementById('bpmLoadingMessage').innerHTML = getTranslatedBPMText(LOADING_FACE_DETECTION)
  }
  else if (state === NO_FACE_FOUND) {
    // in the public version we only find the pulse once (in comparison to installation version where it re-captures)
    if (myBPM === undefined) {
      document.getElementById('bpmHoldMessage').style.display = 'block'
      document.getElementById('bpmLoadingMessage').style.display = 'none'
      document.getElementById('bpmHoldMessage').innerHTML = getTranslatedBPMText(NO_FACE_FOUND) // text: "no face detected"
    }
    singleLoopStop() // stop pulse audio if one is playing
    // document.getElementById('faceRectangles').style.display = 'none'
    clearData = true
    timeOfDetection = 0 // reset
    honingBPM = false

  }
  // face is found, no previous pulse has been detected
  else if (state === FACE_FOUND && myBPM === undefined) {
    document.getElementById('bpmHoldMessage').style.display = 'block'
    document.getElementById('bpmLoadingMessage').style.display = 'none'
    document.getElementById('bpmHoldMessage').innerHTML = getTranslatedBPMText(FACE_FOUND) // text: "face found"

    // update google analytics with latest data
    analytics_faceFound(true)
    analyticsLoginFPS((1000 / frameTime).toFixed(1), getFPS())

    timeOfDetection = Date.now() // reset

    // trigger detecting pulse text
    setTimeout(() => {
      document.getElementById('bpmHoldMessage').innerHTML = getTranslatedBPMText(STAY_STILL)

      // init honing animation after 3s if no pulse has been found
      if (myBPM === undefined) {
        setTimeout(() => {
          honingBPM = true
          setHoningPulseAnim(randomIntFromInterval(500, 2000))
        }, 3000)
      }
    }, 4000)
  }
  // face is found, show found pulse
  else if (state === FACE_FOUND && myBPM) {
    // face is found, and pulse is already detected
    singleLoopStart(myBPM)

  }
  // pulse found!
  else if (state === PULSE_FOUND && myBPM === undefined) {
    myBPM = finalPulse
    honingBPM = false // stop honing animation

    document.getElementById('bpmHoldMessage').style.display = 'none'
    document.getElementById('bpm').style.display = 'block'
    document.getElementById('bpm').innerHTML = getTranslatedBPMText(PULSE_FOUND)

    // start animation to display new pulse
    setTimeout(() => {
      document.getElementById('bpm').innerHTML = myBPM + ' BPM'
    }, 3000)

    registerMyBPM(finalPulse)
    singleLoopStart(myBPM) // start audio of pulse

    // analytics
    if (Date.now() - timeOfDetection > timeDetectionLimit) {
      analytics_foundTrueBPM(false)
    } else {
      analytics_foundTrueBPM(finalPulse)
    }
  }
}

// recursive animation function that calls itself until
// the pulse has been found or there is no face present
// to do face detection on
function setHoningPulseAnim(time) {
  let honingBPMTimer = setTimeout(() => {

    // cancel animation if no longer needed
    if (honingBPM === false) {
      clearInterval(honingBPMTimer)
      return
    }

    // get the latest saved bpm reading
    let latestReading = savedBPM[savedBPM.length - 1]
    let randomReading = 0

    // if the last determined reading showed a pulse greater than 55, then select a random pulse in
    // relation to that reading
    if (latestReading >= 55) {
      randomReading = randomIntFromInterval(parseInt(latestReading) - 2, parseInt(latestReading) + 2)
    } else {
      randomReading = randomIntFromInterval(55, 75)
    }

    // how long the next setTimeout for honing should be called in
    let randomTime = randomIntFromInterval(500, 2000)

    // setBPMHoldText(1.2, randomReading) // 'Detecting pulse... ' + pulse + ' BPM'
    document.getElementById('bpmHoldMessage').innerHTML = getTranslatedBPMText(HONING_PULSE) + randomReading + 'BPM'

    setHoningPulseAnim(randomTime)

  }, time)
}

// udpate frequency is fps of face detection (typically 5-15fps)
// detectface
function detectFace() {
  if (dobpmDetection === false) { return }

  //mirror image
  var mirroredCanvas = document.getElementById('mirroredCanvas')
  var mirroredCtx = mirroredCanvas.getContext('2d')

  mirroredCtx.drawImage(video, 0, 0, mirroredCanvas.width, mirroredCanvas.height)

  // check if face models are loaded and video is streaming, if not wait and try again later
  var isLoaded = isFaceDetectionModelLoaded()
  if (video.paused || video.ended || !isLoaded) { return setTimeout(() => detectFace()) }

  // canvas is used as dimensions to match face detection landmark positions to
  const canvas = document.getElementById('faceRectangles')

  var isDrawingDetection = debugMode ? document.getElementById('showDetection').checked : false
  var isDrawingLandmarks = debugMode ? document.getElementById('showLandmarks').checked : false

  // find face landmarks for first face found
  getSingleFaceLandmarks(mirroredCanvas, canvas, isDrawingLandmarks, isDrawingDetection)

  // if no face was found
  if (getFaceIsFound() === false) {
    // increase no face found threshold buffer. Once threshold is met, handle event of no
    // face was found. Face detection can flicker and we want to have confidence before
    // updating user on current bpm detection state
    faceNotFoundCounter++
    if (faceNotFoundCounter > faceFoundThreshold || allHist.length === 1) {
      if (faceIsFound === true) {
        analytics_faceFound(false)
      }
      faceIsFound = false

      updateViewerStatus(NO_FACE_FOUND)
    }
  } else {
    if(debugMode){
      document.getElementById('faceFps').textContent = getFPS()
    }

    // update viwer's status once face is found.
    if(faceIsFound == false){
      updateViewerStatus(FACE_FOUND)
    }

    // face was found in detection! Update positions of rectangles drawn on face
    faceIsFound = true
    updateForeheadRectPos(getForeheadPoints())
    updateCheekRectPos(LCheekRect, getLeftCheekPoints())
    updateCheekRectPos(RCheekRect, getRightCheekPoints())

    faceNotFoundCounter = 0
  }

  setTimeout(() => detectFace())
}

// runs every frame to draw to screen (~30fps)
function step() {
  if (dobpmDetection === false) { return }

  // copies skin sample inside face rectangles to this canvas
  const foreheadImageData = getForeheadFromVideo(document.getElementById('foreheadClip'))

  // only show face rectangles if face has been found
  let faceRectanglesDOM = document.getElementById('faceRectangles')

  if (faceIsFound) {
    // draws rectangles seen on face to canvas
    faceRectanglesDOM.style.display = 'block'
    drawRectanglesOnFace(faceRectanglesDOM)

    // determine pulse based on skin sample
    if (foreheadImageData !== undefined) { getPulse(foreheadImageData.data) }
  }else {
    faceRectanglesDOM.style.display = 'none' // hide rectangles on face if no face is present
  }

  // if user  has been waiting for pulse over the time limit, give them a random pulse
  if (timeOfDetection > 0) {
    if (myBPM === undefined && Date.now() - timeOfDetection > timeDetectionLimit) {
      updateViewerStatus(PULSE_FOUND, randomIntFromInterval(60, 84))
    }
  }

  // update fps time (used for analytics and debugging)
  fpsLoop()

  // recall this function next frame
  window.requestAnimationFrame(step)
}

// updates forehead with latest landmark information
// forehead landmarks are points 19 (left) and 24 (right) from dlib's 68 landmarks
// https://tinyurl.com/y3nctxrz
function updateForeheadRectPos(foreheadPoints) {
  // returns undefined if array
  fhRect.smoothPnts(foreheadPoints, 'left', 'right') // left is pnt1, right is pnt2

  if (!fhRect.isSmoothPntsAvg()) {
    return
  }

  // determine size of rectangle. Wdith is length between the 2 points, takes into account that
  // face is on an angle
  fhRect.sy = pythagorean(fhRect.pnt2XAvg - fhRect.pnt1XAvg, fhRect.pnt2YAvg - fhRect.pnt1YAvg)
  fhRect.sx = fhRect.sy / 4

  // position will be bottom left of rectangle
  fhRect.x = fhRect.pnt1XAvg
  fhRect.y = fhRect.pnt1YAvg

  // find angle head is on
  var hy = 0
  if (fhRect.pnt2YAvg > fhRect.pnt1YAvg) {
    hy = fhRect.pnt2YAvg - fhRect.pnt1YAvg
    hy = hy * -1
  } else {
    hy = fhRect.pnt1YAvg - fhRect.pnt2YAvg
  }

  fhRect.angle = Math.acos(hy / fhRect.sy)
}

// update cheek rectangle position and size
// left cheek landmarks are 46 (top) and 54 (bottom) from dlib's 68 landmarks
// right cheek landmarks are 41 (top) and 48 (bottom)
// https://tinyurl.com/y3nctxrz
function updateCheekRectPos(cheekLandmark, newPnts) {
  cheekLandmark.smoothPnts(newPnts, 'top', 'bottom')

  // height of rectangle is 60% of the size between the top and bottom point
  const hDiff = (cheekLandmark.pnt1YAvg - cheekLandmark.pnt2YAvg)
  cheekLandmark.sy = hDiff * 0.60
  cheekLandmark.sx = hDiff / 4

  cheekLandmark.x = cheekLandmark.pnt1XAvg
  cheekLandmark.y = cheekLandmark.pnt1YAvg - (hDiff * ((1 - 0.6) / 2))
}

function drawRectanglesOnFace(canvas) {
  var context = canvas.getContext('2d')
  context.setTransform(1, 0, 0, 1, 0, 0)

  // draw forehead
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.save()
  drawRectangle(canvas, context, fhRect.x, fhRect.y, fhRect.sx, fhRect.sy)

  // draw left cheek
  context.restore()
  context.save()
  drawRectangle(canvas, context, LCheekRect.x - LCheekRect.sx, LCheekRect.y, LCheekRect.sy, LCheekRect.sx)

  // draw right cheek
  context.restore()
  drawRectangle(canvas, context, RCheekRect.x, RCheekRect.y, RCheekRect.sy, RCheekRect.sx)
}

// draws rectangle with given params. It moves context to rotate around translation point, then draws the rectangle
function drawRectangle(canvas, context, x, y, rectWidth, rectHeight) {
  context.translate(x, y)
  context.rotate(fhRect.angle + Math.PI)
  context.lineWidth = '1'

  context.beginPath()
  context.fillStyle = 'rgba(0, 0, 0, 0)'
  context.fillRect(0, 0, canvas.width, canvas.height)
  context.rect(0, 0, rectWidth, rectHeight)
  context.stroke()
  context.closePath()
}

// draw pixels from forehead to black isolation zone
function getForeheadFromVideo(canvas) {
  if (fhRect.sx <= 0) {
    return undefined
  }

  var context = canvas.getContext('2d')
  var canvasResizeOffset = video.videoWidth / canvas.width
  var borderOffset = 20

  // add black background
  context.fillStyle = '#000'
  context.fillRect(0, 0, canvas.width, canvas.height)
  context.save()

  // forehead
  // moving context so 0, 0 is in top left corner of rectangle on forhead
  context.beginPath()
  context.translate(-fhRect.x * canvasResizeOffset, (-fhRect.y + fhRect.sx) * canvasResizeOffset)
  context.rect(fhRect.x * canvasResizeOffset + borderOffset, (fhRect.y - fhRect.sx) * canvasResizeOffset + borderOffset, fhRect.sy * canvasResizeOffset, fhRect.sx * canvasResizeOffset)
  context.clip()

  // draw video at 0, 0, add some padding around video by offsetting it slightly
  context.drawImage(video, borderOffset, borderOffset)

  // get pixel data from clipped video
  var imagedata = context.getImageData(0, 0, canvas.width, canvas.height)

  // blur pixels to limit disfigurations and shakiness effect on bpm outcome
  imagedata = stackBlurCanvasRGBtest(imagedata, canvas.width, canvas.height, borderOffset)
  context.putImageData(imagedata, 0, 0)

  context.restore()

  // get image data again set against a black background (helpful for bpm calc)
  imagedata = context.getImageData(0, 0, fhRect.y + borderOffset, fhRect.x + borderOffset)

  return imagedata
}

/// ///////// BPM DETECTION
///
///
///
///
///
///

// get pulse from latest pixels
function getPulse(data) {

  // resets algorithims when new face is found
  if (clearData === true) {
    hist = []
    filteredSignals = []
    clearData = false
    signals = []
    facesVsTime.push(1) // new face is found, note it in time in accordance with the graph
  } else {
    facesVsTime.push(0)
  }


  // get sumation of values from the blue and green channels (channels that change the most with pulse)
  // var len = data.length
  var sum = 0
  for (var i = 0, j = 0; j < data.length; i++, j += 4) {
    sum += data[j + 1] + data[j + 2] // B + G
  }
  var brightness = sum / data.length

  // save data to history
  hist.push({ bright: brightness, time: Date.now() })
  allHist.push({ bright: brightness, time: Date.now() })
  while (hist.length > signalCanvas.width) hist.shift()


  // max and min of sum
  var max = hist[0].bright
  var min = hist[0].bright
  var brightArray = []
  hist.forEach(function (v) {
    // if (v.bright > max) max = v.bright
    // if (v.bright < min) min = v.bright
    brightArray.push(v.bright)
  })


  const lag = 10
  if (brightArray.length < lag + 2) {
    return
  }

  const threshold = 2.0 + (regPulses.length * 0.05)
  const influence = 10
  // console.log(brightArray.slice(-1).pop(), brightness)

  // signal processing algorithim to detect variances in signal strength with moving times and strengths
  var sig = updateSignals(brightness, signalCanvas.width, { lag: lag, influence: influence, threshold: threshold })
  signals.push(sig)
  allSignals.push(sig)
  while (signals.length > signalCanvas.width) signals.shift()
  while (allSignals.length > signalCanvas.width) allSignals.shift()

  // compare against last signal to determine if pulse happened
  if (sig === -1 && lastSignal !== -1) {
    checkForPulse(hist[hist.length - 1].time)

    // write pulse
    if (regPulses.length >= 3) {
      var pulseRate = (60000 / pulseMean(regPulses)).toFixed(0)

      if (myBPM === undefined && pulseRate > 60) {
        updateViewerStatus(PULSE_FOUND, pulseRate)
        console.log('pulse found: ' + pulseRate)
      }

      if (debugMode) {
        document.getElementById('debugBPM').innerHTML = pulseRate + ' BPM (' + regPulses.length + ' pulses)'
      }
      savedBPM.push(pulseRate)


    } else if (debugMode) {
      document.getElementById('bpmDebug').innerHTML = '-- BPM'
    }

  }

  lastSignal = sig

  // draw signal canvas (white line)
  var sigctx = signalCanvas.getContext('2d')
  sigctx.clearRect(0, 0, signalCanvas.width, signalCanvas.height)
  sigctx.beginPath()
  sigctx.moveTo(0, 0)
  allSignals.forEach(function (v, x) {
    var y = signalCanvas.height * (v + 1) / 2
    sigctx.lineTo(x, y)
  })
  sigctx.lineWidth = 1
  sigctx.strokeStyle = 'white'
  sigctx.stroke()

  // draw all found bpms canvas (slow thick white line)
  while (savedBPM.length > timeDiffCanvas.width) savedBPM.shift()
  var bpmmax = savedBPM[0]
  var bpmmin = savedBPM[0]
  savedBPM.forEach(function (v) {
    if (v > bpmmax) bpmmax = v
    if (v < bpmmin) bpmmin = v
  })
  var tctx = timeDiffCanvas.getContext('2d')
  tctx.clearRect(0, 0, timeDiffCanvas.width, timeDiffCanvas.height)
  tctx.beginPath()
  tctx.moveTo(0, 0)
  savedBPM.forEach(function (v, x) {
    var y = timeDiffCanvas.height * (v - bpmmin) / (bpmmax - bpmmin)
    tctx.lineTo(x, y)
  })
  tctx.lineWidth = 3
  tctx.strokeStyle = '#d3d3d3'
  tctx.stroke()


  // draw raw data brightness canvas (red)
  drawGraph(rawDataCanvas, allHist)
  // max and min
  // max = allHist[0].bright
  // min = allHist[0].bright
  // allHist.forEach(function (v) {
  //   if (v.bright > max) max = v.bright
  //   if (v.bright < min) min = v.bright
  // })
  // var rawCtx = rawDataCanvas.getContext('2d')
  // rawCtx.clearRect(0, 0, rawDataCanvas.width, rawDataCanvas.height)
  // rawCtx.beginPath()
  // rawCtx.moveTo(0,0)

  // allHist.forEach(function(v,x) {
  //   var y = rawDataCanvas.height * ( v.bright - min) / (max-min)
  //   rawCtx.lineTo(x, y )
  // })
  // rawCtx.lineWidth = 1
  // rawCtx.strokeStyle = '#FF0000'
  // rawCtx.stroke()

  // mark that a new dataset has started (new face detected)
  while (facesVsTime.length > resetDataCanvas.width) facesVsTime.shift()
  var resetCtx = resetDataCanvas.getContext('2d')
  resetCtx.clearRect(0, 0, resetDataCanvas.width, resetDataCanvas.height)
  resetCtx.beginPath()
  resetCtx.moveTo(0, 0)
  facesVsTime.forEach(function (v, x) {
    if (v === 1) {
      resetCtx.moveTo(x, (resetDataCanvas.height * 0.75))
      resetCtx.lineTo(x, resetDataCanvas.height * 0.25)
    }
  })
  resetCtx.lineWidth = 8
  resetCtx.strokeStyle = '#FFFFFF'
  resetCtx.stroke()


}

function drawGraph(canvas, data) {
  while (data.length > canvas.width) data.shift()
  let max = data[0].bright
  let min = data[0].bright
  data.forEach(function (v) {
    if (v.bright > max) max = v.bright
    if (v.bright < min) min = v.bright
  })
  var rawCtx = canvas.getContext('2d')
  rawCtx.clearRect(0, 0, canvas.width, canvas.height)
  rawCtx.beginPath()
  rawCtx.moveTo(0, 0)

  data.forEach(function (v, x) {
    var y = canvas.height * (v.bright - min) / (max - min)
    rawCtx.lineTo(x, y)
  })
  rawCtx.lineWidth = 1
  rawCtx.strokeStyle = '#FF0000'
  rawCtx.stroke()
}

function checkForPulse(pTime) {
  // variables
  const minDur = (40 / 60) * 1000 // min time for 40bpm, converted to milliseconds
  const maxDur = (120 / 60) * 1000 // max time for 120bpm, converted to milliseconds
  const minPulsesForSuccess = 3
  const regTimeBuffer = 0.2
  const maxDiscardedPulses = 3
  const maxSavedHeartbeats = 15

  // console.log({ regPulses })

  // console.log(regPulses)
  // if there are no registered pulses
  if (!regPulses || regPulses.length === 0) {
    regPulses.push(pTime)
    return
  }

  const pDur = pTime - regPulses[regPulses.length - 1]

  // check if pulse is within an expected time period
  if (pDur < minDur) {
    // ignore, do not save
    if (regPulses.length === 1) {
      regPulses = []
      regPulses.push(pTime)
    }
    // console.log("pulse is less than minDur  "  + minDur);//+ pDur + "  minDur: "
    return
  }

  // console.log("registered pulses: " + regPulses);

  // if pulse is already found compare this one to the last successes
  if (regPulses.length >= minPulsesForSuccess) {
    // compare new pulse time to the avg of recorded ones
    var regMean = pulseMean(regPulses)
    if (pDur <= regMean * (regTimeBuffer + 1) && pDur > regTimeBuffer * (1 - regTimeBuffer)) {
      regPulses.push(pTime)
      return
    }

    // FUTURE change this to any increment less than a set value like 3
    // see if a pulse was missed - this one is within bounds of two times the expected time.
    var min2times = regMean * (regTimeBuffer + 2)
    var max2times = regTimeBuffer * (2 - regTimeBuffer)
    if (pDur <= regMean * (regTimeBuffer + 2) && pDur > regTimeBuffer * (2 - regTimeBuffer)) {
      // console.log("pulse is within 2x range: pulseDur: " + pDur + "  min: " + min2times + "  max: " + max2times);
      // first add missed pulse

      regPulses.push(regPulses[regPulses.length - 1] + pDur / 2)

      // add this pulse
      regPulses.push(pTime)
      return
    }

    // discard this pulse.
    numDiscardedPulses++

    // empty all pervious registered pulses
    if (numDiscardedPulses >= maxDiscardedPulses) {
      console.log('emptying - too many discardedPulses')
      regPulses = []
      regPulses.push(pTime)
    }
  }
  // gaining trust in our registered pulses
  else {
    if (pDur >= minDur && pDur <= maxDur) {
      regPulses.push(pTime)
      return
    }

    // restart the registration of pusles
    regPulses = []
    regPulses.push(pTime)
  }
}

// get the average length of pulse
// since regPulse is recording the time the pulse was taken, we need the duration of each pulse to compute an average
function pulseMean(a) {
  var inbetweenTimes = []
  // console.log("new pulse mean: " );
  for (var i = 1; i < a.length; i++) {
    inbetweenTimes.push(a[i] - a[i - 1])
  }
  // inbetweenTimes.forEach(function(v,x){
  //   console.log("inbetweentime at: " + x + " = " + inbetweenTimes[x])
  // })

  var max = inbetweenTimes[0]
  var min = inbetweenTimes[0]
  inbetweenTimes.forEach(function (v) {
    if (v > max) max = v
    if (v < min) min = v
  })
  var mean = sum(inbetweenTimes) / inbetweenTimes.length
  // console.log('pulseman: ' + mean + '  max dur: ' + max + '  min dur: ' + min)
  // console.log({inbetweenTimes})
  return mean
}

// function checkAgainstLastPulses(newPulse){
//   newP
// updateViewerStatus(3, pulseRate.toFixed(0))
// }

function sum(a) {
  return a.reduce((acc, val) => acc + val)
}

function mean(a) {
  return sum(a) / a.length
}

function stddev(arr) {
  const arr_mean = mean(arr)
  const r = function (acc, val) {
    return acc + ((val - arr_mean) * (val - arr_mean))
  }
  return Math.sqrt(arr.reduce(r, 0.0) / arr.length)
}

// using the saved data in signa
function updateSignals(newData, maxArrayLength, params) {
  var p = params || {}
  // init cooefficients
  const lag = p.lag || 5
  const threshold = p.threshold || 3.5
  const influence = p.influece || 0.5

  if (hist === undefined || hist.length < lag + 2) {
    throw ` ## y data array to short(${hist.length}) for given lag of ${lag}`
  }
  // console.log(`lag, threshold, influence: ${lag}, ${threshold}, ${influence}`)

  // init vars if first time ran
  if (!filteredSignals || filteredSignals.length === 0) {
    const lead_in = hist.slice(0, lag)
    // filteredSignals = hist.slice(0)
    filteredSignals = []
    filteredSignals = lead_in
    avgFilter = []
    avgFilter[lag - 1] = mean(lead_in)
    stdFilter = []
    stdFilter[lag - 1] = stddev(lead_in)
    // console.log('init')
  }
  // init variables
  //     var signals = Array(y.length).fill(0)
  //     var filteredY = y.slice(0)
  //     const lead_in = y.slice(0, lag)
  // //console.log("1: " + lead_in.toString())
  //     var avgFilter = []
  //     avgFilter[lag-1] = mean(lead_in)
  //     var stdFilter = []
  //     stdFilter[lag-1] = stddev(lead_in)
  // console.log("2: " + stdFilter.toString())

  // for(var i = lag; i < y.length; i++) {
  // console.log(`${y[i]}, ${avgFilter[i-1]}, ${threshold}, ${stdFilter[i-1]}`)
  var newSignal = 0
  if (Math.abs(newData - avgFilter[signals.length - 2]) > (threshold * stdFilter[signals.length - 2])) {
    if (newData > avgFilter[signals.length - 2]) {
      newSignal = +1 // positive signal
    } else {
      newSignal = -1 // negative signal
    }
    // make influence lower
    filteredSignals.push(influence * newData + (1 - influence) * filteredSignals[filteredSignals.length - 2])
  } else {
    newSignal = 0 // no signal
    filteredSignals.push(newData)
  }

  // adjust the filters
  const y_lag = filteredSignals.slice(filteredSignals.length - 1 - lag, filteredSignals.length - 1)
  avgFilter.push(mean(y_lag))
  stdFilter.push(stddev(y_lag))
  if (filteredSignals.length == maxArrayLength) {
    avgFilter.shift()
    stdFilter.shift()
    filteredSignals.shift()
    // console.log("maxedlength " + filteredSignals.length + "  " + avgFilter.length + "  " + stdFilter.length);
  }

  return newSignal
}

function timer(callback, delay) {
  var id; var started; var remaining = delay; var running

  this.start = function () {
    running = true
    started = new Date()
    id = setTimeout(callback, remaining)
  }

  this.pause = function () {
    running = false
    clearTimeout(id)
    remaining -= new Date() - started
  }

  this.getTimeLeft = function () {
    if (running) {
      this.pause()
      this.start()
    }

    return remaining
  }

  this.getStateRunning = function () {
    return running
  }

  this.start()
}

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// update fps
function fpsLoop() {
  var thisFrameTime = (thisLoop = new Date()) - lastLoop
  frameTime += (thisFrameTime - frameTime) / filterStrength
  lastLoop = thisLoop
}

function pythagorean(sideA, sideB) {
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2))
}
