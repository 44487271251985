// contains information for 2 points on face. Info on these 2 points are used to draw
// rectangles on face, and locates where to sample skin for bpm detection
export class Landmark {
  constructor(x, y, sx, sy) {
    this.x = x // x position of rectangle
    this.y = y // y position of rectangle
    this.sx = sx // width of rectangle
    this.sy = sy // height of rectangle
    this.angle = 0 // angle of rectangle

    // arrays for moving average, this smooths the position and size of captures area
    this.pntsLength = 7
    this.pntsIndex = 0
    this.pnts1X = []
    this.pnts1Y = []
    this.pnts2X = []
    this.pnts2Y = []

    // saves the average positions for each moving array
    this.pnt1XAvg = 0
    this.pnt1YAvg = 0
    this.pnt2XAvg = 0
    this.pnt2YAvg = 0
  }

  // saves the latest point information to the array, and re-calculates the average position of each point
  smoothPnts(renderedLandmark, param1, param2) {

    this.pnts1X[this.pntsIndex] = renderedLandmark[param1].x
    this.pnts1Y[this.pntsIndex] = renderedLandmark[param1].y
    this.pnts2X[this.pntsIndex] = renderedLandmark[param2].x
    this.pnts2Y[this.pntsIndex] = renderedLandmark[param2].y

    if(this.pnts1X.length === this.pntsLength){
      this.pnt1XAvg = this.pnts1X.reduce((a, b) => a + b) / this.pntsLength
      this.pnt1YAvg = this.pnts1Y.reduce((a, b) => a + b) / this.pntsLength
      this.pnt2XAvg = this.pnts2X.reduce((a, b) => a + b) / this.pntsLength
      this.pnt2YAvg = this.pnts2Y.reduce((a, b) => a + b) / this.pntsLength
    }

    if (this.pntsIndex < this.pntsLength - 1) {
      this.pntsIndex++
    } else {
      this.pntsIndex = 0
    }
  }

  // returns if the average has been calculated
  isSmoothPntsAvg() {
    return (this.pnt1XAvg && this.pnt1YAvg && this.pnt2XAvg && this.pnt2YAvg)
  }
}
