// var faceDetectionControls = require('./bpm-detection.js');
import { changeFaceDetector, changeInputSize, getFaceIsFound, getForeheadPoints, getFPS, getLeftCheekPoints, getRightCheekPoints, getSingleFaceLandmarks, isFaceDetectionModelLoaded, TINY_FACE_DETECTOR } from '../js/face-detection-controls.js'
import { stackBlurCanvasRGBtest } from '../js/StackBlur.js'
import { debugMode, doFaceDetction, installationVersion, randomIntFromInterval} from './main.js'

var audioBPM = require('../js/single.js')

var stream
var video
var dobpmDetection = true
var faceIsFound = false
var oldForheadLeftX = []
var oldForheadRightX = []
var oldForheadLeftY = []
var oldForheadRightY = []
var oldLTopCheekX = []
var oldLBottomCheekX = []
var oldLTopCheekY = []
var oldLBottomCheekY = []
var oldRTopCheekX = []
var oldRBottomCheekX = []
var oldRTopCheekY = []
var oldRBottomCheekY = []

var fhRectPos = {
  x: 0,
  y: 0
}
var fhRectSize = {
  x: 0,
  y: 30
}
var fhRectAngle = 0

var LCheekRectPos = {
  x: 0,
  y: 0
}
var LCheekRectSize = {
  x: 0,
  y: 0
}
var RCheekRectPos = {
  x: 0,
  y: 0
}
var RCheekRectSize = {
  x: 0,
  y: 0
}

var filterStrength = 10
var frameTime = 0; var lastLoop = new Date(); var thisLoop
var fpsOut = document.getElementById('pageFps')
var faceFoundThreshold = 60
var faceFoundCounter = 0

// variables for pulse detection
var hist = []
var regPulses = []
var signalCanvas
var timeDiffCanvas
var rawDataCanvas
var resetDataCanvas
var displayedBPM
var myBPM
var honingBPM = false
var honingBPMTimer
var timeSinceLastHone
var timeToNextHone
var timeOfDetection = 0
var maxFinalDetectionInterval = 5000
export var finalBPM = 0

// for signals & smooth z score
var signals = []
var filteredSignals
var avgFilter
var stdFilter
var clearData = false

// arrays with history for drawing graphs
var allHist = []
var allSignals = []
var facesVsTime = []

// checking for pulse
var lastSignal = 0
var numDiscardedPulses = 0
var savedBPM = []

// resizing image
var videoToCanvasSizeX = 0
var videoToCanvasSizeY = 0
var videoToCanvasPosX = 0
var videoToCanvasPosY = 0

setInterval(function () {
  fpsOut.innerHTML = (1000 / frameTime).toFixed(1) + ' fps'
}, 500)

window.addEventListener('resize', resizeElements)

// var leftPoint, rightPoint
window.addEventListener('DOMContentLoaded', (event) => {
  if (debugMode) {
    document.getElementById('videoInput').style.display = 'block'
    document.getElementById('faceDebug').style.display = 'block'
    document.getElementById('bpmDetectionInfo').style.display = 'block'
    document.getElementById('bpmDebug').style.display = 'block'
  }

  dobpmDetection = true
  video = document.getElementById('videoInput')
  signalCanvas = document.getElementById('signalGraph')
  timeDiffCanvas = document.getElementById('timeDiffGraph')
  rawDataCanvas = document.getElementById('rawDataGraph')
  resetDataCanvas = document.getElementById('resetDetectionGraph')

  // setup graph canvases to resize
  resizeElements()
  // bpmNew = document.getElementById('bpmNew')

  // when first registration button is pressed, begin adding user info
  video.addEventListener('loadedmetadata', onVideoLoaded)
  document.getElementById('faceFps').textContent = 'loading'
  initVideo()

  window.requestAnimationFrame(step)
})

// resize canvases to scale as the desktop size changes
function resizeElements () {
  var vidAspectRatio = video.videoWidth / video.videoHeight
  var newWidth = document.getElementById('visitorVideoViewDiv').offsetWidth
  var newHeight = newWidth / vidAspectRatio

  // resize video - automatically resizes height based on aspect ratio
  video.width = newWidth
  video.height = newHeight

  // resize visitor's camera view
  var visitorVideoCanvas = document.getElementById('visitorVideoView')
  visitorVideoCanvas.width = newWidth
  visitorVideoCanvas.height = newHeight

  var faceDebugCanvas = document.getElementById('faceDebug')
  faceDebugCanvas.width = newWidth
  faceDebugCanvas.height = newHeight

  var foreheadRectCanvas = document.getElementById('foreheadRect')
  var videoCanvas = document.getElementById('videoCanvas')
  foreheadRectCanvas.width = videoCanvas.width
  foreheadRectCanvas.height = videoCanvas.height

  signalCanvas.width = newWidth
  signalCanvas.height = signalCanvas.width / 12

  timeDiffCanvas.width = newWidth
  timeDiffCanvas.height = timeDiffCanvas.width / 12

  rawDataCanvas.width = newWidth
  rawDataCanvas.height = rawDataCanvas.width / 12

  resetDetectionGraph.width = newWidth
  resetDetectionGraph.height = resetDataCanvas.width / 12

  var foreheadClipCanvas = document.getElementById('foreheadClip')
  foreheadClipCanvas.width = newWidth
  foreheadClipCanvas.height = (newWidth / 6 < 80) ? 80 : newWidth / 6
}

function updateViewerStatus (phase, finalPulse) {
  document.getElementById('bpmHoldMessage').style.display = 'block'

  if (phase === 0) {
    // no face found
    clearTimeout(honingBPMTimer)
    document.getElementById('bpmHoldMessage').innerHTML = 'Aucun visage détecté'
    audioBPM.singleLoopStop()
    clearData = true

    if (displayedBPM !== undefined) {
      document.getElementById('bpm').style.display = 'none'
      document.getElementById('bpm').innerHTML = 'Pouls trouvé!'
      displayedBPM = undefined
    }
  } else if (phase === 1) {
    // finding face
    document.getElementById('bpmHoldMessage').innerHTML = 'Visage trouvé!'

    // trigger detecting pulse 3s later
    setTimeout(() => {
      document.getElementById('bpmHoldMessage').innerHTML = 'Détection du pouls. Veuillez demeurer immobile'
      setTimeout(() => {
        // init honing
        if(displayedBPM === undefined){
        honingBPM = true
        console.log('starting to hone pulse')
      let latestReading = savedBPM[savedBPM.length-1]
      let randomTime = randomIntFromInterval(500, 2000)
      honingPulseTimer(randomIntFromInterval(latestReading - 5, latestReading + 5), randomTime)
        }
    }, 3000)
    }, 4000)

  } else if (phase === 3) {
    // found pulse!
    // start animation
    myBPM = finalPulse
    console.log('mybpm: ' + myBPM)
    displayedBPM = finalPulse
    honingBPM = false
    clearTimeout(honingBPMTimer)
    setTimeout(() => {
      document.getElementById('bpm').innerHTML = myBPM + ' BPM'
    }, 3000)
    document.getElementById('bpmHoldMessage').style.display = 'none'
    document.getElementById('bpmHoldMessage').style.color = '#FFF'

    document.getElementById('bpm').style.display = 'block'
    document.getElementById('bpm').innerHTML = 'Pouls trouvé!'
    // document.getElementById('bpm').classList.add('bpmTextAnimation')


    audioBPM.singleLoopStart(displayedBPM)
  } else if (phase === 4) {
    // a closer pulse to 70 was found
    // doesn't get called right now
    document.getElementById('bpm').innerHTML = finalPulse + ' BPM'
    document.getElementById('bpm').style.color = '#FFFFFF'
    displayedBPM = pulseRate.toFixed(0)
    audioBPM.singleLoopStop()
  }
}

function honePulse(newPulse){
  document.getElementById('bpm').innerHTML = newPulse + ' BPM'
  timeOfDetection = Date.now()

}
function honingPulseTimer (pulse, time){
  if(honingBPM === true){
    clearTimeout(honingBPMTimer)
  honingBPMTimer = setTimeout(() => {
    console.log('honing pulse')
    if(honingBPM === true){
      console.log('honingbpm from honing ' + honingBPM)
      if (isNaN(pulse)) {
        pulse = randomIntFromInterval(55, 70)
      }
      document.getElementById('bpmHoldMessage').innerHTML = 'Détection de pouls... ' + pulse + ' BPM'
      //document.getElementById('bpmHoldMessage').style.color = '#FF0000'
      let latestReading = savedBPM[savedBPM.length-1]
      let randomTime = randomIntFromInterval(500, 2000)
      honingPulseTimer(randomIntFromInterval(latestReading - 5, latestReading + 5), randomTime)
    }
  }, time)
  }
  else if(honingBPMTimer !== undefined){
    clearTimeout(honingBPMTimer)
  }
}


async function initVideo () {
  // load face detection model
  await changeFaceDetector(TINY_FACE_DETECTOR)
  // changeInputSize(128)

  stream = await navigator.mediaDevices.getUserMedia({ video: {width: {ideal: 1280}, height:{ ideal:720}, frameRate: {ideal: 30}} })
  video.srcObject = stream
  video.play()

  // resizeElements()
}

export function destroyBPMDetection () {
  if (stream) {
    stream.getTracks().forEach(function (track) {
      track.stop()
    })
  }
  audioBPM.singleLoopStop()
  dobpmDetection = false
}

function onVideoLoaded () {
  resizeElements()
  playVideo()
  console.log('in video loaded')
  if (installationVersion === true) {
    var videoCanvas = document.getElementById('videoCanvas')
    var newWidth = document.getElementById('visitorVideoViewDiv').offsetWidth
    videoCanvas.width = newWidth//window.innerWidth
    videoCanvas.height = newWidth*1.3// window.innerWidth*1.333
    console.log('set videoCanvas width and height to: ' + videoCanvas.width + '   ' + videoCanvas.height)
    var ctx = videoCanvas.getContext('2d')

    ctx.setTransform(1, 0, 0, -1, video.videoHeight+111,video.videoWidth)
     ctx.rotate(90 * Math.PI / 180)

      videoToCanvasSizeX = videoCanvas.width/video.videoHeight
      videoToCanvasPosX =  videoCanvas.width-video.videoHeight

      console.log({videoToCanvasSizeX})
      console.log('2 set videoCanvas width and height to: ' + videoCanvas.width + '   ' + video.height)
     ctx.scale(videoToCanvasSizeX,videoToCanvasSizeX)

      resizeElements()
  }
}

function playVideo () {
  if (dobpmDetection === false) { return }
  // get video feed
  const videoEl = video

  // rotate for installation mode
  if (installationVersion === true) {
    var videoCanvas = document.getElementById('videoCanvas')
    var ctx = videoCanvas.getContext('2d')
   // ctx.drawImage(videoEl, 0, 0)
     ctx.drawImage(videoEl, 100, -videoToCanvasPosX+120) // with border
   //ctx.drawImage(videoEl, -300, -videoToCanvasPosX+120) // full portrait screen
    // console.log('videoEl W and H: ' + video.videoWidth + '   ' + video.videoHeight)
    // ctx.getImageData(0, 0, videoCanvas.width, videoCanvas.height)
  }

  // check if face models are loaded, if not wait and try again later
  var isLoaded = isFaceDetectionModelLoaded()
  // console.log('1  ' + getFPS())
  if (videoEl.paused || videoEl.ended || !isLoaded) { return setTimeout(() => playVideo()) }

  // get canvas to draw face detection to
  const canvas = document.getElementById('faceDebug')
  // console.log('vid w ' + videoEl.width + '  face w ' + canvas.width + 'vid h ' + videoEl.height + '  face h ' + canvas.height)
  var isDrawingDetection = document.getElementById('showDetection').checked
  var isDrawingLandmarks = document.getElementById('showLandmarks').checked

  // get and draw the face landmarks. This is asynch function!!
  var result = getSingleFaceLandmarks(videoCanvas, canvas, isDrawingLandmarks, isDrawingDetection)
  // console.log('2  ' + getFPS())
  if (getFaceIsFound() === false) {
    // console.log('no face')
    faceFoundCounter++
    if (faceFoundCounter > faceFoundThreshold || allHist.length === 1) {
      faceIsFound = false
      updateViewerStatus(0)
    }
  } else {
    // console.log('face found')
    faceIsFound = true
    document.getElementById('faceFps').textContent = getFPS()
    // console.log("left from fun: " + getForeheadPoints().left.x);
    updateForeheadRectPos(getForeheadPoints())
    updateLeftCheekPos(getLeftCheekPoints())
    updateRightCheekPos(getRightCheekPoints())

    // needs to get called only the first time a face is found
    if (faceFoundCounter > faceFoundThreshold || allHist.length === 1) {
      updateViewerStatus(1)
      console.log('face is found, entering phase 1')
    }

    faceFoundCounter = 0
  }

  // if (result && getForeheadPoints() && faceFoundCounter < faceFoundThreshold) {
  //   console.log('draw face')

  //   // faceIsFound = true
  //   // get new rectangle for forehaed
  // } else {
  //   // faceIsFound = false
  //   updateViewerStatus(0)
  // }

  setTimeout(() => playVideo())
}

// runs every frame for drawing to screen
function step () {
  if (dobpmDetection === false) { return }

  if (faceIsFound) {
    document.getElementById('foreheadRect').style.display = 'block'
    // document.getElementById('foreheadClip').style.display = 'block'
  } else {
    document.getElementById('foreheadRect').style.display = 'none'
    // document.getElementById('foreheadClip').style.display = 'none'
  }
  // udpate views
  const foreheadRecCanvs = document.getElementById('foreheadRect') // canvases where the rectangles are drawn on the face
  const foreheadFromVideoCanvas = document.getElementById('foreheadClip') // copies pixels inside rectangles to this canvas
  // const visitorVideoViewCanvas = document.getElementById('visitorVideoView') // zoomed in and out of a the video feed
  drawForeheadRect(foreheadRecCanvs)
  var foreheadImageData = getForeheadFromVideo(foreheadFromVideoCanvas, faceIsFound)

  // updateVisitorVideoView(visitorVideoViewCanvas)
  //  if(foreheadImageData !== undefined ){
  //    console.log('ready ')
  //  }
  // get pulse
  if (foreheadImageData !== undefined && faceIsFound === true) { getPulse(foreheadImageData.data) }

  // update time
  fpsLoop()

  // recall this function next frame
  window.requestAnimationFrame(step)
}

// update fps
function fpsLoop () {
  var thisFrameTime = (thisLoop = new Date()) - lastLoop
  frameTime += (thisFrameTime - frameTime) / filterStrength
  lastLoop = thisLoop
}

// update the drawing of rectangle on forehead
function updateForeheadRectPos (foreheadPoints) {
  // returns undefined if array
  foreheadPoints = smoothForeheadPoints(foreheadPoints, 'left', 'right', oldForheadLeftX, oldForheadLeftY, oldForheadRightX, oldForheadRightY)

  if (!foreheadPoints) {
    return
  }
  // rectangle gets drawn from top left corner
  fhRectSize.y = pythagorean(foreheadPoints.right.x - foreheadPoints.left.x, foreheadPoints.right.y - foreheadPoints.left.y)
  fhRectSize.x = fhRectSize.y / 4

  fhRectPos = foreheadPoints.left
  var hy = 0
  if (foreheadPoints.right.y > foreheadPoints.left.y) {
    hy = foreheadPoints.right.y - foreheadPoints.left.y
    hy = hy * -1
  } else {
    hy = foreheadPoints.left.y - foreheadPoints.right.y
  }
  fhRectAngle = Math.acos(hy / fhRectSize.y)
}

function updateLeftCheekPos (leftCheekPnts) {
// do smoothing on cheek positions
// console.log({leftCheekPnts})
  // right side of rectangle gets drawn from middle between the top and bottom point, and a height of 75% the size between these points
  // use the angle of rotation based on the fhangle
  leftCheekPnts = smoothForeheadPoints(leftCheekPnts, 'top', 'bottom', oldLTopCheekX, oldLTopCheekY, oldLBottomCheekX, oldLBottomCheekY)
  // console.log('top: ' + leftCheekPnts.top.x + '  ' + leftCheekPnts.top.y + '   bottom: ' + leftCheekPnts.bottom.x + '  ' + leftCheekPnts.bottom.y)
  const hDiff = (leftCheekPnts.top.y - leftCheekPnts.bottom.y)
  LCheekRectSize.y = hDiff * 0.60
  LCheekRectSize.x = hDiff / 4

  LCheekRectPos.x = leftCheekPnts.top.x// - (hDiff/4)
  LCheekRectPos.y = leftCheekPnts.top.y - (hDiff * ((1 - 0.6) / 2))
}

function updateRightCheekPos (rightCheekPnts) {
  // console.log({rightCheekPnts})
  rightCheekPnts = smoothForeheadPoints(rightCheekPnts, 'top', 'bottom', oldRTopCheekX, oldRTopCheekY, oldRBottomCheekX, oldRBottomCheekY)
  // console.log('top: ' + leftCheekPnts.top.x + '  ' + leftCheekPnts.top.y + '   bottom: ' + leftCheekPnts.bottom.x + '  ' + leftCheekPnts.bottom.y)
  const hDiff = (rightCheekPnts.top.y - rightCheekPnts.bottom.y)
  RCheekRectSize.y = hDiff * 0.60
  RCheekRectSize.x = hDiff / 4

  RCheekRectPos.x = rightCheekPnts.top.x// - (hDiff/4)
  RCheekRectPos.y = rightCheekPnts.top.y - (hDiff * ((1 - 0.6) / 2))
}

function pythagorean (sideA, sideB) {
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2))
}

function drawForeheadRect (canvas) {
  var context = canvas.getContext('2d')

  context.setTransform(1, 0, 0, 1, 0, 0)
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.save()

  context.translate(fhRectPos.x, fhRectPos.y)
  context.rotate(fhRectAngle + Math.PI)
  context.lineWidth = '2'
  getForeheadRectPath(context, canvas.width, canvas.height)

  // draw left cheek
  context.restore()
  context.save()
  context.translate(LCheekRectPos.x - LCheekRectSize.x, LCheekRectPos.y)
  // context.rotate((-fhRectAngle + 180) * Math.PI / 180)
  context.rotate(fhRectAngle + Math.PI)
  context.lineWidth = '2'
  getLeftCheekRectPath(context, canvas.width, canvas.height)

  // draw right cheek
  context.restore()
  context.translate(RCheekRectPos.x, RCheekRectPos.y)
  // context.rotate((-fhRectAngle + 180) * Math.PI / 180)
  context.rotate(fhRectAngle + Math.PI)
  context.lineWidth = '2'
  getRightCheekRectPath(context, canvas.width, canvas.height)
}

function getForeheadRectPath (context, canvasW, canvasH) {
  context.beginPath()
  context.fillStyle = 'rgba(0, 0, 0, 0)'
  context.fillRect(0, 0, canvasW, canvasH)
  context.rect(0, 0, fhRectSize.x, fhRectSize.y)
  context.stroke()
  context.closePath()
}

function getLeftCheekRectPath (context, canvasW, canvasH) {
  context.beginPath()
  context.fillStyle = 'rgba(0, 0, 0, 0)'
  context.fillRect(0, 0, canvasW, canvasH)
  context.rect(0, 0, LCheekRectSize.y, LCheekRectSize.x)
  context.stroke()
  context.closePath()
}

function getRightCheekRectPath (context, canvasW, canvasH) {
  context.beginPath()
  context.fillStyle = 'rgba(0, 0, 0, 0)'
  context.fillRect(0, 0, canvasW, canvasH)
  context.rect(0, 0, RCheekRectSize.y, RCheekRectSize.x)
  context.stroke()
  context.closePath()
}

// TODO: not getting forehead section including face rotation
function getForeheadFromVideo (canvas, faceIsFound) {
  if (fhRectSize.x <= 0) {
    return undefined
  }

  var videoCanvas = document.getElementById('videoCanvas')
  var videoCtx = videoCanvas.getContext('2d')


  var context = canvas.getContext('2d')
  // var canvasResizeOffset = video.videoWidth / canvas.width
  var canvasResizeOffset = videoCanvas.width / canvas.width
  var borderOffsetX =  (videoCanvas.width/2) - (fhRectSize.y * canvasResizeOffset)/2
  var borderOffsetY = 20
  // console.log({canvasResizeOffset})
  context.fillStyle = '#000'
  context.fillRect(0, 0, canvas.width, canvas.height)
  context.save()

  // forehead
  context.beginPath()
  context.translate(-fhRectPos.x * canvasResizeOffset, (-fhRectPos.y + fhRectSize.x) * canvasResizeOffset)
  context.rect(fhRectPos.x * canvasResizeOffset + borderOffsetX, (fhRectPos.y - fhRectSize.x) * canvasResizeOffset + borderOffsetY, fhRectSize.y * canvasResizeOffset, fhRectSize.x * canvasResizeOffset)
  context.clip()

  //context.drawImage(video, borderOffset, borderOffset)
  context.drawImage(videoCanvas, borderOffsetX, borderOffsetY)


  var imagedata = context.getImageData(0, 0, canvas.width, canvas.height)
  imagedata = stackBlurCanvasRGBtest(imagedata, canvas.width, canvas.height, 20)
  context.putImageData(imagedata, 0, 0)

  context.restore()
  imagedata = context.getImageData(0, 0, fhRectSize.y + borderOffsetX, fhRectSize.x + borderOffsetY)

  return imagedata
}

// updates the canvas that the visitors can see
function updateVisitorVideoView (canvas) {
  var context = canvas.getContext('2d')
  var vidWidth = video.videoWidth
  var vidHeight = video.videoHeight
  var vidAspectRatio = vidWidth / vidHeight
  if (!faceIsFound) {
    // calculate width and height to resize video to

    var resizedHeight = canvas.height
    var resizedWidth = resizedHeight * vidAspectRatio
    var diffSize = (resizedWidth - resizedHeight) / 2

    // show regular video feed
    context.drawImage(video, -diffSize, 0, resizedWidth, resizedHeight)

    return
  }
  var resizeDiff = vidWidth / canvas.width
  var lfPntX = fhRectPos.x * resizeDiff// reminder that fhrectsize.y is actually the width
  var lfPntY = (fhRectPos.y - (fhRectSize.x * 3)) * resizeDiff
  var newW = vidWidth * (vidWidth / (fhRectSize.y * 3))
  lfPntX = lfPntX * (newW / vidWidth)
  var newH = newW / vidAspectRatio
  lfPntY = lfPntY * (newH / vidHeight)
  // console.log('vidwidth: ' + vidWidth + ' vidheight: ' + vidHeight + ' vidAR: ' + vidAspectRatio + ' resizediff: ' + resizeDiff)

  context.drawImage(video, -lfPntX, -lfPntY, newW, newH)

  // var imagedata = context.getImageData(0, 0, canvas.width, canvas.height)
  // imagedata = stackBlurCanvasRGBtest(imagedata, fhRectSize.y, fhRectSize.x, 200)
  // // context.clearRect(0,0, canvas.width, canvas.height)
  // context.putImageData(imagedata, 0, 0)
}

function smoothForeheadPoints (foreheadPoints, param1, param2, oldParam1X, oldParam1Y, oldParam2X, oldParam2Y) {
  var smoothingLength = 7

  var localpnts = {
    [param1]: [{
      x: 0,
      y: 0
    }],
    [param2]: [{
      x: 0,
      y: 0
    }]
  }
  if (oldParam1X.length < smoothingLength) {
    oldParam1X.push(foreheadPoints[param1].x)
    oldParam1Y.push(foreheadPoints[param1].y)
    oldParam2X.push(foreheadPoints[param2].x)
    oldParam2Y.push(foreheadPoints[param2].y)
  } else {
    oldParam1X.shift()
    oldParam1Y.shift()
    oldParam2X.shift()
    oldParam2Y.shift()
    oldParam1X.push(foreheadPoints[param1].x)
    oldParam1Y.push(foreheadPoints[param1].y)
    oldParam2X.push(foreheadPoints[param2].x)
    oldParam2Y.push(foreheadPoints[param2].y)
  }

  localpnts[param1].x = oldParam1X.reduce((a, b) => a + b) / oldParam1X.length
  localpnts[param1].y = oldParam1Y.reduce(function (a, b) { return a + b }, 0) / oldParam1Y.length
  localpnts[param2].x = oldParam2X.reduce(function (a, b) { return a + b }, 0) / oldParam2X.length
  localpnts[param2].y = oldParam2Y.reduce(function (a, b) { return a + b }, 0) / oldParam2Y.length

  return localpnts
}

/// ///////// BPM DETECTION
///
///
///
///
///
///

function getPulse (data) {

  // resets the data stream for a new face
  if(clearData === true){
    hist = []
    filteredSignals = []
    clearData = false
    signals = []
    facesVsTime.push(1)
  }else {
    facesVsTime.push(0)
  }
  while (facesVsTime.length > resetDataCanvas.width) facesVsTime.shift()

  var len = data.length
  var sum = 0
  // console.log(len)
  for (var i = 0, j = 0; j < len; i++, j += 4) {
    // sum += data[j] + data[j + 1] + data[j + 2]; //RGB
    sum += data[j + 1] + data[j + 2] // G
  }

  hist.push({ bright: sum / len, time: Date.now() })
  allHist.push({ bright: sum / len, time: Date.now() })
  while (hist.length > signalCanvas.width) hist.shift()
  while (allHist.length > signalCanvas.width) allHist.shift()

  // max and min
  var max = hist[0].bright
  var min = hist[0].bright
  var brightArray = []
  hist.forEach(function (v) {
    if (v.bright > max) max = v.bright
    if (v.bright < min) min = v.bright
    brightArray.push(v.bright)
  })


  // console.log(brightArray);
  const lag = 10 // document.getElementById('lagIn').value

  if (brightArray.length > lag + 2) {
    var threshold = 2 // document.getElementById('thresholdIn').value
    const influence = 10 // document.getElementById('influenceIn').value

    threshold = parseFloat(threshold) + (regPulses.length * 0.05)

    // get signal
    var sig = updateSignals(brightArray.slice(-1).pop(), signalCanvas.width, { lag: lag, influence: influence, threshold: threshold })
    signals.push(sig)
    allSignals.push(sig)
    while (signals.length > signalCanvas.width) signals.shift()
    while (allSignals.length > signalCanvas.width) allSignals.shift()

    // only check if signal for the latest pulse
    if (sig === -1 && lastSignal !== -1) {
      checkForPulse(hist[hist.length - 1].time)
      // console.log('new signal')
      // write pulse
      if (regPulses.length >= 3) {
        var pulseRate = 60000 / pulseMean(regPulses)

        if (displayedBPM === undefined && pulseRate > 60) {
          updateViewerStatus(3, pulseRate.toFixed(0))
          console.log('pulse found: ' + pulseRate.toFixed(0))
        } // else if (Math.abs(70 - pulseRate.toFixed(0)) < Math.abs(70 - displayedBPM)) {


        if (debugMode) {
          document.getElementById('debugBPM').innerHTML = pulseRate.toFixed(0) + ' BPM (' + regPulses.length + ' pulses)'
        }

        savedBPM.push(pulseRate)

        // update final bpm
        if (finalBPM === 0) {
          finalBPM = pulseRate.toFixed(0)
        } else if (pulseRate.toFixed(0) > 50 && pulseRate.toFixed(0) < 110) {
          finalBPM = pulseRate.toFixed(0)
        }
      } else {
        if (debugMode) {
          document.getElementById('bpmDebug').innerHTML = '-- BPM'
        }
      }
    }

    lastSignal = sig

    // draw signal canvas
    var sigctx = signalCanvas.getContext('2d')
    sigctx.clearRect(0, 0, signalCanvas.width, signalCanvas.height)
    sigctx.beginPath()
    sigctx.moveTo(0, 0)
    allSignals.forEach(function (v, x) {
      var y = signalCanvas.height * (v + 1) / 2
      sigctx.lineTo(x, y)
    })
    sigctx.lineWidth = 1
    sigctx.strokeStyle = 'white'
    sigctx.stroke()

    // draw timeDiff Canvas
    var bpmmax = savedBPM[0]
    var bpmmin = savedBPM[0]
    savedBPM.forEach(function (v) {
      if (v > bpmmax) bpmmax = v
      if (v < bpmmin) bpmmin = v
    })

    var tctx = timeDiffCanvas.getContext('2d')
    tctx.clearRect(0, 0, timeDiffCanvas.width, timeDiffCanvas.height)
    tctx.beginPath()
    tctx.moveTo(0, 0)
    while (savedBPM.length > timeDiffCanvas.width) savedBPM.shift()
    savedBPM.forEach(function (v, x) {
      // var y = timeDiffCanvas.height * (v + 1) / 2;
      var y = timeDiffCanvas.height * (v - bpmmin) / (bpmmax - bpmmin)
      tctx.lineTo(x, y)
    })
    tctx.lineWidth = 3
    tctx.strokeStyle = '#d3d3d3'
    tctx.stroke()

    // draw raw data canvas
    // max and min
    max = allHist[0].bright
    min = allHist[0].bright
    allHist.forEach(function (v) {
      if (v.bright > max) max = v.bright
      if (v.bright < min) min = v.bright
    })
    var rawCtx = rawDataCanvas.getContext('2d')
    rawCtx.clearRect(0, 0, rawDataCanvas.width, rawDataCanvas.height)
    rawCtx.beginPath()
    rawCtx.moveTo(0,0)

    allHist.forEach(function(v,x) {
      var y = rawDataCanvas.height * ( v.bright - min) / (max-min)
      rawCtx.lineTo(x, y )
    })
    rawCtx.lineWidth = 1
    rawCtx.strokeStyle = '#FF0000'
    rawCtx.stroke()

    // mark that a new dataset has started (new face detected)
    var resetCtx = resetDataCanvas.getContext('2d')
    resetCtx.clearRect(0, 0, resetDataCanvas.width, resetDataCanvas.height)
    resetCtx.beginPath()
    resetCtx.moveTo(0,0)
    facesVsTime.forEach(function(v,x){
      if(v === 1){
        resetCtx.moveTo(x, (resetDataCanvas.height*0.75))
        resetCtx.lineTo(x, resetDataCanvas.height*0.25)
      }
    })
    resetCtx.lineWidth = 8
    resetCtx.strokeStyle = '#FFFFFF'
    resetCtx.stroke()

  }
}

function checkForPulse (pTime) {
  // variables
  const minDur = (40 / 60) * 1000 // min time for 40bpm, converted to milliseconds
  const maxDur = (120 / 60) * 1000 // max time for 120bpm, converted to milliseconds
  const minPulsesForSuccess = 3
  const regTimeBuffer = 0.2
  const maxDiscardedPulses = 3
  const maxSavedHeartbeats = 15

  // console.log({ regPulses })

  // console.log(regPulses)
  // if there are no registered pulses
  if (!regPulses || regPulses.length === 0) {
    regPulses.push(pTime)
    return
  }

  const pDur = pTime - regPulses[regPulses.length - 1]

  // check if pulse is within an expected time period
  if (pDur < minDur) {
    // ignore, do not save
    if (regPulses.length === 1) {
      regPulses = []
      regPulses.push(pTime)
    }
    // console.log("pulse is less than minDur  "  + minDur);//+ pDur + "  minDur: "
    return
  }

  // console.log("registered pulses: " + regPulses);

  // if pulse is already found compare this one to the last successes
  if (regPulses.length >= minPulsesForSuccess) {
    // compare new pulse time to the avg of recorded ones
    var regMean = pulseMean(regPulses)
    if (pDur <= regMean * (regTimeBuffer + 1) && pDur > regTimeBuffer * (1 - regTimeBuffer)) {
      regPulses.push(pTime)
      return
    }

    // FUTURE change this to any increment less than a set value like 3
    // see if a pulse was missed - this one is within bounds of two times the expected time.
    var min2times = regMean * (regTimeBuffer + 2)
    var max2times = regTimeBuffer * (2 - regTimeBuffer)
    if (pDur <= regMean * (regTimeBuffer + 2) && pDur > regTimeBuffer * (2 - regTimeBuffer)) {
      // console.log("pulse is within 2x range: pulseDur: " + pDur + "  min: " + min2times + "  max: " + max2times);
      // first add missed pulse

      regPulses.push(regPulses[regPulses.length - 1] + pDur / 2)

      // add this pulse
      regPulses.push(pTime)
      return
    }

    // discard this pulse.
    numDiscardedPulses++

    // empty all pervious registered pulses
    if (numDiscardedPulses >= maxDiscardedPulses) {
      console.log('emptying - too many discardedPulses')
      regPulses = []
      regPulses.push(pTime)
    }
  }
  // gaining trust in our registered pulses
  else {
    if (pDur >= minDur && pDur <= maxDur) {
      regPulses.push(pTime)
      return
    }

    // restart the registration of pusles
    regPulses = []
    regPulses.push(pTime)
  }
}

// get the average length of pulse
// since regPulse is recording the time the pulse was taken, we need the duration of each pulse to compute an average
function pulseMean (a) {
  var inbetweenTimes = []
  // console.log("new pulse mean: " );
  for (var i = 1; i < a.length; i++) {
    inbetweenTimes.push(a[i] - a[i - 1])
  }
  // inbetweenTimes.forEach(function(v,x){
  //   console.log("inbetweentime at: " + x + " = " + inbetweenTimes[x])
  // })

  var max = inbetweenTimes[0]
  var min = inbetweenTimes[0]
  inbetweenTimes.forEach(function (v) {
    if (v > max) max = v
    if (v < min) min = v
  })
  var mean = sum(inbetweenTimes) / inbetweenTimes.length
  // console.log('pulseman: ' + mean + '  max dur: ' + max + '  min dur: ' + min)
  // console.log({inbetweenTimes})
  return mean
}

function sum (a) {
  return a.reduce((acc, val) => acc + val)
}

function mean (a) {
  return sum(a) / a.length
}

function stddev (arr) {
  const arr_mean = mean(arr)
  const r = function (acc, val) {
    return acc + ((val - arr_mean) * (val - arr_mean))
  }
  return Math.sqrt(arr.reduce(r, 0.0) / arr.length)
}

// using the saved data in signa
function updateSignals (newData, maxArrayLength, params) {
  var p = params || {}
  // init cooefficients
  const lag = p.lag || 5
  const threshold = p.threshold || 3.5
  const influence = p.influece || 0.5

  if (hist === undefined || hist.length < lag + 2) {
    throw ` ## y data array to short(${hist.length}) for given lag of ${lag}`
  }
  // console.log(`lag, threshold, influence: ${lag}, ${threshold}, ${influence}`)

  // init vars if first time ran
  if (!filteredSignals || filteredSignals.length === 0) {
    console.log('resetting filter data')
    const lead_in = hist.slice(0, lag)
    // filteredSignals = hist.slice(0)
    filteredSignals = []
    filteredSignals = lead_in
    avgFilter = []
    avgFilter[lag - 1] = mean(lead_in)
    stdFilter = []
    stdFilter[lag - 1] = stddev(lead_in)
    console.log('init')
  }
  // init variables
  //     var signals = Array(y.length).fill(0)
  //     var filteredY = y.slice(0)
  //     const lead_in = y.slice(0, lag)
  // //console.log("1: " + lead_in.toString())
  //     var avgFilter = []
  //     avgFilter[lag-1] = mean(lead_in)
  //     var stdFilter = []
  //     stdFilter[lag-1] = stddev(lead_in)
  // console.log("2: " + stdFilter.toString())

  // for(var i = lag; i < y.length; i++) {
  // console.log(`${y[i]}, ${avgFilter[i-1]}, ${threshold}, ${stdFilter[i-1]}`)
  var newSignal = 0
  if (Math.abs(newData - avgFilter[signals.length - 2]) > (threshold * stdFilter[signals.length - 2])) {
    if (newData > avgFilter[signals.length - 2]) {
      newSignal = +1 // positive signal
    } else {
      newSignal = -1 // negative signal
    }
    // make influence lower
    filteredSignals.push(influence * newData + (1 - influence) * filteredSignals[filteredSignals.length - 2])
  } else {
    newSignal = 0 // no signal
    filteredSignals.push(newData)
  }

  // adjust the filters
  const y_lag = filteredSignals.slice(filteredSignals.length - 1 - lag, filteredSignals.length - 1)
  avgFilter.push(mean(y_lag))
  stdFilter.push(stddev(y_lag))
  if (filteredSignals.length == maxArrayLength) {
    avgFilter.shift()
    stdFilter.shift()
    filteredSignals.shift()
    // console.log("maxedlength " + filteredSignals.length + "  " + avgFilter.length + "  " + stdFilter.length);
  }

  return newSignal
}

function timer (callback, delay) {
  var id; var started; var remaining = delay; var running

  this.start = function () {
    running = true
    started = new Date()
    id = setTimeout(callback, remaining)
  }

  this.pause = function () {
    running = false
    clearTimeout(id)
    remaining -= new Date() - started
  }

  this.getTimeLeft = function () {
    if (running) {
      this.pause()
      this.start()
    }

    return remaining
  }

  this.getStateRunning = function () {
    return running
  }

  this.start()
}

