
const firebase = require('@firebase/app')
const sceneController = require('../page-index/sceneController.js')
const { initChat } = require('../page-index/chat.js')

require('@firebase/firestore')
require('@firebase/auth')
require('@firebase/database')
require('@firebase/analytics')

var firebaseConfig = {
  apiKey: "AIzaSyDrW-yh1lapWoqTK9Wyb7cv1uOLhI77Sgs",
  authDomain: "antimodular-onpulse.firebaseapp.com",
  databaseURL: "https://antimodular-onpulse.firebaseio.com",
  projectId: "antimodular-onpulse",
  storageBucket: "antimodular-onpulse.appspot.com",
  messagingSenderId: "838099255616",
  appId: "1:838099255616:web:f1140af52e30d216a17aa5",
  measurementId: "G-ZBVV07TBLB"
};

firebase.firebase.initializeApp(firebaseConfig)
const firebaseConfigChat = firebase.firebase.initializeApp({
databaseURL: "https://antimodular-onpulse-chat.firebaseio.com/"
}, 'firebaseConfigChat');


// init analytics
const analytics = firebase.firebase.analytics()

const RTDB = firebase.firebase.database() // ref to real-time db
const RTDBChat = firebase.firebase.database(firebaseConfigChat)
var userIsAuth = false


async function countActiveUsers() {
  var numActiveUsers = -1
  await RTDB.ref('/room/1/active').once('value').then (function (snapshot) {
    numActiveUsers = snapshot.numChildren()
    //  console.log('there this many active users ' + snapshot.numChildren())
  })
  return numActiveUsers
}

// get all users from database for room id
async function getAllUsers () {
  RTDB.ref('/room/1/active').orderByKey().on('child_added', function (snapshot) {
    // if user recieved is not the main user, then get user info and add event listener to user
    if (sceneController.isMainUser(snapshot.key) === false) {

      // check if user is active
      // true - they are already in the room
      // false - they are likely entering the room and need 10s before they are active
      if(snapshot.val() === true){
        RTDB.ref('users/' + snapshot.key).once('value').then(function (founduser) {
          var newuser = founduser.val()
          newuser.id = founduser.key
          sceneController.newUserAddedtoAllUsers(newuser)
          addListenersToUsers(newuser.id)  // add listener to the new user
        })

      }else {
        // listen for changes on this user (changing to active / inactive)
        RTDB.ref('/room/1/active/' + snapshot.key).on('value', function(userIsActive){
          // user has switched from being not active in the room, to being available
          // this happens about 10s after they are loaded in, to give them time before they are
          // interacted with
          // console.log(' active user status changed ' , userIsActive.key , userIsActive.val())
          if(userIsActive.val() === true){
            RTDB.ref('/room/1/active/' + userIsActive.key).off() // remove listener once user is interactable
            RTDB.ref('users/' + userIsActive.key).once('value').then(function (founduser) {
            var newuser = founduser.val()
            newuser.id = founduser.key
            sceneController.newUserAddedtoAllUsers(newuser)
            addListenersToUsers(newuser.id)  // add listener to the new user
            })
          }
        })
      }
    }
  })
  // add listener for when someone has left the room
  RTDB.ref('/room/1/active').on('child_removed', function (snapshot) {
    // remove all listeners for position
    RTDB.ref('users/' + snapshot.key + '/pos').off()

    // add all

    // if the user removed from the scene was me, restart page
    // my user can only be removed like this through cloud functions
    // if i was present for longer than the cutoff time, i will
    // be removed from the scene.
    if(sceneController.isMainUser(snapshot.key)){
      RTDBBootUser(snapshot.key)
    }else {

      sceneController.removeUserfromAllUsers(snapshot.key)
    }
  })
  return true
}

// add listeners to a newly added user
function addListenersToUsers (userID) {
  // add listener for position change event
  RTDB.ref().child('users/' + userID + '/pos').on('child_changed', function (snapshot) {
    sceneController.updateUserPosInAllUsers(userID, snapshot.key, snapshot.val())
  })
  // console.log('adding user to chat')
  // RTDBChat.ref('room/1/chat').update({ [userID]: true })
}

// get ianctive users for husks
async function getXInactiveUsers (x) {
  var query = RTDB.ref('room/1/inactive').limitToFirst(x)
  query.once('value', function (snapshot) {
    snapshot.forEach(function (childSnapshot) {
      // get all info on this user
      RTDB.ref('users/' + childSnapshot.key).once('value', function (founduser) {
        var inuser = founduser.val()
        inuser.id = founduser.key

        sceneController.addInactiveUser(inuser)
      })
    })
  })
}
// window.getXInactiveUsers = getXInactiveUsers;

// add user to database
// set user active to false. When a user's active state is false, they are not being tracked
// by people within the room. This lets them load in first, get to the right position, then be set
// to true. Once set to true they can be chatted with and be seen moving around
async function addUserToDatabase (user) {
  await RTDB.ref('users/' + user.id).set({
    name: user.name,
    loc: user.loc,
    bpm: user.bpm,
    comment: user.comment,
    active: true,
    room: 1,
    timestamp: Date.now(),
    pos: {
      x: 0,
      z: 0
    }
  }).then(async result => {
    RTDB.ref('room/1/inactive/' + user.id).remove()
    RTDB.ref('room/1/active').update({ [user.id]: false })

    // get list of all users I've chatted with in past
    // RTDB.ref('mychats/' + user.id).once('value')
    //   .then(function (snapshot) {
    //     snapshot.forEach(function (childSnapshot) {
    //       // console.log('key: ' + childSnapshot.key + ' cahtid: ' + childSnapshot.val())
    //       sceneController.setPreviousChatIDs(childSnapshot.key, childSnapshot.val())
    //     })
    //   })
    // console.log('successfully added user: ' + user.id)
    sceneController.initMainUser(user)
  })
  return user.id
}

window.updateMyRoomAvailability = function (userid, availability){
  RTDB.ref('room/1/active').update({ [userid]: availability })
}

// update current user position in db
function updateMyPosInDB (userID, x, z) {
  // console.log('updating my pos to : ' + userID + '  ' + x + '  ' + z)
  RTDB.ref('users/' + userID + '/pos').set({
    x: x,
    z: z
  })
}


// sign in anonymously
async function authenticateUser () {
  var userID
  userIsAuth = true
  await firebase.firebase.auth().setPersistence(firebase.firebase.auth.Auth.Persistence.SESSION) //LOCAL
    .then(async function () {

      console.log('signed in user as auth')
      // console.log('set persistence to local')
      await firebase.firebase.auth().signInAnonymously().then(result => {
        // console.log('signed in anonymously ' + result.user.uid)
        userID = result.user.uid

      })
        .catch(function (error) {
          var errorCode = error.code
          var errorMessage = error.message
          console.error('Error with authentication', [errorCode, errorMessage])
        })
    })

  return userID
}

// called when authentication state changed
// user needs to be authenticated to use realtime database
firebase.firebase.auth().onAuthStateChanged(function (user) {

  // only trigger when the user has persistence from last session. If the user gets a new
  // authenticated id this won't be triggered
  if (user && userIsAuth === false) {
      // if user has already been to this website, auto fill in data with previous data
      // TODO
      // console.log('user persists from last session')
      RTDB.ref('users/' + user.uid).once('value')
        .then(function (founduser) {
          var newuser = founduser.val()
          newuser.id = founduser.key
          sceneController.fillInputsWithUserPersistence(newuser)
        })
      userIsAuth = true


  }
})

// current user is leaving space. Clean up as necessary.
window.RTDBBootUser = function(userID) {
  // var userID = user.id
  // console.log('exit user')
  // update room
  RTDB.ref('room/1/inactive').update({ [userID]: true })
  RTDB.ref('room/1/active/' + userID).remove()
  RTDBChat.ref('mutes/' + userID).remove()

  // TODO: fix this! Doesn't get called when page closes
  // update user list
  var updates = {}
  updates['users/' + userID + '/active'] = false
  RTDB.ref().update(updates)

  window.location.href = window.location.href;
}


window.RTDBChat_cache = {
  invites:{},
  mutes:{},
  chats:{},
}

// var chattedThisSession = {} // a dictionary storing all the chatIds(keys) from this seesion, with a timestamp of last time I had this chat open(value). Helps to maintain listeners

window.addChatListeners = function (chatId){

  // console.log('adding chat listeneres ' + chatId)
  // RTDBChat_cache.chats[chatId] = {}

  // check if we've chatted already before
  // let haschatted = chattedThisSession.indexOf(chatId) === -1 ? false : true //
  let haschatted = (RTDBChat_cache.chats[chatId])
  haschatted = haschatted === undefined ? false : true

  // listener0 = RTDBChat.ref("chats/"+chatId).on('child_added',function(data){
  //   RTDBChat_cache.chats[chatId][data.key] = data.val();
  //   console.log({data})
  //   appendChatMessage(data.val())
  // })

  // child_changed returns entire child (thus returning entire chat conversation)
  // listener1 = RTDBChat.ref("chats/"+chatId).on('child_changed',function(data){
  //   console.log('chat listener1 child changed')
  //   RTDBChat_cache.chats[chatId][data.key] = data.val();
  //   let oldCoversation = RTDBChat_cache.chats[chatId]['messages']
  //   // update typing
  //   if(data.key === 'typing'){

  //   }
  //   // update messages
  //   // if(data.key === 'messages'){
  //   //   appendChatMessage(data.val())
  //   // }
  //   let val = data.val()
  //   console.log({val})


  // })

  var listener10 = RTDBChat.ref("chats/"+chatId+"/messages").on('child_changed',function(data){
    // console.log('chat listener1 child changed')
    RTDBChat_cache.chats[chatId][data.key] = data.val();
    let val = data.val()
    console.log({val})
  })
  listener2 = RTDBChat.ref("chats/"+chatId).on('child_removed',function(data){
    RTDBChat_cache.chats[chatId][data.key] = null;
  })

  // issue with child added is if the chat exists in the cache, but not in this session we do not listen to add more chats.
  // need to differentiate between the two.
  // basically just want to check if I am already listening to child loaded on this chat. If not, do not listen
  if(haschatted === false){

    if(RTDBChat_cache.chats[chatId] === undefined){
      RTDBChat_cache.chats[chatId] = {}
    }
    if(RTDBChat_cache.chats[chatId]['messages'] === undefined){
      RTDBChat_cache.chats[chatId]['messages'] = {}
    }
    RTDBChat_cache.chats[chatId]['messages'] = {}

    listener3 = RTDBChat.ref("chats/" + chatId + '/messages').on('child_added',function(data){
      console.log('message added: ')
      let val = data.val()
      // console.log({val})
      RTDBChat_cache.chats[chatId]['messages'][data.key] = data.val()

      appendChatMessage(data.val())
    })
    // chattedThisSession.push(chatId)
   }
   // get all the messages since the last time we chatted
   else {
    let oldCoversation = RTDBChat_cache.chats[chatId]['messages'] //= 'messages'
    if(oldCoversation === undefined){
      RTDBChat_cache.chats[chatId]['messages'] = {}
      listener3 = RTDBChat.ref("chats/" + chatId + '/messages').on('child_added',function(data){
        // console.log('message added: ')
        let val = data.val()
        // console.log({val})
        RTDBChat_cache.chats[chatId]['messages'][data.key] = data.val()

        appendChatMessage(data.val())

      })
    }
    else {
      var latestMessage = 0

      for(var key in oldCoversation){
        if(!oldCoversation.hasOwnProperty(key)) continue;

        var obj = oldCoversation[key]
        // console.log(obj.time)
        if(obj.time > latestMessage){
          latestMessage = obj.time
        }
      }
      latestMessage = parseInt(latestMessage) + parseInt(1)
        // console.log('oldest message: ' + latestMessage)

        // get time of last message in our cache

        listener3 = RTDBChat.ref("chats/" + chatId + '/messages').orderByChild('time').startAt(latestMessage).on('child_added', function(data){
          // console.log('recent message added: ')
          RTDBChat_cache.chats[chatId]['messages'][data.key] = data.val()
          appendChatMessage(data.val())
          let val = data.val()
        })
      }
   }

  // listen for typing changes only when the other user types

}

// typing listener on other user
function addTypingListener(chatId, userId){
  // console.log('adding typing listener: ' + chatId, userId)
  RTDBChat.ref("chats/" + chatId + "/typing/" + userId ).on('value', function(data){
    otherUserIsTyping(data.val())
  })
  RTDBChat.ref("chats/" + chatId + "/typing/" + userId).on('child_added', function(data){
    otherUserIsTyping(data.val())
  })
}

window.RTDBChat_stopChatListeners = function(chatId, userId){

  // console.log('stop calling any messages ')
  // stop chat listeners
  RTDBChat.ref('chats/' + chatId + '/messages').off() // don't get any more messages
}

window.RTDBChat_setUserId = function(userId){
  // alert(userId);
  // console.log('removing chat listeners ' + userId)
  RTDBChat_cache.invites[userId]={};

  var listener0 = null;
  var listener1 = null;
  var listener2 = null;

  // nnot called
  function remChatListeners(){
    if (listener0){
      RTDBChat.ref("chats/"+chatId).off('child_added',listener0);
    }
    if (listener1){
      RTDBChat.ref("chats/"+chatId).off('child_changed',listener1);
    }
    if (listener2){
      RTDBChat.ref("chats/"+chatId).off('child_removed',listener2);
    }
    listener0 = null;
    listener1 = null;
    listener2 = null;
  }



  // when someone wants to talk with me, this gets triggered twice.
  // Once with key == 'chat' and another with key == 'user'
  RTDBChat.ref("invites/"+userId+"/").on('child_added',function(data){
    // console.log('someone wants to chat with me') // gets triggered
    // console.log('removing invites listener ' + data)
    if (!RTDBChat_cache.invites[userId]){RTDBChat_cache.invites[userId]={}}
    RTDBChat_cache.invites[userId][data.key] = data.val();
    if (data.key == 'chat'){

      addChatListeners(RTDBChat_cache.invites[userId].chat);

    }
    // key of other user
    else if (data.key == 'user'){
      addTypingListener(RTDBChat_cache.invites[userId].chat, data.val())
      let otherUser = sceneController.getUserWithID(data.val())
      initChat(otherUser, RTDBChat_cache.invites[userId])
      invitedToChat(otherUser, RTDBChat_cache.invites[userId].chat)
    }

  })


  RTDBChat.ref("invites/"+userId+"/").on('child_changed',function(data){
    // remChatListeners();

    if (!RTDBChat_cache.invites[userId]){RTDBChat_cache.invites[userId]={}}
    RTDBChat_cache.invites[userId][data.key] = data.val();
    if (data.key == 'chat') addChatListeners(RTDBChat_cache.invites[userId].chat);
  })


  // gets called after child_added gets called twice
  RTDBChat.ref("invites/"+userId+"/").on('child_removed',function(data){
    // remChatListeners();

    // console.log('weve finished our handshake')
    RTDBChat_cache.invites[userId][data.key] = null;
    // if (data.key == 'chat') addChatListeners();
  })



}

RTDBChat.ref("mutes/").on('child_added',function(data){
  RTDBChat_cache.mutes[data.key] = data.val();
})
RTDBChat.ref("mutes/").on('child_changed',function(data){
  RTDBChat_cache.mutes[data.key] = data.val();
})
RTDBChat.ref("mutes/").on('child_removed',function(data){
  RTDBChat_cache.mutes[data.key] = null;
})

window.RTDBChat_nuke = function(){
  RTDBChat.ref("invites/").remove();
  RTDBChat.ref("mutes/").remove();
  RTDBChat.ref("chats/").remove();
}

function deepCompare(){var t,e,r,n;function o(t,e){var f;if(isNaN(t)&&isNaN(e)&&"number"==typeof t&&"number"==typeof e)return!0;if(t===e)return!0;if("function"==typeof t&&"function"==typeof e||t instanceof Date&&e instanceof Date||t instanceof RegExp&&e instanceof RegExp||t instanceof String&&e instanceof String||t instanceof Number&&e instanceof Number)return t.toString()===e.toString();if(!(t instanceof Object&&e instanceof Object))return!1;if(t.isPrototypeOf(e)||e.isPrototypeOf(t))return!1;if(t.constructor!==e.constructor)return!1;if(t.prototype!==e.prototype)return!1;if(r.indexOf(t)>-1||n.indexOf(e)>-1)return!1;for(f in e){if(e.hasOwnProperty(f)!==t.hasOwnProperty(f))return!1;if(typeof e[f]!=typeof t[f])return!1}for(f in t){if(e.hasOwnProperty(f)!==t.hasOwnProperty(f))return!1;if(typeof e[f]!=typeof t[f])return!1;switch(typeof t[f]){case"object":case"function":if(r.push(t),n.push(e),!o(t[f],e[f]))return!1;r.pop(),n.pop();break;default:if(t[f]!==e[f])return!1}}return!0}if(arguments.length<1)return!0;for(t=1,e=arguments.length;t<e;t++)if(r=[],n=[],!o(arguments[0],arguments[t]))return!1;return!0}

window.RTDBChat_get = function(key,userId,callback){
  var cached;
  if (userId in RTDBChat_cache[key] && RTDBChat_cache[key][userId]!=undefined && (typeof RTDBChat_cache[key][userId] != 'object' || Object.keys(RTDBChat_cache[key][userId]).length)) {
    cached = RTDBChat_cache[key][userId];
  }else{
    cached = null;
  }
  // callback(cached);


  setTimeout(function(){
    callback(cached);
  },100);

    // RTDBChat.ref(key+"/"+userId).once("value").then(function(thing){
    //   var val = thing.val();

    //   if (!deepCompare(val,cached)){
    //     console.log(key);
    //     console.log("=================")
    //     console.log(JSON.stringify(val));
    //     console.log("-----------------")
    //     console.log(JSON.stringify(cached));
    //     console.log("~~~~~~~~~~~~~~~~~")

    //   //   alert("!")
    //   }
    //   callback(cached);
    // });
}
window.RTDBChat_set = function(key,userId,val,callback){
  if (val === null){
    // at beginning remove myself from any invites
    RTDBChat.ref(key+"/"+userId).remove();
  }else{
    // RTDBChat.ref(key+"/"+userId).set(val).then(callback?callback:function(){});
    if (typeof val == 'object'){
      for (var k in val){
        RTDBChat.ref(key+"/"+userId+"/"+k).set(val[k]).then(callback?callback:function(){});
      }
    }else{
      // called at beginning sets mutes to false
      RTDBChat.ref(key+"/"+userId).set(val).then(callback?callback:function(){});
    }
  }
  RTDBChat_cache[key][userId]=val;
}

window.RTDBChat_getMute = function(userId, callback){
  RTDBChat_get("mutes",userId,callback);
}
window.RTDBChat_setMute = function(userId, val, callback){
  RTDBChat_set("mutes",userId,val,callback);
}
window.RTDBChat_getInvite = function(userId, callback){
  RTDBChat_get("invites",userId,callback);
}
window.RTDBChat_listenForInvites = function(userId){
  RTDBChat.ref("invites/" + userId + '/')
  invitedToChat(invite)
}
window.RTDBChat_setInvite = function(userId, val,callback){
  // console.log('i am initiating a chat with someone else')
  RTDBChat_set("invites",userId,val,callback);
  if (val && val.chat){
    addChatListeners(val.chat)
    addTypingListener(val.chat, userId)
  }
}
window.RTDBChat_getChat = function(chatId, callback){
  RTDBChat_get("chats",chatId,callback);
}

window.RTDBChat_getCachedChat = function(chatId){
  if(RTDBChat_cache.chats === undefined){
    RTDBChat_cache.chats = {}
  }
  if(RTDBChat_cache.chats[chatId] === undefined){
    RTDBChat_cache.chats[chatId] = {}
  }
  if(RTDBChat_cache.chats[chatId]['messages'] === undefined){
    RTDBChat_cache.chats[chatId]['messages'] = {}
  }
  return RTDBChat_cache.chats[chatId]['messages']
}

window.RTDBChat_setChat = function(chatId, val, callback){
  RTDBChat_set("chats",chatId,val,callback);
}

window.RTDBChat_setChatMessage = function(chatId,msgId,val,callback){
  RTDBChat.ref("chats/"+chatId+"/messages/"+msgId).set(val).then(callback?callback:function(){});
}
window.RTDBChat_setChatTyping = function(chatId,userId,val,callback){
  RTDBChat.ref("chats/"+chatId+"/typing/"+userId).set(val).then(callback?callback:function(){});

}



///////////// analytics
/// logged when a face is found and when a face is lost until a bpm is determined
function analytics_faceFound(hasFace){
  // console.log('analytics face found : ' + hasFace)
  analytics.logEvent('face_found', {
    'value': hasFace

  });
}

// bpm is the true bpm when it was properly detected.
// bpm is false when bpm shown was randomly selected as time
// for bpm detection ran out
function analytics_foundTrueBPM(bpm){
  // console.log('analytics has bpm: ' + bpm)
  analytics.logEvent('foundBPM', {
    'value': bpm
  });
}

// records fps for the face deetection and page when a face is present
function analyticsLoginFPS(pagefps, facefps){
  // console.log('analytics has fps: ' + pagefps + '  ' + facefps)
  analytics.logEvent('loginfps', {
    'pagefps': pagefps,
    'facefps': facefps
  });
}

// user was denied entry, too many currently active users
function analyticsCannotEnterSpace(maxusers){
  analytics.logEvent('maxusers', {
    'maxusers': maxusers
  })
}

function analyticsEnterSpace(){
  analytics.logEvent('loginButton', {
    'triggered': true
  })
}


module.exports = {
  // users
  addUserToDatabase: addUserToDatabase,
  authenticateUser: authenticateUser,
  getAllUsers: getAllUsers,
  getXInactiveUsers: getXInactiveUsers,
  countActiveUsers: countActiveUsers,

  // listeners
  updateMyPosInDB: updateMyPosInDB,

  // analytics
  analytics_faceFound: analytics_faceFound,
  analytics_foundTrueBPM: analytics_foundTrueBPM,
  analyticsLoginFPS: analyticsLoginFPS,
  analyticsEnterSpace: analyticsEnterSpace,
  analyticsCannotEnterSpace: analyticsCannotEnterSpace

  // chats
  // getNewChatID: getNewChatID,
  // getUserAvailabilityToChat: getUserAvailabilityToChat,
  // initNewChat: initNewChat,
  // notifyUsrOfChat: notifyUsrOfChat,
  // updateMyAvailabilityToChat: updateMyAvailabilityToChat,
  // addToMyPreviousChats: addToMyPreviousChats
}
