// import {
// 	Euler,
// 	EventDispatcher,
// 	MathUtils,
// 	Quaternion,
// 	Vector3
// } from "../../../build/three.module.js";

var THREE = require('three')

/**
 * W3C Device Orientation control (http://w3c.github.io/deviceorientation/spec-source-orientation.html)
 */

var scope = this;

var onScreenOrientationChangeEvent = function () {

	scope.screenOrientation = window.orientation || 0;

};

var onDeviceOrientationChangeEvent = function ( event ) {

	scope.deviceOrientation = event;

};

THREE.DeviceOrientationControls = function ( object ) {

	scope = this
	var changeEvent = { type: "change" };
	var EPS = 0.000001;

	this.object = object;
	this.object.rotation.reorder( 'YXZ' );

	this.enabled = true;

	this.deviceOrientation = {};
	this.screenOrientation = 0;

	this.alphaOffset = 0; // radians

	

	

	// The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

	var setObjectQuaternion = function () {

		var zee = new THREE.Vector3( 0, 0, 1 );

		var euler = new THREE.Euler();

		var q0 = new THREE.Quaternion();

		var q1 = new THREE.Quaternion( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) ); // - PI/2 around the x-axis

		return function ( quaternion, alpha, beta, gamma, orient ) {

			euler.set( beta, alpha, - gamma, 'YXZ' ); // 'ZXY' for the device, but 'YXZ' for us

			quaternion.setFromEuler( euler ); // orient the device

			quaternion.multiply( q1 ); // camera looks out the back of the device, not the top

			quaternion.multiply( q0.setFromAxisAngle( zee, - orient ) ); // adjust for screen orientation

		};

	}();

	this.connect = function () {

		onScreenOrientationChangeEvent(); // run once on load

		// iOS 13+

		

		if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {

			window.DeviceOrientationEvent.requestPermission().then( function ( response ) {

				if ( response == 'granted' ) {

					window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
					window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

				}

			} ).catch( function ( error ) {

				console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );

			} );

		} else {

			window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
			window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

		}

		scope.enabled = true;

	};

	this.disconnect = function () {

		window.removeEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
		window.removeEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

		scope.enabled = false;

	};

	this.update = ( function () {

		var lastQuaternion = new THREE.Quaternion();

		return function () {

			if ( scope.enabled === false ) return;

			var device = scope.deviceOrientation;

			if ( device ) {

				var alpha = device.alpha ? THREE.MathUtils.degToRad( device.alpha ) + scope.alphaOffset : 0; // Z

				var beta = device.beta ? THREE.MathUtils.degToRad( device.beta ) : 0; // X'

				var gamma = device.gamma ? THREE.MathUtils.degToRad( device.gamma ) : 0; // Y''

				var orient = scope.screenOrientation ? THREE.MathUtils.degToRad( scope.screenOrientation ) : 0; // O

				setObjectQuaternion( scope.object.quaternion, alpha, beta, gamma, orient );

				if ( 8 * ( 1 - lastQuaternion.dot( scope.object.quaternion ) ) > EPS ) {

					lastQuaternion.copy( scope.object.quaternion );
					scope.dispatchEvent( changeEvent );
					return true

				}else {
					return false
				}

			}

		};


	} )();

	this.hasChangedOrientation = (function (lastQuaternion2) {
		// var lastQuaternion2 = new THREE.Quaternion();
		return function () {
			if(scope.enabled == false) return;

			var device = scope.deviceOrientation;

			if ( device ) {

				var alpha = device.alpha ? THREE.MathUtils.degToRad( device.alpha ) + scope.alphaOffset : 0; // Z

				var beta = device.beta ? THREE.MathUtils.degToRad( device.beta ) : 0; // X'

				var gamma = device.gamma ? THREE.MathUtils.degToRad( device.gamma ) : 0; // Y''

				var orient = scope.screenOrientation ? THREE.MathUtils.degToRad( scope.screenOrientation ) : 0; // O

				var newQuarternion = new THREE.Quaternion(alpha, beta, gamma, orient)

				// setObjectQuaternion( scope.object.quaternion, alpha, beta, gamma, orient );
				console.log(8 * ( 1 - lastQuaternion2.dot( newQuarternion ) ))
				// if ( 8 * ( 1 - lastQuaternion2.dot( scope.object.quaternion ) ) > 3 ) {

				// 	lastQuaternion2.copy( scope.object.quaternion );
				// 	return true

				// }else {
					return false
				// }

			}
		}
	})();

	this.getDeviceOrientation = (function () {

		return function (){
		if(scope.enabled == false) return;

		var device = scope.deviceOrientation;
		var alpha = device.alpha ? THREE.MathUtils.degToRad( device.alpha ) + scope.alphaOffset : 0; // Z

		var beta = device.beta ? THREE.MathUtils.degToRad( device.beta ) : 0; // X'

		var gamma = device.gamma ? THREE.MathUtils.degToRad( device.gamma ) : 0; // Y''

		var orient = scope.screenOrientation ? THREE.MathUtils.degToRad( scope.screenOrientation ) : 0; // O

		return new THREE.Quaternion(alpha, beta, gamma, orient)
		}
	})();

	this.dispose = function () {

		scope.disconnect();

	};

	this.connect();

};

THREE.DeviceOrientationRequest = function () {

	console.log("window.DeviceOrientationEvent info: ")
		console.log(window.DeviceOrientationEvent)
		console.log(typeof window.DeviceOrientationEvent.requestPermission)
	onScreenOrientationChangeEvent(); // run once on load

	console.log("window.DeviceOrientationEvent info: ")
		console.log(window.DeviceOrientationEvent)
		console.log(typeof window.DeviceOrientationEvent.requestPermission)

	// iOS 13+

	if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {

		console.log("will request permissions: ")
		window.DeviceOrientationEvent.requestPermission().then( function ( response ) {

			if ( response == 'granted' ) {

				window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
				window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

			}

		} ).catch( function ( error ) {

			console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );

		} );

	} else {

		window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent, false );
		window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent, false );

	}

	scope.enabled = true;

};

THREE.DeviceOrientationControls.prototype = Object.create( THREE.EventDispatcher.prototype );
THREE.DeviceOrientationControls.prototype.constructor = THREE.DeviceOrientationControls;

THREE.DeviceOrientationRequest.prototype = Object.create( THREE.EventDispatcher.prototype );
THREE.DeviceOrientationRequest.prototype.constructor = THREE.DeviceOrientationRequest
;


// export { THREE.DeviceOrientationControls };