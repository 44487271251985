//Get Three.js Functions

import { version } from "../js/materialize.min";

//Audio Variables, Mixing and Effects
var Tone = require("tone");

//External Heartbeat Players and Panners
var players, panners, userid,reverb, groupVolume,master_limiter;
//Synth Drone Spatial Mixers
var synthMixer1,synthMixer2,synthMixer3,synthMixer4, synthVolume;
//Background Audio Synths and Mixing
var drone,noise,wind_volume,autoFilter,whoosh1,whoosh2,whooshVolume,tick,tickVolume, uiVolume;
//Main User Heartbeat and Mixing
var userVolume, mainUserSpatialMixer, mainUserBeat, mainUserLoop, mainUserID;
//User Management
var collectedUsers = new Array();
var output_map = new Array();

var loading = true;
// Master Audio Mute Control on "M" Keypress
Tone.Master.volume.value = 0;
const load_mute = false;
var muted = load_mute;
// Tone.Master.mute = true;
window.onkeyup = function(e) {
    if (window.ischat){
        return;
    }
    var key = e.keyCode ? e.keyCode : e.which;
    if (key== 77) {
      muteAudio()

    }
}

window.muteAudio = function () {
  if(muted==true){
    groupVolume.mute = false;
    synthVolume.mute = false;
    wind_volume.mute = false;
    muted=false;
    if(ismob === true ){
        Tone.Master.mute = false
    }
}else if (muted==false){
    groupVolume.mute = true;
    synthVolume.mute = true;
    wind_volume.mute = true;
    muted=true;
    if(ismob === true){
        Tone.Master.mute = true
    }
}
}

// mobile check for audio. Audio is spatialized when laptop, desktop, or recent iphone
function isMobileDevice() {

    // ios devices not listed in this mobile check.
    let ismob = (/Android|webOS|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) ;//|| navigator.maxTouchPoints > 1;
    // if older iphone, show mobile version, newer iphones can handle this website
    
    if (ismob === false) {
        
      if(/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
        //   console.log('is ios device and ismob is false')
          if(getiOSVersion() < 13){
          ismob = true
        }
      }
    }
    // console.log('is mobile?: ' + ismob)
    return ismob;
  };


   function getiOSVersion () {
    var agent = window.navigator.userAgent,
    start = agent.indexOf( 'OS ' );
    if( ( agent.indexOf( 'iPhone' ) > -1 || agent.indexOf( 'iPad' ) > -1 ) && start > -1 ){
        return window.Number( agent.substr( start + 3, 3 ).replace( '_', '.' ) );
    }
    return 0;
}


var ismob = isMobileDevice()

//Create 100 Tone.js Players and Panners
export function loadAudio() {
    if(ismob){
        console.log("Loading Mobile Audio System");
        loadMobileAudio()
    }else{
        console.log("Loading Full Audio System");
        loadFullAudio();
    }
}

function loadFullAudio() {
    loading = true;
    var reverb_volume = new Tone.Volume(-25).toMaster();
    reverb = new Tone.Freeverb().connect(reverb_volume);
    master_limiter = new Tone.Limiter(-20).fan(reverb,Tone.Master);

    //Load Whoosh
    uiVolume = new Tone.Volume(0).toMaster();
    whooshVolume = new Tone.Volume(5).connect(uiVolume);
    tickVolume = new Tone.Volume(-15).connect(uiVolume);
    whoosh1 = new Tone.Player({
        "url" : "./src/assets/audio/whoosh1.wav",
        "playbackRate" : .25
    }).connect(whooshVolume);

    whoosh2 = new Tone.Player({
    "url" : "./src/assets/audio/whoosh2.wav",
    "playbackRate" : .25
    }).connect(whooshVolume);

    tick = new Tone.Player({
        "url" : "./src/assets/audio/tick1.wav"
        }).connect(tickVolume);

    //Load Synth
    synthMixer1 = new Tone.Panner3D().toMaster();
    synthMixer2 = new Tone.Panner3D().toMaster();
    synthMixer3 = new Tone.Panner3D().toMaster();
    synthMixer4 = new Tone.Panner3D().toMaster();
    synthVolume = new Tone.Volume(0).fan(synthMixer1,synthMixer2,synthMixer3,synthMixer4);

    //Corner 1
    synthMixer1.setPosition(30,1,120);
    synthMixer1.refDistance = 5;
    //Corner 2
    synthMixer2.setPosition(30,1,-120);
    synthMixer2.refDistance = 5;
    //Corner 3
    synthMixer3.setPosition(-30,1,120);
    synthMixer3.refDistance = 5;
    //Corner 4
    synthMixer4.setPosition(-30,1,-120);
    synthMixer4.refDistance = 5;

    //Create Tone.js Player for Synth Drone
    drone = new Tone.Player({
        "url" : "./src/assets/audio/drone2.mp3"
    }).connect(synthVolume);
    drone.autoplay = true;

    //Wind created from Pink Noise and Filter with LFO
    noise = new Tone.Noise("pink");
    wind_volume =  new Tone.Volume(-40).toMaster();;
    autoFilter = new Tone.AutoFilter({
        "frequency" : "8m",
        "min" : 800,
        "max" : 15000
    }).connect(wind_volume)
    //connect the noise
    noise.connect(autoFilter);

    
    Tone.loaded('load', function(){
        loaded=true;
        console.log("Audio Loaded")
    });
    // Tone.Buffer.on('load', function(){
    //     loaded=true;
    //     console.log("Audio Loaded")
    // });

    //Main User Heartbeat
    userVolume = new Tone.Volume(0).toMaster();
    mainUserSpatialMixer = new Tone.Panner3D().connect(userVolume);
    mainUserSpatialMixer.refDistance = 20
    mainUserBeat = new Tone.Player({
        "url" : "./src/assets/audio/heartbeat2.mp3",
        "fadeOut": .1,
        "fadeIn": .2,
    }).connect(mainUserSpatialMixer);

    //Create Tone.js Players and Panners
    players = new Array();
    panners = new Array();
    userid = new Array();
    groupVolume = new Tone.Volume(0).connect(master_limiter);

    //Create Tone.js Players
    for (var i = 0; i < 100; i++)
        players.push(new Tone.Player({
          "url" : "./src/assets/audio/heartbeat2.mp3",
          "fadeOut": .1,
          "fadeIn": .2,
        }));

    //Create Tone.js 3D Panners
    for (var i = 0; i < 100; i++)
      panners.push(new Tone.Panner3D().connect(groupVolume));

    //Set Output Map
    initOutputs();
    console.log("Outputs Initialized")

    for (var i = 0; i < 100; i++){
        //Change distance that you can begin to hear the heartbeats from
        panners[i].refDistance=15;
        panners[i].distanceModel="exponential";
        players[i].connect(panners[i]);
    }
}

function loadMobileAudio() {
    loading = true;
    master_limiter = new Tone.Limiter(-20).toMaster();

    //Load Whoosh
    uiVolume = new Tone.Volume(0).toMaster();
    whooshVolume = new Tone.Volume(5).connect(uiVolume);
    tickVolume = new Tone.Volume(-15).connect(uiVolume);
    whoosh1 = new Tone.Player({
        "url" : "./src/assets/audio/whoosh1.wav",
        "playbackRate" : .25
    }).connect(whooshVolume);

    whoosh2 = new Tone.Player({
    "url" : "./src/assets/audio/whoosh2.wav",
    "playbackRate" : .25
    }).connect(whooshVolume);

    tick = new Tone.Player({
        "url" : "./src/assets/audio/tick1.wav"
        }).connect(tickVolume);

    synthVolume = new Tone.Volume(0).toMaster();;
    //Create Tone.js Player for Synth Drone
    drone = new Tone.Player({
        "url" : "./src/assets/audio/drone2.mp3"
    }).connect(synthVolume);
    drone.autoplay = true;

    //Wind created from Pink Noise and Filter with LFO
    noise = new Tone.Noise("pink");
    wind_volume =  new Tone.Volume(-40).toMaster();;
    autoFilter = new Tone.AutoFilter({
        "frequency" : "8m",
        "min" : 800,
        "max" : 15000
    }).connect(wind_volume)
    //connect the noise
    noise.connect(autoFilter);

    Tone.loaded('load', function(){
        loaded=true;
        console.log("Audio Loaded")
    });

    //Main User Heartbeat
    userVolume = new Tone.Volume(0).toMaster();
    mainUserSpatialMixer = new Tone.Panner3D().connect(userVolume);
    mainUserSpatialMixer.refDistance = 20
    mainUserBeat = new Tone.Player({
        "url" : "./src/assets/audio/heartbeat2.mp3",
        "fadeOut": .1,
        "fadeIn": .2,
    }).connect(mainUserSpatialMixer);

    //Create Tone.js Players and Panners
    players = new Array();
    panners = new Array();
    userid = new Array();
    groupVolume = new Tone.Volume(0).connect(master_limiter);

    //Create Tone.js Players
    for (var i = 0; i < 100; i++)
        players.push(new Tone.Player({
          "url" : "./src/assets/audio/heartbeat2.mp3",
          "fadeOut": .1,
          "fadeIn": .2,
        }).toMaster());
}

var alt = 0;
window.whoosh = function(){
  tickSound();
  if(alt==0){
    whoosh1.start();
    alt=1;
  }else if(alt==1){
    whoosh2.start();
    alt=0;
  }
}

window.tickSound = function(){
    tick.start();
}

function getOutputById(userID){
    for (let index = 0; index < collectedUsers.length; index++) {
        const element = collectedUsers[index];

        if(element.id==userID){
            return element.output
            break;
        }

    }
}

// Update Audio Tone Listener
// likely not called
export function updateAudio() {
    //Call Function on every Frame

    if(ismob){
        updateMobile()
    }else{
        updateFullAudio()
    }

}

function updateMobile(){};

function updateFullAudio() {
    //Call Function on every Frame
    requestAnimationFrame(updateFullAudio);

    // Only Track Camera when Three.js Camera Initialized
    if (window.getCameraPosition == undefined) {
        // Leave this here to avoid errors being thrown when camera is not active.
    } else {
        Tone.Listener.positionX = window.getCameraPosition().x
        Tone.Listener.positionY = window.getCameraPosition().y
        Tone.Listener.positionZ = window.getCameraPosition().z
        Tone.Listener.forwardX = window.getCameraOrientation().forward.x
        Tone.Listener.forwardY = window.getCameraOrientation().forward.y
        Tone.Listener.forwardZ = window.getCameraOrientation().forward.z
        Tone.Listener.upX = window.getCameraOrientation().up.x
        Tone.Listener.upY = window.getCameraOrientation().up.y
        Tone.Listener.upZ = window.getCameraOrientation().up.z

        // Tone.Listener.setPosition(window.getCameraPosition().x,window.getCameraPosition().y,window.getCameraPosition().z)
        // Tone.Listener.setOrientation(window.getCameraOrientation().forward.x,window.getCameraOrientation().forward.y,window.getCameraOrientation().forward.z,window.getCameraOrientation().up.x,window.getCameraOrientation().up.y,window.getCameraOrientation().up.z)
    }

    //Track User Position

    // Get Figures Positions From Three.js
    var pos = window.getAllFigurePositions();

    //Iterates Through All Active Users
    for (const key in pos) {
        if (pos.hasOwnProperty(key)) {
            var p = pos[key]//Get the XYZ Orientation from Three.js for each user
            var k = getOutputById(key)
            if(k==undefined){
                //AVOID ERRORS
            }else{
                panners[k].setPosition(p.x,p.y,p.z);
            }
        }
    }

}

function initOutputs(){
    for (let index = 0; index < 100 ;index++) {
        output_map[index]=false;
    }
}


// Start Background Drone and Wind
export function startBackgroundAudio() {
    drone.start();
    noise.start();
    autoFilter.start();
}
//Starts Main User Heartbeat
export function startMainUserAudio(id,bpm) {
    mainUserID = id;

    //Main User Volume and Spatial Mixer Routing
    var bcb = window.getBeatCallbacks();
    mainUserLoop = setInterval(() => {
         bcb[id]()
         mainUserBeat.start();
    }, (60/bpm)*1000);
}
//Stops Main User Heartbeat
export function stopMainUserAudio(bpm) {
    loading=false;
    userVolume.volume.linearRampTo(-500, 3);
    setTimeout(() => {
        mainUserBeat.mute = true;
    }, 3000);
}

function getOpenOutput(){

    for (let index = 0; index < 100; index++) {
        if(output_map[index]==false){
            console.log("New User Assined to Output: " + index)
            turnOutputOn(index)
            return index;
            break;
        }
    }
}


var new_users = 1;
var new_index = 1;
var queueCount = 0;

// tegan audio stop
export function collectUsersOnLoad(user) {
    // USER OBJECT
    var collectedUser = {
        id:"",
        bpm:"",
        output:""
    };
    if(collectedUsers.length>100){

    }else{

    }
    if(loading==true){
        //COLLECT ACTIVE USERS
        collectedUser.id=user.id
        collectedUser.bpm=user.bpm
        collectedUser.output=queueCount;
        output_map[queueCount]=true;
        collectedUsers.push(collectedUser)
        queueCount=queueCount+1;
    }else{
        //NEW USERS
        collectedUser.id=user.id
        collectedUser.bpm=user.bpm
        collectedUser.output=getOpenOutput()
        collectedUsers.push(collectedUser)
        startNewHeartbeat(collectedUser);
    }

}

function startNewHeartbeat(user){
    // console.log("NEW OUTPUT "+ user.output)
    if(user.output==undefined){
       console.log("The Room is Full - Please Wait")
    } else if(user.output<100) {
        setTimeout(() => {
            loopBeat(user.id,user.bpm,players[user.output])
        }, 2000);
    }

}


function turnOutputOn(num) {
    output_map[num]=true
}

function turnOutputOff(id) {
    collectedUsers.forEach(element => {
         if(element.id==id){
              output_map[parseInt(element.output)]=false;
              // console.log("Turned Off: "+element.id+" at Output: "+element.output)

        }
    });
}


export function loadExternalHeartbeats(){

}

export function startExternalHeartbeats() {
    console.log("Collected all Current Users:")
    // console.log({collectedUsers})
    // console.log(output_map)

    var count = 0;
    var queued = collectedUsers.length
    // Trigger Loop every two seconds
    var loop = setInterval(() => {
        if(count<queued){
            loopBeat(collectedUsers[count].id,collectedUsers[count].bpm,players[count]);
            // console.log('started beat for user: ' + '   ' + count + '    ' + queued + '     ' + collectedUsers[count].id )
            count = count+1;

        }else{
            clearInterval(loop)
        }
   }, 2000);

}


var playingUsers = new Array();

function loopBeat(user,bpm,element){
        var beat = {
            user:"",
            loop:""
        }
        var bcb = window.getBeatCallbacks();
        var loop = setInterval(() => {
          element.start();
          bcb[user]();
        }, (60/bpm)*1000);
        beat.user=user;
        beat.loop = loop;
        playingUsers.push(beat)
}
export function removeUserAudio(id){
    console.log('remove user audio: ' + id)
    for (let index = 0; index < playingUsers.length; index++) {
        if(playingUsers[index].user==id){
            turnOutputOff(id);
            clearInterval(playingUsers[index].loop)
            break;
        }

    }
}
